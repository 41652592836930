import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {setDeletePack, setEditPack, updatePack} from '../../actions/packs';
import {connect} from 'react-redux';
import {Input} from './Input';

const MoveTo = ({
  moveTo, items, propName, sourceItem, isFocus = true,
  style, onClose, isClose = false, containerClassName, placeholder = 'Move to...', renderProp = null,
}) => {
  const [filter, setFilter] = useState('');
  const [localItems, setLocalItems] = useState(items);

  useEffect(() => {
    setLocalItems(items.filter((item) => {
      return item[propName].toLowerCase().includes(filter.toLowerCase());
    }));
  }, [filter]);

  const renderBody = () => {
    return <div className={'move-to-container'}>
      <Input handleChange={setFilter}
        value={filter}
        className={'move-to-input'}
        placeholder={placeholder}
        debounce={100}
        isFocus={isFocus}
      />
      <div style={{maxHeight: '210px', overflowX: 'auto'}}>
        {
          localItems.map((item, index) => {
            return <div
              className={'component-setting'}
              style={style}
              key={index + 'move_to_' + item.name + item.id}
              onClick={() => {
                if (isClose && onClose) {
                  onClose();
                }
                setTimeout(() => moveTo(sourceItem, item), 100);
              }}
            >
              <div className={'component-setting-text'}>{!renderProp ? item[propName] : renderProp(item)}</div>
            </div>;
          })
        }
      </div>
    </div>;
  };
  if (containerClassName) {
    return <div className={containerClassName}>
      {renderBody()}
    </div>;
  }

  return (renderBody());
};

const mapDispatchToProps = (dispatch) => ({
  editPack: bindActionCreators(setEditPack, dispatch),
  deletePack: bindActionCreators(setDeletePack, dispatch),
  updatePack: bindActionCreators(updatePack, dispatch),
});

export default connect(null, mapDispatchToProps)(MoveTo);

