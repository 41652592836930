const frames = {};

export const groupFrames = (message) => {
  const frameKey = message.action + '_' + message.id;

  if (!frames[frameKey]) {
    frames[frameKey] = {parts: {}};
  }
  frames[frameKey].parts[String(message.numberFrame)] = message.message;
  console.log(`${Object.keys(frames[frameKey].parts).length} of ${message.countFrames}`)
  if (Object.keys(frames[frameKey].parts).length === message.countFrames) {
    let unitedFrames = '';
    Object.keys(frames[frameKey].parts).forEach((numberKey) => {
      unitedFrames += frames[frameKey].parts[numberKey];
    });
    frames[frameKey] = undefined;
    return {
      type: message.action,
      data: Object.assign({}, message, {
        message: JSON.parse(unitedFrames),
      }),
    };
  }
};

const arrays = {};

export const groupArray = (message) => {
  const arrayKey = message.action + '_' + message.id;

  if (!arrays[arrayKey]) {
    arrays[arrayKey] = {pages: {}};
  }
  arrays[arrayKey].pages[String(message.numberPage)] = message.message;

  if (Object.keys(arrays[arrayKey].pages).length === message.countPages) {
    let unitedPages = [];
    Object.keys(arrays[arrayKey].pages).forEach((numberKey) => {
      unitedPages = unitedPages.concat(arrays[arrayKey].pages[numberKey]);
    });
    arrays[arrayKey] = undefined;

    return {
      type: message.action,
      data: Object.assign({}, message, {
        message: unitedPages,
      }),
    };
  };
};
