import React from 'react';
import RequestsTable from './RequestsTable';
import {getStyle} from '../../utils/StyleUtils';
import {configs} from '../../resources/configs';
import {addFakeRequest} from '../../actions/requests';
import RequestsTabs from './RequestsTabs';
import RequestAdminSelect from './RequestAdminSelect';

const Requests = ({match}) => {
  return (
    <div className={getStyle('requests-container')}>
      {configs.isDev || configs.isLocal ?
              <div
                className={'save-btn'}
                style={{width: '90px', marginBottom: '20px', marginTop: '10px'}}
                onClick={() => addFakeRequest()}
              >
                  Add request
              </div> : null
      }
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <RequestsTabs/>
        <RequestAdminSelect/>
      </div>
      <RequestsTable
        requestIdFromParam={match.params.requestId}
      />
    </div>
  );
};

export default Requests;
