import React, {useState} from 'react';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addGroup, updateGroup} from '../../actions/groups';
import {changeLocalization, getLocalizedValue, locales} from '../../utils/LocalizationUtils';

const GroupDetails = ({mode, group, cancel, deleteGroup,
  addGroup, updateGroup,
}) => {
  const [name, setName] = useState(group.name);
  const [localization, setLocalization] = useState(group.localization);

  const handleSaveGroup = () => {
    const groupForSave = {...group};
    groupForSave.name = name;
    groupForSave.localization = localization;

        mode === 'EDIT' ? updateGroup(groupForSave) : addGroup(groupForSave);
        cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'}>
      <div className={'fltr-head-text'}>
        {mode === 'EDIT' ? 'Edit Group' : 'Add Group'}
      </div>
    </div>;
  };

  const renderName = (lang) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={lang + 'input'}>
      <div className={'fltr-second-text'}>
        {lang}
      </div>
      <Input style={{marginTop: '7px', width: '200px'}}
        handleChange={(value) => {
          changeLocalization(value, 'name', localization, setName, setLocalization, lang);
        }}
        value={getLocalizedValue(localization, 'name', lang)}
      />
    </div>;
  };

  const renderBody = () => {
    return <div className={'pack-details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '100%'}}>
        <div className={'fltr-second-text'}>
                    English
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={(value) => {
            changeLocalization(value, 'name', localization, setName, setLocalization);
          }}
          value={getLocalizedValue(localization, 'name')}
        />
      </div>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {locales.map((locale) => {
          if (locale === 'en') {
            return null;
          }
          return renderName(locale);
        })}
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'pack-details-footer'}>
      <button className={mode === 'EDIT' && group.id > 3 ? 'fltr-delete-btn' : 'fltr-second-btn'}
        style={{marginRight: (mode === 'EDIT' && group.id > 3 ? 'auto' : undefined)}}
        onClick={() => {
          if (mode === 'EDIT' && group.id > 3) {
            deleteGroup(group);
          }
          cancel();
        }}
      >
        {mode === 'EDIT' && group.id > 3 ? 'Delete' : 'Cancel'}
      </button>
      <button className={'save-btn'} style={{marginLeft: '24px'}}
        onClick={handleSaveGroup}
        disabled={name === ''}
      >
        {mode === 'EDIT' ? 'Save' : 'Add'}
      </button>
    </div>;
  };

  return <div className="pack-details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addGroup: bindActionCreators(addGroup, dispatch),
  updateGroup: bindActionCreators(updateGroup, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
