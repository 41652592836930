import uuid from 'uuid/v4';
import {WEBSOCKET_ERROR, WebsocketAction} from '../middlewares/websockets/types';
import {
  CHANGE_SEARCH_REQUESTS_ADMIN_ID,
  CHANGE_SEARCH_REQUESTS_PAGE, CHANGE_SEARCH_REQUESTS_STATUS,
  ORDER_REQUESTS,
  SELECT_REQUEST, SET_IS_LOADING_REQUEST,
  SET_IS_LOADING_REQUESTS,
} from '../actions/requests';
import {removeDuplicates} from '../utils/ArrayUtils';

const getInintialState = () => {
  return {
    stateId: uuid(),
    requests: [],
    countPageRequests: 0,
    countRequests: 0,
    searchParamsRequests: {
      limit: 20,
      page: 1,
      orderBy: 'endIn',
      orderIndex: 'ASC',
      status: undefined,
      adminId: undefined,
    },
    selectedRequest: {id: -1},
    isLoadingRequests: false,
    timeStampSaveRequests: 0,
    timeStampUpdateRequests: {},
    isLoadingRequest: false,
  };
};

export default function elements(state = getInintialState(), action) {
  switch (action.type) {
    case WebsocketAction.SAVE_FLTR_REQUESTS.name: {
      const message = action.data;
      if (message && message.stateId === state.stateId && message.requestTimeEpoch > state.timeStampSaveRequests) {
        return Object.assign({}, state, {
          requests: state.searchParamsRequests.page === 1 ? message.message.results : removeDuplicates(state.requests.concat(message.message.results), 'id'),
          countPageRequests: message.message.pageTotal,
          countRequests: message.message.total,
          isLoadingRequests: false,
          timeStampSaveRequests: message.requestTimeEpoch,
        });
      } else {
        return state;
      }
    }

    case WebsocketAction.UPDATE_FLTR_REQUEST.name: {
      const message = action.data;
      let timeStampUpdateRequest = state.timeStampUpdateRequests[message.message.requestId];
      if (message) {
        const timeStampUpdateRequests = {...state.timeStampUpdateRequests};
        timeStampUpdateRequest = timeStampUpdateRequest ? {...timeStampUpdateRequest} : {};


        let isUpdatedRequest = false;
        let newRequest;
        const newRequests = state.requests.map((request) => {
          if (request.id !== message.message.requestId) {
            return request;
          }
          isUpdatedRequest = true;
          newRequest = {...request};
          message.message.props.forEach((prop) => {
            const timeStampUpdateRequestProp = timeStampUpdateRequest[prop.propName] ? timeStampUpdateRequest[prop.propName] : 0;
            if (timeStampUpdateRequestProp <= message.requestTimeEpoch) {
              newRequest[prop.propName] = prop.prop;
              timeStampUpdateRequest[prop.propName] = message.requestTimeEpoch;
            }
          });
          return newRequest;
        });

        timeStampUpdateRequests[message.message.requestId] = timeStampUpdateRequest;

        if (!isUpdatedRequest && message.message.request) {
          newRequests.push(message.message.request);
        }

        const requests = sortRequests(newRequests, state.searchParamsRequests);
        const diffCount = state.requests.length - requests.length;
        let selectedRequest = state.selectedRequest;
        if (state.selectedRequest.id === message.message.requestId) {
          if (newRequest) {
            selectedRequest = newRequest;
          } else {
            message.message.props.forEach((prop) => {
              selectedRequest[prop.propName] = prop.prop;
            });
          }
        }
        return Object.assign({}, state, {
          requests,
          countRequests: state.countRequests - diffCount,
          timeStampUpdateRequests: timeStampUpdateRequests,
          selectedRequest,
        });
      } else {
        return state;
      }
    }

    case ORDER_REQUESTS: {
      return Object.assign({}, state, {
        searchParamsRequests: Object.assign({}, state.searchParamsRequests, {
          orderBy: action.orderParams.orderBy,
          orderIndex: action.orderParams.orderIndex,
          page: 1,
        }),
        requests: [],
      });
    }

    case CHANGE_SEARCH_REQUESTS_PAGE: {
      return Object.assign({}, state, {
        searchParamsRequests: Object.assign({}, state.searchParamsRequests, {
          page: action.page,
        }),
      });
    }

    case CHANGE_SEARCH_REQUESTS_STATUS: {
      return Object.assign({}, state, {
        requests: [],
        searchParamsRequests: Object.assign({}, state.searchParamsRequests, {
          status: action.status === 'ALL' ? undefined : action.status,
          page: 1,
        }),
      });
    }

    case CHANGE_SEARCH_REQUESTS_ADMIN_ID: {
      return Object.assign({}, state, {
        requests: [],
        searchParamsRequests: Object.assign({}, state.searchParamsRequests, {
          adminId: action.adminId,
          page: 1,
        }),
      });
    }

    case WebsocketAction.ADD_REQUEST.name: {
      const message = action.data;
      const newRequests = [...state.requests];
      newRequests.unshift(message.message);

      return Object.assign({}, state, {
        requests: sortRequests(newRequests, state.searchParamsRequests),
        countRequests: state.searchParamsRequests.status === undefined || state.searchParamsRequests.status === 'NEW' ? state.countRequests + 1 : state.countRequests,
      });
    }

    case WebsocketAction.SAVE_FLTR_REQUEST.name: {
      const message = action.data;

      return Object.assign({}, state, {
        selectedRequest: message.message ? message.message : {id: -1},
        isLoadingRequest: false,
      });
    }

    case WebsocketAction.DELETE_REQUEST.name: {
      const message = action.data;
      const requestId = message.message.requestId;
      const newRequests = state.requests.filter((request) => request.id !== requestId);
      return Object.assign({}, state, {
        countRequests: newRequests.length !== state.requests.length ? state.countRequests - 1 : state.countRequests,
        requests: newRequests,
        selectedRequest: state.selectedRequest.id === requestId ? {id: -1} : state.selectedRequest,
      });
    }

    case WebsocketAction.REQUEST_IS_PROCESSED.name: {
      const message = action.data;
      const request = message.message.request;
      const newRequests = state.requests.map((requestExist) => requestExist.id === request.id ? request : requestExist);
      return Object.assign({}, state, {
        requests: newRequests,
        selectedRequest: {id: -1},
      });
    }

    case SET_IS_LOADING_REQUESTS: {
      return Object.assign({}, state, {
        isLoadingRequests: action.isLoading,
      });
    }

    case SET_IS_LOADING_REQUEST: {
      return Object.assign({}, state, {
        isLoadingRequest: action.isLoading,
      });
    }

    case SELECT_REQUEST: {
      return Object.assign({}, state, {
        selectedRequest: action.request ? action.request : {id: -1},
      });
    }

    case WEBSOCKET_ERROR: {
      return getInintialState();
    }

    default: return state;
  }
}

const sortRequests = (requests, searchParams) => {
  const ids = {};
  const filteredRequests = requests
      .filter((request) => {
        const isAdmin = searchParams.adminId ? request.adminId === searchParams.adminId : true;
        const isStatus = searchParams.status ? request.status === searchParams.status : true;
        ids[request.id] = ids[request.id] === undefined ? false : true;
        if (ids[request.id]) {
          return true;
        }
        return isAdmin && isStatus;
      });

  const orderBy = searchParams.orderBy === 'endIn' ? 'createdAt' : searchParams.orderBy;

  return filteredRequests.sort((a, b) => {
    if (searchParams.orderBy === 'endIn' && (b.closeDate && !a.closeDate)) {
      return -1;
    }
    if (searchParams.orderBy === 'endIn' && (!b.closeDate && a.closeDate)) {
      return 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return searchParams.orderIndex === 'DESC' ? -1 : 1;
    }
    if (a[orderBy] < b[orderBy]) {
      return searchParams.orderIndex === 'DESC' ? 1 : -1;
    }
    return 0;
  });
};
