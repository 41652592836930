import {EditImg} from '../../general/EditImg';
import React from 'react';

export const BeforeAfter = ({style, setImage, setImageUrl, imageUrl, imageProcessedUrl, isLoadingBefore,
  isLoadingAfter, setIsLoadingAfter, setIsLoadingBefore, tempNameAfter, tempNameBefore,
  setIsForceUpdate, handleFileDrop, setImageProcessed, setImageProcessedUrl,
}) => {
  return <div style={style}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className={'fltr-second-text'}>
                Before
      </div>
      <EditImg imgUrl={imageUrl}
        style={{marginTop: '5px', height: '164px', width: '124px'}}
        heightImg={'164px'}
        widthImg={'124px'}
        isLoading={isLoadingBefore}
        onDrop={(file) => handleFileDrop(
            file,
            setImage,
            setImageUrl,
            setIsLoadingBefore,
            tempNameAfter,
        )}
        clear={() => {
          setImage(null);
          setImageUrl(null);
          setIsForceUpdate(false);
        }}
      />
    </div>
    <div style={{marginLeft: '20px', display: 'flex', flexDirection: 'column'}}>
      <div className={'fltr-second-text'}>
                After
      </div>
      <EditImg imgUrl={imageProcessedUrl}
        style={{marginTop: '5px', height: '164px', width: '124px'}}
        heightImg={'164px'}
        widthImg={'124px'}
        isLoading={isLoadingAfter}
        onDrop={(file) => handleFileDrop(
            file,
            setImageProcessed,
            setImageProcessedUrl,
            setIsLoadingAfter,
            tempNameBefore,
            setImageProcessedUrl,
        )}
        clear={() => {
          setImageProcessed(null);
          setImageProcessedUrl(null);
          setIsForceUpdate(false);
        }}
      />
    </div>
  </div>;
};
