import React, {useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import {ReactComponent as ArrowButton} from '../../resources/imgs/arrow-button-icon.svg';
import {ReactComponent as ArrowRight} from '../../resources/imgs/arrow-right-icon.svg';
import {getLocalizedValue} from '../../utils/LocalizationUtils';
import ElementsList, {ElementsListType} from '../elements/ElementsList';
import {PacksListType} from './PacksList';
import ComponentSettings from '../general/ComponentSettings';
import DropdownWrapper from '../general/DropdownWrapper';
import {ComponentType} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {setEditPack, updatePack} from '../../actions/packs';
import {connect} from 'react-redux';
import {Draggable} from 'react-beautiful-dnd';

const Pack = ({pack, isSelected, isBlockDnD, externalId, draggableId, isDragging,
  arrayIndex, groupId, selectPack, packListType, elementsInPacks,
}) => {
  const [isMouseOver, setMouseOver] = useState(false);

  const renderButtons = () => {
    return <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
      <DropdownWrapper style={{right: '-100%'}}>
        <DotsHorizontal
          style={{opacity: (isMouseOver ? 1 : 0)}}
        />
        <ComponentSettings componentType={ComponentType.PACK} componentTypeIn={packListType}
          item={pack} sourceItemId={groupId}
        />
      </DropdownWrapper>
      {
            (isSelected && true) ? <ArrowButton style={{marginLeft: '12px'}}/> :
                <ArrowRight style={{marginLeft: '12px', opacity: (isMouseOver ? 1 : 0)}}/>
      }
    </div>;
  };

  return (
    <Draggable
      key={pack.id}
      draggableId={draggableId}
      index={arrayIndex}
    >
      {(provided, snapshot) => (
        <div className={'pack-list-content'}
          onMouseLeave={() => setMouseOver(false)}
          onMouseEnter={() => setMouseOver(true)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{...provided.draggableProps.style, ...{borderTop: (arrayIndex === 0 ? '0px' : undefined)}}}
          {...provided.dragHandleProps}
        >
          <div className={'pack-list-item' + (isSelected ? '-active' : '')}
            style={{
              cursor: (isBlockDnD ? 'pointer' : 'move'),
              backgroundColor: (snapshot.isDragging ? 'white' : 'none'),
            }}
            onClick={(e) => {
              selectPack(pack.id + '_' + externalId, packListType);
            }}
          >
            {isBlockDnD ? null :
                        <div className={'drag-dots'}
                          style={{
                            opacity: (isMouseOver ? 1 : 0),
                          }}
                        >
                        </div>
            }
            <img className={'pack-list-img'}
              style={{opacity: pack.isVisible ? '1' : '0.4'}}
              src={pack.previewUrl ? pack.previewUrl : pack.imageUrl}
            />
            <div style={{
              display: 'flex',
              opacity: pack.isVisible ? '1' : '0.4',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '10px',
            }}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={'pack-list-value-name'}>
                  {getLocalizedValue(pack.localization, 'name')}
                </div>

                <div className={'pack-list-value-elements'}>
                  {elementsInPacks[pack.id].length}x
                </div>
              </div>
              {packListType === PacksListType.PACKS_IN_TODAY || packListType === PacksListType.PACKS_IN_PREMIUM ?
                            <div className="fltr-second-text">
                              {pack.contentTypeId} Pack
                            </div> : null}
            </div>
            {renderButtons()}
          </div>
          {
            <ElementsList
              elements={elementsInPacks[pack.id]}
              elementsInPacks={elementsInPacks}
              isRenderList={isSelected && !isDragging}
              packId={pack.id}
              packContentType={pack.contentTypeId}
              elementsListType={ElementsListType.ELEMENTS_IN_PACK}
            />
          }
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  elementsInPacks: state.elements.elementsInPacks,
});

const mapDispatchToProps = (dispatch) => ({
  editPack: bindActionCreators(setEditPack, dispatch),
  updatePack: bindActionCreators(updatePack, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pack);
