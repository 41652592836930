import {logoutApp} from '../utils/AuthUtill';
import {WebsocketAction} from '../middlewares/websockets/types';

const initialState = {};

export const AdminType = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  FLTR_REQUESTS_MANAGER: 'FLTR_REQUESTS_MANAGER',
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case WebsocketAction.FLTR_ADMIN_LOGOUT:
      logoutApp();
      return state;

    default: return state;
  }
}
