import React from 'react';
import {REQUEST_STATUS} from '../../actions/requests';

export const Status = ({status}) => {
  switch (status) {
    case REQUEST_STATUS.DONE:
      return <div className={'fltr-status-done'}>
                DONE
      </div>;
    case REQUEST_STATUS.NEW:
      return <div className={'fltr-status-new'}>
              NEW
      </div>;
    case REQUEST_STATUS.IN_PROGRESS:
      return <div className={'fltr-status-in-progress'}>
              IN PROGRESS
      </div>;
  }
};
