import React from 'react';

export const EditVideo = ({videoUrl, onDrop, clear, style, heightVideo, widthVideo}) => {
  const isActive = false;

  if (!videoUrl) {
    return <div
      className={'fltr-img-edit-dnd-container' + (isActive ? '-active' : '') }
      style={style}
    >
      <img style={{width: '46px', height: '31px'}}
        src={require('../../resources/imgs/upload.png').default}
      />
      <div className={'fltr-img-edit-dnd-text'}>
                Max size 3 MB
      </div>

      <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
        onChange={(event) => {
          if (event.target.files[0] && event.target.files[0].type.startsWith('video/')) {
            onDrop(event.target.files[0]);
          }
        }}/>
    </div>;
  }

  return <div className= "fltr-img-edit-container" style={style}>
    <video className={'fltr-img-edit'} width={widthVideo} height={heightVideo} controls>
      <source src={videoUrl} type="video/mp4"/>
    </video>
    <img className={'fltr-close-img'}
      src={require('../../resources/imgs/close.png').default}
      onClick={clear}
    />
  </div>;
};
