import React from 'react';
import {getStyle} from '../../utils/StyleUtils';

export const RequestAdminSelectBody = ({admins, onClose, selectAdminId, selectedAdminId}) => {
  return <div className={getStyle('component-settings-container')}>
    <div className={'component-setting'} onClick={() => {
      selectAdminId(undefined);
      onClose();
    }}>
      <div className={'component-setting-text'}>All</div>
    </div>
    {admins.map((admin) => {
      return <div
        style={{backgroundColor: (selectedAdminId === admin.id ? '#F5F5F5' : undefined)}}
        className={'component-setting'}
        onClick={() => {
          selectAdminId(admin.id);
          onClose();
        }}
      >
        <div className={'component-setting-text'}>{admin.nickname}</div>
      </div>;
    })}
  </div>;
};
