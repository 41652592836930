import React from 'react';
import {ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';
import {getStyle} from '../../utils/StyleUtils';
import {isMobileOnly} from 'react-device-detect';

export const WarningAttention = ({title, subtitle, footerButtonText, description, cancel}) => {
  const renderBody = (title, subtitle, description) => {
    return <div className={'attention-body'}>
      <AttentionIcon/>
      <div className={'fltr-attention-text'} style={{marginTop: '12px'}}>
        {title}
      </div>
      <div className={'fltr-attention-description-text'} style={{marginTop: '25px'}}>
          <b><b>{description}</b></b> {subtitle}
      </div>
    </div>;
  };

  const renderFooter = (footerButtonText) => {
    return <div className={'attention-footer'}
      style={{justifyContent: isMobileOnly ? 'center' : undefined}}
    >
      <button className={'save-btn'} onClick={()=>{
        cancel();
      }}>
        {footerButtonText}
      </button>
    </div>;
  };

  return (
    <div className={getStyle('attention-container')}>
      {renderBody(title, subtitle, description)}
      {renderFooter(footerButtonText)}
    </div>
  );
};
