import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeSearchRequestsPage, getRequest, getRequests, setEditRequest} from '../../actions/requests';
import MDSpinner from 'react-md-spinner';
import {Request} from './Request';
import {getStyle} from '../../utils/StyleUtils';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import RequestsTableHeader from './RequestsTableHeader';
import {List} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {WebsocketServiceType} from '../../middlewares/websockets/types';

const RequestsTable = ({
  requests, isLoadingRequests, searchParamsRequests, isConnectedWSS, getRequests, getRequest, isLoadingRemoteConfig,
  selectedRequest, editRequest, countPages, changeSearchRequestsPage, requestIdFromParam, isLoadingRequest, remoteConfig,
}) => {
  const [refreshInterval, setRefreshInterval] = useState(0);
  useEffect(() => {
    if (requestIdFromParam && isConnectedWSS) {
      getRequest(Number(requestIdFromParam));
    }
  }, [requestIdFromParam, isConnectedWSS]);

  useEffect(() => {
    setTimeout(function() {
      setRefreshInterval(refreshInterval + 1);
    }, 60000);
  }, [refreshInterval]);

  useEffect(() => {
    if (isConnectedWSS) {
      getRequests(searchParamsRequests);
    }
  }, [searchParamsRequests, isConnectedWSS]);

  if ((isLoadingRequests && searchParamsRequests.page === 1) || !isConnectedWSS || isLoadingRequest || isLoadingRemoteConfig) {
    return (
      <div className='spinner-container' style={{marginTop: '212px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  const onRowsRendered = ({overscanStartIndex, overscanStopIndex, startIndex, stopIndex}) => {
    const isLoadMore = (stopIndex + 1) === requests.length && searchParamsRequests.page !== countPages && !isLoadingRequests;

    if (isLoadMore) {
      changeSearchRequestsPage(searchParamsRequests.page + 1);
    }
  };

  const rowRenderer = ({key, index, style}) => {
    const request = requests[index];
    if (!request) {
      return null;
    }

    return (
      <div key={request.id} style={style}>
        <Request
          isSelected={request.id === selectedRequest.id}
          request={request}
          editRequest={editRequest}
          processingInterval={remoteConfig.requestProcessingInterval}
        />
      </div>
    );
  };

  return (
    <div className={getStyle('requests-list')}>
      <RequestsTableHeader/>
      <div style={{height: '100%'}}>
        <AutoSizer>
          {({height, width}) => {
            return <List
              style={{outline: 'none'}}
              width={width}
              height={height}
              rowCount={requests.length}
              rowHeight={50}
              onRowsRendered={onRowsRendered}
              rowRenderer={rowRenderer}
            />;
          }}
        </AutoSizer>
      </div>
      {isLoadingRequests ?
        <div style={{marginTop: '10px', marginBottom: '10px', alignSelf: 'center'}}>
          <MDSpinner size={30} singleColor={'#00DADA'}/>
        </div> : null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingRequests: state.requests.isLoadingRequests,
  isConnectedWSS: state.appState.isConnectedWSS[WebsocketServiceType.FLTR_REQUESTS],
  requests: state.requests.requests,
  countPages: state.requests.countPageRequests,
  searchParamsRequests: state.requests.searchParamsRequests,
  selectedRequest: state.requests.selectedRequest,
  isLoadingRequest: state.requests.isLoadingRequest,
  isLoadingRemoteConfig: state.appState.isLoadingRemoteConfig,
  remoteConfig: state.appState.remoteConfig,
});

const mapDispatchToProps = (dispatch) => ({
  getRequests: bindActionCreators(getRequests, dispatch),
  getRequest: bindActionCreators(getRequest, dispatch),
  editRequest: bindActionCreators(setEditRequest, dispatch),
  changeSearchRequestsPage: bindActionCreators(changeSearchRequestsPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
