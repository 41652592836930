import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {getStyle} from '../../utils/StyleUtils';

const Modal = ({modalType, modals, showModal, onClose, children, modalContentClassName = 'modal-content', style}) => {
  const [isOnMouseDown, setMouseDown] = useState(false);
  const [isOnMouseUp, setMouseUp] = useState(false);

  useEffect(() => {
    if (!isOnMouseDown && isOnMouseUp) {
      setMouseDown(false);
      setMouseUp(false);
    } else {
      if (isOnMouseDown && isOnMouseUp) {
        setMouseDown(false);
        setMouseUp(false);

        showModal(modalType, false);
        if (onClose) {
          onClose();
        }
      }
    }
  }, [isOnMouseDown, isOnMouseUp]);

  if (!modals[modalType].isShow) {
    return null;
  }

  return (
    <div className={'modal'}
      style={style}
      onMouseDown={() => {
        setMouseDown(true);
      }}
      onMouseUp={() => {
        setMouseUp(true);
      }}
    >
      <div className={getStyle(modalContentClassName)}
        onMouseDown={(e) =>(setMouseDown(false), e.stopPropagation())}
        onMouseUp={(e) => e.stopPropagation()}
      >
        {React.cloneElement(children, {meta: modals[modalType].meta})}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modals: state.appState.modals,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
