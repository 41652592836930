import {configs} from '../resources/configs';
import {getAccessToken} from './AuthUtill';
import {WebsocketAction} from '../middlewares/websockets/types';

export const getJSON = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.withCredentials = true;

  xhr.responseType = 'json';
  xhr.onload = function() {
    const status = xhr.status;
    if (status === 200) {
      console.log(xhr.response);
      callback(null, xhr.response);
    } else {
      callback(status, xhr.response);
    }
  };
  xhr.send();
};

export const getFileArrayBuffer = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'arraybuffer';
  if (!configs.isLocal) {
    xhr.withCredentials = true;
  }
  xhr.open('GET', url, true);
  xhr.onload = function(e) {
    callback(xhr.response);
  };
  xhr.send();
};

export const createRequest = (dispatch, method, path, onload, isAsync = true, basePath = '/fltr') => {
  const xhr = new XMLHttpRequest();
  xhr.open(method, configs.apiUrl + basePath + path, isAsync);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());
  xhr.setRequestHeader('onelight-app-id', 'FLTR');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        onload(xhr.responseText);
      } else {
        if (xhr.status === 401) {
          dispatch({type: WebsocketAction.FLTR_ADMIN_LOGOUT});
        }
      }
    }
  };

  return xhr;
};
