import React from 'react';
import {ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';
import {getStyle} from '../../utils/StyleUtils';

export const AcceptDialog = ({title, subtitle, meta, textAccept, cancel}) => {
  const renderBody = () => {
    return <div className={'dialog-publish-body'}>
      <AttentionIcon/>
      <div className={'fltr-attention-text'} style={{marginTop: '12px'}}>
        {title}
      </div>
      <div className={'fltr-attention-description-text'} style={{marginTop: '25px'}}>
        {subtitle}
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'dialog-publish-footer'}>
      <button className={'fltr-second-btn'} onClick={()=>{
        cancel();
      }}>
                Cancel
      </button>

      <button className={'save-btn'} style={{
        marginLeft: '25px',
      }}
      onClick={()=>{
        cancel();
        meta.onAccept();
      }}
      >
        {textAccept}
      </button>
    </div>;
  };

  return <div className={getStyle('dialog-container')}>
    {renderBody()}
    {renderFooter()}
  </div>;
};
