import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import uuid from 'uuid/v4';
import {BrowserView, MobileView} from 'react-device-detect';
import {prepareFileToUpload} from '../../../utils/FilesUtils';
import {BeforeAfter} from './BeforeAfter';
import {DngInput} from '../../general/DngInput';
import {REQUEST_STATUS, updateRequest} from '../../../actions/requests';
import {RequestDetailsHeader} from './RequestsDetailsHeader';
import {getAdmin, getAdminIamActions} from '../../../utils/AuthUtill';
import {getStyle} from '../../../utils/StyleUtils';
import momentZ from 'moment-timezone';
import {RequestsDetailsFooter} from './RequestsDetailsFooter';
import {showModal, typeModal} from '../../../actions/general';

const RequestDetails = ({request, cancel, deleteRequest, updateRequest, admin, showModal, remoteConfig}) => {
  const [tempNameAfter] = useState(uuid());
  const [tempNameDNG] = useState(uuid());

  const [isLoadingDNG, setIsLoadingDNG] = useState(false);
  const [isLoadingImageProcessed, setIsLoadingImageProcessed] = useState(false);
  const [iamActions, setIamActions] = useState(getAdminIamActions());

  const isIamIndividualFltrRequestManager = (iamActions.indexOf('webUI:AccessIndividualFltrRequests') !== -1 && iamActions.indexOf('webUI:AccessFltrRequests') === -1);
  const isDisable = (admin.adminType === 'FLTR_REQUESTS_MANAGER' || isIamIndividualFltrRequestManager) &&
      admin.id !== request.adminId &&
      request.status !== REQUEST_STATUS.NEW;

  const isEnabledComplete = !isDisable &&
      (request.imageUrl &&
      request.processedImageUrl &&
      request.elementUrl &&
      request.elementPreviewUrl &&
      request.status === REQUEST_STATUS.IN_PROGRESS);

  const handleFileDrop = (file, action, actionUrl, setIsLoading, tempName, isUploadToS3 = true) => {
    prepareFileToUpload(file, action, actionUrl, setIsLoading, tempName, isUploadToS3, 5000000, false);
  };

  const handleUpdate = (props, isChangeStatus) => {
    if (isChangeStatus) {
      props.push({
        propName: 'status',
        prop: REQUEST_STATUS.IN_PROGRESS,
      });
      props.push({
        propName: 'adminId',
        prop: admin.id,
        isSkip: true,
      });
      props.push({
        propName: 'adminName',
        prop: admin.nickname,
        isSkip: true,
      });
      props.push({
        propName: 'closeDate',
        prop: null,
        isSkip: true,
      });
      if (request.status === REQUEST_STATUS.NEW) {
        props.push({
          propName: 'inProgressDate',
          prop: new Date(),
          isSkip: true,
        });
      }
    }
    updateRequest(props, request.id);
  };

  const handleChangeStatus = (status) => {
    if (request.status !== status || request.adminId !== admin.id) {
      const props = [
        {
          propName: 'status',
          prop: status,
        },
        {
          propName: 'adminId',
          prop: status === REQUEST_STATUS.NEW ? null : admin.id,
          isSkip: true,
        },
        {
          propName: 'adminName',
          prop: status === REQUEST_STATUS.NEW ? null : admin.nickname,
          isSkip: true,
        },
      ];
      if (request.status === REQUEST_STATUS.NEW) {
        props.push({
          propName: 'inProgressDate',
          prop: new Date(),
          isSkip: true,
        });
      }
      if (status === REQUEST_STATUS.DONE) {
        props.push({
          propName: 'closeDate',
          prop: new Date(),
          isSkip: true,
        });
      } else {
        props.push({
          propName: 'closeDate',
          prop: null,
          isSkip: true,
        });
      }

      handleUpdate(props);
    }
  };
  const getDetailFooter = () => {
    return <RequestsDetailsFooter
      deleteEntity={deleteRequest}
      entity={request}
      isHideDelete={admin.adminType === 'FLTR_REQUESTS_MANAGER' || isIamIndividualFltrRequestManager}
      handleSave={ () => {
        switch (request.status) {
          case REQUEST_STATUS.IN_PROGRESS: {
            showModal(typeModal.DONE_REQUEST, true, {
              onAccept: () => {
                handleChangeStatus(REQUEST_STATUS.DONE);
              },
            });
            break;
          }
          case REQUEST_STATUS.NEW: {
            handleChangeStatus(REQUEST_STATUS.IN_PROGRESS);
            break;
          }
        }
      }}
      isEnabledComplete={!isEnabledComplete}
    />;
  };

  const generateHrefContact = (contact) => {
    if (!contact || contact.length === 0) {
      return '';
    }
    let contactFiltered = contact;
    const firstChar = contact.charAt(0);

    if (firstChar === '@') {
      contactFiltered = contactFiltered.slice(1);
    }

    return contactFiltered.includes('@') ? `mailto:${contactFiltered}` : `https://instagram.com/${contactFiltered}`;
  };

  const renderBody = () => {
    return <div className={getStyle('request-details-body')}>
      {isDisable ? <div className={'block-request-details'} /> : null}
      <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <a href={generateHrefContact(request.contact)} target="_blank" className="request-details-contact-date-text" rel="noreferrer">{request.contact}</a>
        <div className={'request-details-contact-date-text'}>
          {momentZ(request.createdAt).tz(momentZ.tz.guess()).format('D MMM HH:mm')}
        </div>
      </div>
      <div className={'request-details-description'}>
        {request.description}
      </div>
      <BeforeAfter
        imageUrl={request.imageUrl}
        handleFileDrop={handleFileDrop}
        setIsLoadingAfter={setIsLoadingImageProcessed}
        isLoadingAfter={isLoadingImageProcessed}
        imageProcessedUrl={request.processedImageUrl}
        setImageProcessed={(path) => {
          handleUpdate([{
            propName: 'imageProcessedTempPath',
            prop: path,
          }], true);
        }}
        setImageProcessedUrl={(url) => {
          handleUpdate([
            {
              propName: 'processedImageUrl',
              prop: url,
              isSkip: true,
            },
            {
              propName: 'imagePreviewUrl',
              prop: url,
              isSkip: true,
            },
          ], false);
        }}
        tempNameAfter={tempNameAfter}
      />

      <DngInput
        elementUrl={request.elementUrl}
        elementFileTempUrl={request.elementFileTempUrl}
        setElementFileUrl={(elementFileTempUrl) => {
          handleUpdate([{
            propName: 'elementFileTempUrl',
            prop: elementFileTempUrl,
          },
          {
            propName: 'imagePreviewElementTempPath',
            prop: null,
          }], true);
        }}
        setElementUrl={(elementUrl) => {}}
        isLoadingDNG={isLoadingDNG}
        setIsLoadingDNG={setIsLoadingDNG}
        isLoadPreviewDNG={admin.id === request.adminId}
        setElementPreviewUrl={(elementPreviewUrl) => {
          handleUpdate([{
            propName: 'imagePreviewElementTempPath',
            prop: elementPreviewUrl,
          }], true);
        }}
        tempNameDNG={tempNameDNG}
        elementPreviewUrl={request.elementPreviewUrl}
        handleFileDrop={handleFileDrop}
      />
      <BrowserView>
        {getDetailFooter()}
      </BrowserView>
    </div>;
  };

  return <div className={getStyle('request-details-container')}>
    <RequestDetailsHeader
      request={request}
      close={cancel}
      isHideDelete={admin.adminType === 'FLTR_REQUESTS_MANAGER' || isIamIndividualFltrRequestManager}
      deleteRequest={() => deleteRequest(request)}
      isDisableComplete={!isEnabledComplete}
      processingInterval={remoteConfig.requestProcessingInterval}
      changeStatus={(status) => {
        if (status === REQUEST_STATUS.DONE) {
          cancel();
          showModal(typeModal.DONE_REQUEST, true, {
            onAccept: () => {
              handleChangeStatus(REQUEST_STATUS.DONE);
            },
          });
        } else {
          handleChangeStatus(status);
        }
      }}
    />
    {renderBody()}
    <MobileView>
      {getDetailFooter()}
    </MobileView>
  </div>;
};

const mapStateToProps = (state) => ({
  admin: getAdmin(),
  remoteConfig: state.appState.remoteConfig,
});

const mapDispatchToProps = (dispatch) => ({
  updateRequest: bindActionCreators(updateRequest, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);
