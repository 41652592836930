import React from 'react';
import {getStyle} from '../../utils/StyleUtils';
import {isMobileOnly} from 'react-device-detect';

export const DetailsFooterMode = {
  EDIT: 'EDIT',
  SAVE: 'SAVE',
};

export const DetailsFooter = ({mode, deleteEntity, handleSave, entity, cancel, isDisabled, saveText = 'Save'}) => {
  if (isMobileOnly) {
    return <div className={getStyle('details-footer')}>
      <button className={'save-btn-footer'}
        onClick={handleSave}
        disabled={isDisabled}
      >
        {mode === 'EDIT' ? saveText : 'Add'}
      </button>
    </div>;
  }

  return <div className={getStyle('details-footer')}>
    <button className={mode === 'EDIT' ? 'fltr-second-delete-btn' : 'fltr-second-btn'}
      style={{marginRight: (mode === 'EDIT' ? 'auto' : undefined)}}
      onClick={() => {
        if (mode === 'EDIT') {
          deleteEntity(entity);
        }
        cancel();
      }}
    >
      {mode === 'EDIT' ? 'Delete' : 'Cancel'}
    </button>
    <button className={'save-btn'} style={{marginLeft: '24px'}}
      onClick={handleSave}
      disabled={isDisabled}
    >
      {mode === 'EDIT' ? saveText : 'Add'}
    </button>
  </div>;
};
