import {DatePickerFltr} from '../../general/DatePickerFltr';
import React from 'react';

export const TodayInput = ({style, setTodayDate, todayDate}) => {
  return <div style={style}>
    <div className={'fltr-second-text'}>
                    Today
    </div>
    <div style={{display: 'flex', marginTop: '5px'}}>
      <DatePickerFltr onChangeDate={setTodayDate} date={todayDate}/>
      { todayDate ?
                        <button className={'fltr-second-btn'} style={{marginLeft: '10px'}}
                          onClick={() => {
                            setTodayDate(null);
                          }}>
                            Clear
                        </button> : null
      }
    </div>
  </div>;
};
