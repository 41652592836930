import React, {useState} from 'react';
import {locales} from '../../utils/LocalizationUtils';
import {LocaleTagColumn} from './LocaleTagColumn';

export const TagsList = ({selectedTagsContentType, tags, updateTag, setDeleteTag, setEditTag, orderParams, orderTags}) => {
  const [selectedTagId, selectTagId] = useState(null);

  return <div className={'tags-list-container'}>
    {locales.map((locale, index) => {
      return <LocaleTagColumn
        locale={locale}
        key={locale}
        tags={tags}
        selectedTagId={selectedTagId}
        selectTagId={selectTagId}
        updateTag={updateTag}
        setDeleteTag={setDeleteTag}
        setEditTag={setEditTag}
        orderParams={orderParams}
        orderTags={orderTags}
        selectedTagsContentType={selectedTagsContentType}
        style={{
          backgroundColor: (index % 2 === 1 ? '#f5f5f5' : undefined),
          width: (index === 0 ? '120px' : '100px'),
        }}
      />;
    })}
  </div>;
};
