import React, {useEffect, useState} from 'react';
import DropdownWrapper from '../../general/DropdownWrapper';
import {ReactComponent as ArrowDownIcon} from '../../../resources/imgs/arrow-down-icon.svg';
import {REQUEST_STATUS} from '../../../actions/requests';
import {RequestStatusSelectBody} from './RequestStatusSelectBody';

export const RequestDetailsStatus = ({status, changeStatus, isDisableComplete}) => {
  const [statusLocal, setStatus] = useState(status);
  useEffect(() => {
    setStatus(status);
  }, [status]);

  const renderSelect = () => {
    const iconStyle = {
      paddingLeft: '3px',
    };
    switch (statusLocal) {
      case REQUEST_STATUS.DONE:
        return <div className={'fltr-status-select-done'}>
                  DONE
          <ArrowDownIcon style={iconStyle}/>
        </div>;
      case REQUEST_STATUS.NEW:
        return <div className={'fltr-status-select-new'}>
                  NEW
          <ArrowDownIcon style={iconStyle}/>
        </div>;
      case REQUEST_STATUS.IN_PROGRESS:
        return <div className={'fltr-status-select-in-progress'}>
                  IN PROGRESS
          <ArrowDownIcon style={iconStyle}/>
        </div>;
    }
  };

  return <div>
    <DropdownWrapper style={{right: '-100%'}}>
      {renderSelect()}
      <RequestStatusSelectBody
        changeStatus={(status) => {
          setStatus(status);
          changeStatus(status);
        }}
        isDisableComplete={isDisableComplete}
      />
    </DropdownWrapper>
  </div>;
};

