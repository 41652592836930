import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {publish} from '../../actions/general';
import {ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';
import {getStyle} from '../../utils/StyleUtils';

const PublishFeedDialog = ({publish, version, cancel}) => {
  const renderBody = () => {
    return <div className={'dialog-publish-body'}>
      <AttentionIcon/>
      <div className={'fltr-attention-text'} style={{marginTop: '12px'}}>
                Are you sure ?
      </div>
      <div className={'fltr-attention-description-text'} style={{marginTop: '25px'}}>
                Are you sure you want to publish version <b>{version ? version.id + 1 : 1}?</b>
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'dialog-publish-footer'}>
      <button className={'fltr-second-btn'} onClick={()=>{
        cancel();
      }}>
                Cancel
      </button>

      <button className={'save-btn'} style={{
        marginLeft: '25px',
      }}
      onClick={()=>{
        cancel();
        publish();
      }}
      >
                Yes, publish it!
      </button>
    </div>;
  };

  return <div className={getStyle('dialog-container')}>
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({
  version: state.appState.version,
});

const mapDispatchToProps = (dispatch) => ({
  publish: bindActionCreators(publish, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishFeedDialog);

