import {WEBSOCKET_SEND, WebsocketAction} from '../middlewares/websockets/types';
import {batch} from 'react-redux';
import {createRequest} from '../utils/HttpRequestUtills';
import {getTimestampUTCms} from '../utils/TimeUtils';

export const CHANGE_SEARCH_PARAMS_REQUESTS = 'CHANGE_SEARCH_PARAMS_REQUESTS';
export const ORDER_REQUESTS = 'ORDER_REQUESTS';
export const SET_EDIT_REQUEST = 'SET_EDIT_REQUEST';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const SET_DELETE_REQUEST = 'SET_DELETE_REQUEST';
export const SET_PROCESSED_REQUEST = 'SET_PROCESSED_REQUEST';
export const SET_IS_LOADING_REQUESTS = 'SET_IS_LOADING_REQUESTS';
export const SET_IS_LOADING_REQUEST = 'SET_IS_LOADING_REQUEST';
export const CHANGE_SEARCH_REQUESTS_PAGE = 'CHANGE_SEARCH_REQUESTS_PAGE';
export const CHANGE_SEARCH_REQUESTS_STATUS = 'CHANGE_SEARCH_REQUESTS_STATUS';
export const CHANGE_SEARCH_REQUESTS_ADMIN_ID = 'CHANGE_SEARCH_REQUESTS_ADMIN_ID';

export const REQUEST_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  NEW: 'NEW',
};

export const getRequests = (params) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_REQUESTS,
    isLoading: true,
  });

  const payload = {
    action: WebsocketAction.GET_FLTR_REQUESTS,
    stateId: getState().requests.stateId,
    status: params.status,
    adminId: params.adminId,
    paginationOrder: {
      limit: params.limit,
      page: params.page,
      order: {
        orderBy: params.orderBy,
        orderIndex: params.orderIndex,
      },
    },
  };

  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const getRequest = (requestId) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_REQUEST,
    isLoading: true,
  });

  const payload = {
    action: WebsocketAction.GET_FLTR_REQUEST,
    stateId: getState().requests.stateId,
    requestId,
  };

  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const changeSearchParamsRequests = (paramName, paramValue) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PARAMS_REQUESTS,
    paramName,
    paramValue,
  });
};

export const changeOrderByRequests = (orderParams) => (dispatch) => {
  dispatch({
    type: ORDER_REQUESTS,
    orderParams,
  });
};

export const updateRequest = (props, requestId) => (dispatch, getState) => {
  const requestTimeEpoch = getTimestampUTCms();
  let isSkipSendToServer = true;
  props.forEach((prop) => {
    if (isSkipSendToServer) {
      isSkipSendToServer = prop.isSkip;
    }
  });
  dispatch({
    type: WebsocketAction.UPDATE_FLTR_REQUEST.name,
    data: {
      requestTimeEpoch,
      message: {
        props,
        requestId,
      },
    },
  });
  if (!isSkipSendToServer) {
    const payload = {
      action: WebsocketAction.UPDATE_FLTR_REQUEST,
      requestTimeEpoch,
      stateId: getState().requests.stateId,
      requestId,
      props,
    };

    dispatch({
      type: WEBSOCKET_SEND,
      payload: payload,
    });
  }
};

export const setEditRequest = (request) => (dispatch) => {
  batch(() => {
    dispatch({
      type: SET_EDIT_REQUEST,
      request,
    });
    dispatch({
      type: SELECT_REQUEST,
      request,
    });
  });
};

export const deleteRequest = (requestId) => (dispatch) => {
  dispatch({
    type: WebsocketAction.DELETE_REQUEST.name,
    data: {
      message: {
        requestId,
      },
    },
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'DELETE', '/request/' + requestId, onload);

  xhr.send();
};

export const setDeleteRequest = (request) => (dispatch) => {
  dispatch({
    type: SET_DELETE_REQUEST,
    request,
  });
};

export const setProcessedRequest = (request) => (dispatch) => {
  dispatch({
    type: SET_PROCESSED_REQUEST,
    request,
  });
};

export const addFakeRequest = () => {
  const onload = () => {};
  const xhr = createRequest(null, 'POST', '/fake/request', onload);
  xhr.send(JSON.stringify({}));
};

export const changeSearchRequestsPage = (page) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_REQUESTS_PAGE,
    page,
  });
};

export const changeSearchRequestsStatus = (status) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_REQUESTS_STATUS,
    status,
  });
};

export const changeSearchRequestsAdminId = (adminId) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_REQUESTS_ADMIN_ID,
    adminId,
  });
};
