import React from 'react';
import {IoMdClose as CloseIcon} from 'react-icons/io';

import MoveTo from './MoveTo';
import DropdownWrapper from './DropdownWrapper';
import {compareString} from '../../utils/ArrayUtils';

export const TagsInput = ({tagIds, allTags, addTag, removeTag, sourceItem}) => {
  return <DropdownWrapper style={{right: '31%', paddingTop: '10px'}} isRevert={true} isRerender={true}>
    <div className={'tags-input-container'}>
      {
        tagIds.map((tagId) => {
          const tag = allTags.find((tag) => tag.id === tagId);
          return <div className={'tag-badge'}
            key={tagId}
          >
            {`${tag.name}`}
            <div className={'close-tag'}>
              <CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  removeTag(tagId);
                }}
                style={{paddingLeft: '5px', width: '20px'}}
              />
            </div>
          </div>;
        })
      }
    </div>

    <MoveTo
      moveTo={(sourceItem, tag) => {
        addTag(tag);
      }}
      items={allTags
          .filter((tag) => {
            return !tagIds.find((tagId) => {
              return tagId === tag.id;
            }) && tag.isActive;
          })
          .sort(compareString('name'))
      }
      isFocus={true}
      isClose={false}
      propName={'name'}
      renderProp={(tag) => {
        return `${tag.name} (${tag.elementsIds.length})`;
      }}
      placeholder={'Search'}
      sourceItem={sourceItem}
      containerClassName={'component-settings-container'}
    />

  </DropdownWrapper>;
};
