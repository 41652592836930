import React, {useEffect, useState} from 'react';
import {ReactComponent as CloseIcon} from '../../../resources/imgs/close-icon.svg';
import {ReactComponent as DotsIcon} from '../../../resources/imgs/dots-icon.svg';
import {ReactComponent as BackNavigationIcon} from '../../../resources/imgs/back-navigation-icon.svg';
import {getStyle} from '../../../utils/StyleUtils';
import {REQUEST_STATUS} from '../../../actions/requests';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import {durationToHM, timeIntervalRequestMS, timeLeft, timeLeftForAttentionRequest} from '../../../utils/TimeUtils';
import {RequestDetailsStatus} from './RequestDetailsStatus';
import momentZ from 'moment-timezone';
import DropdownWrapper from '../../general/DropdownWrapper';
import {RequestActionsBody} from './RequestActionsBody';

export const RequestDetailsHeader = ({request, close, changeStatus, isDisableComplete, deleteRequest, isHideDelete, processingInterval = timeIntervalRequestMS}) => {
  const [refreshInterval, setRefreshInterval] = useState(0);

  useEffect(() => {
    setTimeout(function() {
      setRefreshInterval(refreshInterval + 1);
    }, 60000);
  }, [refreshInterval]);

  const msLeftTime = timeLeft(request.createdAt, processingInterval, request.closeDate ? momentZ(request.closeDate) : undefined);
  const isExpired = msLeftTime < timeLeftForAttentionRequest && request.status !== REQUEST_STATUS.DONE;

  const renderNavigationBar = () => {
    return <div className={'request-details-header-container'}>
      <BrowserView>
        <CloseIcon className={'fltr-close-navigation'}
          onClick={() => {
            close();
          }}
        />
      </BrowserView>
      <MobileView>
        <BackNavigationIcon
          onClick={() => {
            close();
          }}
        />
      </MobileView>
      <div className={'request-details-header-navigation-info'}>
        <div className={'request-details-header-navigation-info-request'}>Request {request.id}</div>
        <BrowserView>
          <div className={'request-details-header-navigation-info-makes'}>makes {request.adminName}</div>
        </BrowserView>
        <MobileView>
          <div className={'request-details-header-navigation-info-makes'}
            style={{opacity: request.adminName ? 1 : 0}}
          >
            {request.adminName ? request.adminName : '-'}
          </div>
        </MobileView>
      </div>
      <div style={{alignSelf: 'start'}}>
        {isHideDelete ? null :
            <DropdownWrapper style={{left: '-100%'}}>
              <DotsIcon style={{opacity: isMobile ? '1' : '0'}}/>
              <RequestActionsBody
                deleteRequest={deleteRequest}
              />
            </DropdownWrapper>
        }
      </div>
    </div>;
  };

  const renderInfo = () => {
    return <div className={'request-details-header-container'}>
      <div className={'request-details-header-end-in-container'}>
        <div className={'request-details-header-end-in-title'}>END IN</div>
        <div className={'request-details-header-end-in-value'}
          style={{color: isExpired ? '#F13838' : undefined}}
        >
          {durationToHM(msLeftTime)}
        </div>
      </div>
      <RequestDetailsStatus
        status={request.status}
        changeStatus={changeStatus}
        isDisableComplete={isDisableComplete}
      />
    </div>;
  };

  return <div className={getStyle('request-details-header')}>
    {renderNavigationBar()}
    {renderInfo()}
  </div>;
};
