import React from 'react';
import MDSpinner from 'react-md-spinner';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selectPack} from '../../actions/packs';
import {ComponentType} from '../../actions/general';
import Pack from '../packs/Pack';
import {Droppable} from 'react-beautiful-dnd';
import {PacksListType} from '../packs/PacksList';
import Element from '../elements/Element';
import {ElementsListType} from '../elements/ElementsList';

const PremiumList = ({isLoadingPacks, selectedLang, packs, elements,
  isLoadingElements, selectPack, selectedPackIds}) => {
  if (isLoadingPacks || isLoadingElements) {
    return (
      <div className='spinner-container' style={{marginTop: '212px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  const premiumComponents = [];

  elements.forEach((element) => {
    if (element.isPremium) {
      element.componentType = ComponentType.ELEMENT;
      premiumComponents.push(element);
    }
  });

  packs.forEach((pack) => {
    if (pack.isPremium) {
      pack.componentType = ComponentType.PACK;
      premiumComponents.push(pack);
    }
  });

  return (
    <Droppable droppableId={'premiumListList'} isDropDisabled={true}>
      {(provided, snapshot) => (
        <div className={'section-container'}
          ref={provided.innerRef}
        >
          <div className={'fltr-items-container-none-height'} style={{paddingTop: '12px', paddingBottom: '12px'}}>
            {
              premiumComponents.sort((a, b) => {
                return a.id - b.id;
              }).map((premiumComponent, index) => {
                if (premiumComponent.componentType === ComponentType.PACK) {
                  return <Pack
                    key={premiumComponent.id}
                    arrayIndex={index}
                    pack={premiumComponent}
                    id={premiumComponent.id}
                    isSelected={selectedPackIds[PacksListType.PACKS_IN_PREMIUM].indexOf(premiumComponent.id + '_premium') !== -1}
                    selectPack={selectPack}
                    movePack={null}
                    endMove={null}
                    findPack={null}
                    externalId={'premium'}
                    packListType={PacksListType.PACKS_IN_PREMIUM}
                    elements={elements}
                    isBlockDnD={true}
                  />;
                }

                if (premiumComponent.componentType === ComponentType.ELEMENT) {
                  return <Element
                    key={premiumComponent.id}
                    element={premiumComponent}
                    arrayIndex={index}
                    id={premiumComponent.id}
                    isSelected={false}
                    selectElement={null}
                    moveElement={null}
                    endMove={null}
                    findElement={null}
                    editElement={null}
                    elementListType={ElementsListType.ELEMENTS_IN_PREMIUM}
                    isBlockDnD={true}
                  />;
                }
                return null;
              })
            }
          </div>
        </div>)}</Droppable>
  );
};

const mapStateToProps = (state) => ({
  isLoadingPacks: state.packs.isLoadingPacks,
  selectedLang: state.appState.selectedLang,
  elements: state.elements.elements,
  isLoadingElements: state.elements.isLoadingElements,
  packs: state.packs.packs,
  selectedPackIds: state.packs.selectedPackIds,
});

const mapDispatchToProps = (dispatch) => ({
  selectPack: bindActionCreators(selectPack, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumList);
