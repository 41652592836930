/* eslint-env browser */

import {WebsocketMessageType} from './types';
import {groupArray, groupFrames} from '../../utils/WebsocketUtils';

/**
 * Formats args for creating the WebSocket instance
 */
const extractArgs = (config) => {
  if (config.args) {
    return config.args;
  }

  if (config.url) {
    return [config.url];
  }

  return [];
};

/**
 * Create a websocket object from the incoming config
 */
export const createWebsocket = (payload) => {
  const args = extractArgs(payload);
  const websocket = (payload.websocket) ? payload.websocket : WebSocket;
  // eslint-disable-next-line new-cap
  return new websocket(...args);
};

export const message = (message) => {
  if (message.message !== undefined || message.action !== undefined) {
    if (message.messageType === WebsocketMessageType.FRAMES) {
      return groupFrames(message);
    }
    if (message.messageType === WebsocketMessageType.ARRAY) {
      return groupArray(message);
    }

    return {
      type: message.action,
      data: message,
    };
  }
};

export const processEventToMessage = (event, serviceType, messageAction) => {
  const message = JSON.parse(event.data);

  if (message.action && message.action.name) {
    if (message.action.serviceType === serviceType) {
      messageAction({...message, ...{action: message.action.name}});
    }
  } else {
    messageAction(event);
  }
};
