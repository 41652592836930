import React from 'react';
import {connect} from 'react-redux';
import {showModal, typeModal} from '../../actions/general';
import AddHeader from '../general/AddHeader';
import {bindActionCreators} from 'redux';
import ElementsList, {ElementsListType} from './ElementsList';

const ElementsSection = ({elements, selectedContentTypeId, elementsInPacks}) => {
  const filteredElements = elements
      .filter((element) => (
        element.contentTypeId === selectedContentTypeId && element.elementToPacks.length === 0
      ))
      .sort((a, b) => (a.id - b.id));

  return (
    <div className={'section-container'} style={{marginTop: '30px'}}>
      <AddHeader
        title={`Elements (${filteredElements.length})`}
        modalType={typeModal.ELEMENT_ADD}
      />

      <div className={'fltr-items-container'}>
        <ElementsList
          elements={filteredElements}
          elementsInPacks={elementsInPacks}
          elementsListType={ElementsListType.ONLY_ELEMENTS}
        />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  elements: state.elements.elements,
  selectedContentTypeId: state.appState.selectedContentTypeId,
  elementsInPacks: state.elements.elements,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementsSection);
