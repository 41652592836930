import React from 'react';
import momentZ from 'moment-timezone';
import Pack from '../packs/Pack';
import {PacksListType} from '../packs/PacksList';
import Element from '../elements/Element';
import {ElementsListType} from '../elements/ElementsList';
import {ComponentType} from '../../actions/general';

export const Today = ({components, style, todayDate, elements, selectPack, selectedPackIds}) => {
  const renderHeader = () => {
    return <div className={'today-header'}>
      <div className={'today-header-name'}>
        {momentZ(todayDate).format('DD MMM')}
      </div>

    </div>;
  };

  return (
    <div className={'fltr-items-container-none-height'} style={style}>
      {renderHeader()}
      {components.map((component, index) => {
        if (component.componentType === ComponentType.PACK) {
          return <Pack
            key={component.id}
            arrayIndex={index}
            pack={component}
            id={component.id}
            isSelected={selectedPackIds[PacksListType.PACKS_IN_TODAY].indexOf(component.id + '_' + todayDate) !== -1}
            selectPack={selectPack}
            movePack={null}
            endMove={null}
            findPack={null}
            externalId={todayDate}
            packListType={PacksListType.PACKS_IN_TODAY}
            elements={elements}
            isBlockDnD={true}
          />;
        }

        if (component.componentType === ComponentType.ELEMENT) {
          return <Element
            key={component.id}
            element={component}
            id={component.id}
            arrayIndex={index}
            isSelected={false}
            selectElement={null}
            moveElement={null}
            endMove={null}
            findElement={null}
            editElement={null}
            elementListType={ElementsListType.ELEMENTS_IN_TODAY}
            isBlockDnD={true}
          />;
        }
        return null;
      })}
    </div>
  );
};
