import {combineReducers} from 'redux';
import appState from './appState';
import auth from './auth';
import packs from './packs';
import groups from './groups';
import elements from './elements';
import tags from './tags';
import requests from './requests';

const appReducer = combineReducers({
  appState,
  auth,
  packs,
  groups,
  elements,
  tags,
  requests,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
