import React, {useState} from 'react';
import {EditImg} from '../general/EditImg';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {compressImage, uploadFileToS3} from '../../utils/FilesUtils';
import uuid from 'uuid/v4';
import {DetailsFooter} from '../general/DetailsFooter';
import {Checkbox} from '../general/Checkbox';
import {addTag, updateTag} from '../../actions/tags';

const TagDetails = ({tag, mode, cancel, deleteTag,
  addTag, updateTag, lastTagOrder,
}) => {
  const [isActive, setIsActive] = useState(tag.isActive);
  const [name, setName] = useState(tag.name);
  const [imageTempPath, setImageTempPath] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(tag.imageUrl);

  const [iconImageTempPath, setIconImageTempPath] = useState(null);
  const [isLoadingIconImage, setIsLoadingIconImage] = useState(false);
  const [iconImageUrl, setIconImageUrl] = useState(tag.iconImageUrl);

  const [highlightImageTempPath, setHighlightImageTempPath] = useState(null);
  const [isLoadingHighlightImage, setIsLoadingHighlightImage] = useState(false);
  const [highlightImageUrl, setHighlightImageUrl] = useState(tag.highlightImageUrl);

  const handleFileDrop = (file, setIsLoadingImage, setImageTempPath, setImageUrl) => {
    if (file) {
      setIsLoadingImage(true);
      console.log('File size ' + file.size);
      compressImage(file, 4000000, (fileCompressed) => {
        console.log('FileCompress size ' + fileCompressed.size);
        uploadFileToS3(fileCompressed, uuid(), (pathTemp) => {
          setImageTempPath(pathTemp);
          setIsLoadingImage(false);
          setImageUrl(URL.createObjectURL(file));
        });
      });
    }
  };

  const handleSave = () => {
    const tagForSave = {...tag};
    tagForSave.id = !tagForSave.id ? uuid() : tagForSave.id;
    tagForSave.isActive = isActive;
    tagForSave.name = name;
    tagForSave.imageTempPath = imageTempPath;
    tagForSave.imageUrl = imageUrl;
    tagForSave.iconImageTempPath = iconImageTempPath;
    tagForSave.iconImageUrl = iconImageUrl;
    tagForSave.highlightImageTempPath = highlightImageTempPath;
    tagForSave.highlightImageUrl = highlightImageUrl;
    tagForSave.order = mode === 'EDIT' ? tagForSave.order : lastTagOrder + 1;
    if (!tagForSave.localization.en) {
      tagForSave.localization.en = {};
    }
    tagForSave.localization.en.name = name;
    mode === 'EDIT' ? updateTag(tagForSave) : addTag(tagForSave);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'} style={{flexDirection: 'row'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'fltr-head-text'}>
          {mode === 'EDIT' ? 'Edit Tag' : 'Add Tag'}
        </div>
        <div className={'fltr-second-text'}>
          {tag.id ? 'ID: ' + tag.id : ''}
        </div>
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'pack-details-body'}>
      <Checkbox
        key={'visibility-checkbox'}
        switch={setIsActive}
        isActive={isActive}
        text={'Visibility'}
      />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '121px'}}>
          <div className={'fltr-second-text'}>
            Presets photo
          </div>
          <EditImg imgUrl={imageUrl}
            style={{marginTop: '5px', height: '91px', width: '121px'}}
            heightImg={'91px'}
            widthImg={'121px'}
            onDrop={(file) => {
              handleFileDrop(file, setIsLoadingImage, setImageTempPath, setImageUrl);
            }}
            isLoading={isLoadingImage}
            clear={() => {
              setImageTempPath(null);
              setImageUrl(null);
            }}
          />
        </div>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '121px'}}>
          <div className={'fltr-second-text'}>
            Highlights photo
          </div>
          <EditImg imgUrl={highlightImageUrl}
            style={{marginTop: '5px', height: '91px', width: '121px'}}
            heightImg={'91px'}
            widthImg={'121px'}
            onDrop={(file) => {
              handleFileDrop(file, setIsLoadingHighlightImage, setHighlightImageTempPath, setHighlightImageUrl);
            }}
            isLoading={isLoadingHighlightImage}
            clear={() => {
              setHighlightImageTempPath(null);
              setHighlightImageUrl(null);
            }}
          />
        </div>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '121px'}}>
          <div className={'fltr-second-text'}>
            Icons photo
          </div>
          <EditImg imgUrl={iconImageUrl}
            style={{marginTop: '5px', height: '91px', width: '121px'}}
            heightImg={'91px'}
            widthImg={'121px'}
            onDrop={(file) => {
              handleFileDrop(file, setIsLoadingIconImage, setIconImageTempPath, setIconImageUrl);
            }}
            isLoading={isLoadingIconImage}
            clear={() => {
              setIconImageTempPath(null);
              setIconImageUrl(null);
            }}
          />
        </div>
      </div>

      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', marginTop: '20px', flexDirection: 'column', width: '200px'}}>
          <div className={'fltr-second-text'}>
                        Tag Name
          </div>
          <Input style={{marginTop: '5px', width: '100%'}}
            handleChange={setName}
            value={name}
          />
        </div>
      </div>
    </div>;
  };

  return <div className="pack-details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteTag}
      cancel={cancel}
      entity={tag}
      handleSave={handleSave}
      isDisabled={name === ''}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  lastTagOrder: state.tags.tags[state.tags.tags.length - 1].order,
});

const mapDispatchToProps = (dispatch) => ({
  addTag: bindActionCreators(addTag, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagDetails);
