import React, {Component} from 'react';
import {ReactComponent as CalendarIcon} from '../../resources/imgs/calendar-icon.svg';
import DatePicker from 'react-datepicker';
import momentZ from 'moment-timezone';

export const DatePickerFltr = ({onChangeDate, date}) => {
  const DateInput = ({value, onClick}) => {
    return <div className="fltr-date-picker-input" onClick={onClick}>
      <div className={'fltr-date-picker-text'}>
        {value}
      </div>
      <CalendarIcon/>
    </div>;
  };
  return <DatePicker
    selected={date ? new Date(new Date(momentZ(date).toDate()).toISOString().replace('Z', '')) : null}
    onChange={(date) => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const mo1 = momentZ(date);
      const mo2 = momentZ(date).tz(timeZone);

      const modifiedDate = momentZ(date);
      modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');
      onChangeDate(modifiedDate.valueOf());
    }}
    showTimeSelect
    timeFormat="hh:mm a"
    timeIntervals={30}
    dateFormat="yyyy-MM-dd hh:mm aa"
    timeCaption="time"
    customInput={<DateInput/>}
  />;
};
