import React, {useEffect, useState} from 'react';
import {Input} from '../../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addElement, updateElement} from '../../../actions/elements';
import {changeLocalization, getLocalizedValue} from '../../../utils/LocalizationUtils';
import {prepareFileToUpload} from '../../../utils/FilesUtils';
import uuid from 'uuid/v4';
import {AddToPack} from './AddToPack';
import {DngInput} from '../../general/DngInput';
import {TodayInput} from './TodayInput';
import {BeforeAfter} from './BeforeAfter';
import {DetailsFooter} from '../../general/DetailsFooter';
import {MetaType, showModal, typeModal} from '../../../actions/general';
import {TagsInput} from '../../general/TagsInput';
import {CheckboxElementsDetails} from './CheckboxElementsDetails';
import {DescriptionElementsDetails} from './DescriptionElementsDetails';
import {LutInput} from '../../general/LutInput';

const PresetDetails = ({element, mode, cancel, deleteElement, tags,
  addElement, updateElement, languages, packs, meta={}, showModal
}) => {
  const [isSetToday, setIsSetToday] = useState(meta.isToday !== undefined ? meta.isToday : element.todayDate);
  const [tempNameAfter] = useState(uuid());
  const [tempNameBefore] = useState(uuid());
  const [tempNameDNG] = useState(uuid());

  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [isActive, setIsActive] = useState(meta.isActive !== undefined ? meta.isActive : element.isActive);
  const [isNew, setIsNew] = useState(element.isNew);
  const [isPremium, setIsPremium] = useState(element.isPremium);
  const [isFree, setIsFree] = useState(meta.isFree !== undefined ? meta.isFree : element.isFree);
  const [name, setName] = useState(element.name);
  const [imageTempPath, setImageTempPath] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingDNG, setIsLoadingDNG] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [todayDate, setTodayDate] = useState(meta.todayDate !== undefined ? meta.todayDate : element.todayDate);
  const [imageUrl, setImageUrl] = useState(element.imageUrl);
  const [imageProcessedTempPath, setImageProcessedTempPath] = useState(null);
  const [isLoadingImageProcessed, setIsLoadingImageProcessed] = useState(false);
  const [imageProcessedUrl, setImageProcessedUrl] = useState(element.processedImageUrl);
  const [elementFileTempUrl, setElementFileTempUrl] = useState(null);
  const [elementUrl, setElementUrl] = useState(element.elementUrl);
  const [description, setDescription] = useState(element.description);
  const [localization, setLocalization] = useState(element.localization);
  const [tagIds, setTagIds] = useState(element.fltrTagIds);
  const [elementPreviewUrl, setElementPreviewUrl] = useState(element.elementPreviewUrl);
  const [filterUrl, setFilterUrl] = useState(element.filterUrl);
  const [filterTempUrl, setFilterTempUrl] = useState(null);

  const [joinPack, setJoinPack] = useState(null);

  useEffect(() => {
    if (meta) {
      if (meta.type && meta.type === MetaType.WithPack) {
        const pack = packs.find((pack) => pack.id === meta.packId);
        if (pack) {
          setJoinPack(pack);
        }
      }
    }
  }, [meta]);

  const handleFileDrop = (file, action, actionUrl, setIsLoading, tempName, isUploadToS3 = true) => {
    setIsForceUpdate(true);
    prepareFileToUpload(file, action, actionUrl, setIsLoading, tempName, isUploadToS3, 4000000);
  };

  const handleSave = () => {
    const elementForSave = {...element};
    elementForSave.isActive = isActive;
    elementForSave.isFree = isFree;
    elementForSave.isPremium = isPremium;
    elementForSave.isNew = isNew;
    elementForSave.name = name;
    elementForSave.description = description;
    elementForSave.isForceUpdate = isForceUpdate;
    elementForSave.imageTempPath = imageTempPath;
    elementForSave.fltrTagIds = tagIds;
    elementForSave.todayDate = todayDate;
    elementForSave.imageUrl = imageUrl;
    elementForSave.processedImageUrl = imageProcessedUrl;
    elementForSave.previewUrl = imageProcessedUrl;
    elementForSave.imageProcessedTempPath= imageProcessedTempPath;
    elementForSave.elementFileTempUrl = elementFileTempUrl;
    elementForSave.elementUrl = elementUrl;
    elementForSave.filterUrl = filterUrl;
    elementForSave.filterTempUrl = filterTempUrl;
    elementForSave.localization = localization;
    elementForSave.isUpdateRelations = isUpdateRelations;
    elementForSave.elementToPacks = mode === 'EDIT' ? elementForSave.elementToPacks : [];
    elementForSave.order = 0;
    elementForSave.elementPreviewUrl = elementPreviewUrl;
    elementForSave.imagePreviewElementTempPath = elementPreviewUrl;
    elementForSave.sku = elementForSave.sku ? elementForSave.sku : uuid();

    if (joinPack) {
      elementForSave.order = joinPack.elementToPacks.length + 1;
      elementForSave.elementToPacks.push({
        packId: joinPack.id,
        uuid: uuid(),
        order: joinPack.elementToPacks.length + 1,
      });
    }
        mode === 'EDIT' ? updateElement(elementForSave) : addElement(elementForSave);
        cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'}>
      <div className={'fltr-head-text'}>
        {(mode === 'EDIT' ? 'Edit ' : 'Add ') + element.contentTypeId}
      </div>
      {
        element.id ?       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <div className={'fltr-second-text'}>
            {element.id ? 'ID: ' + element.id : ''}
          </div>
          <div className={'fltr-second-btn'}
               onClick={() => {
                 if(element.id) {
                   showModal(typeModal.CREATE_PERSONAL_NOTIFICATION, true, {entityId: element.id})
                 }
               }}
          >
            Send notification
          </div>
        </div> : null
      }
    </div>;
  };

  const renderTags = () => {
    return <div style={{
      paddingTop: '17px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'fltr-second-text'}>
                Tags
      </div>
      <TagsInput
        tagIds={tagIds}
        allTags={tags}
        addTag={ (tag) => {
          setIsUpdateRelations(true);
          const newTagIds = [...tagIds].filter( (tagId) => tag.id !== tagId);
          newTagIds.push(tag.id);
          setTagIds(newTagIds);
        }}
        removeTag={(removeTagId) => {
          const newTagIds = [...tagIds].filter( (tagId) => removeTagId !== tagId);
          setIsUpdateRelations(true);
          setTagIds(newTagIds);
        }}
      />
      <div className={'fltr-second-text'} style={{
        paddingTop: '5px',
      }}>
        {description}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'element-details-body'}>
      <CheckboxElementsDetails
        isFree={isFree}
        setIsFree={setIsFree}
        isNew={isNew}
        setIsNew={setIsNew}
        setIsPremium={setIsPremium}
        isPremium={isPremium}
        setIsSetToday={setIsSetToday}
        setTodayDate={setTodayDate}
        isSetToday={isSetToday}
        setIsActive={setIsActive}
        isActive={isActive}
      />

      {
                isSetToday ?
                    <TodayInput
                      style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}
                      setTodayDate={setTodayDate}
                      todayDate={todayDate}
                    /> : null
      }

      <BeforeAfter
        style={{display: 'flex', marginTop: '20px'}}
        imageUrl={imageUrl}
        handleFileDrop={handleFileDrop}
        setImage={setImageTempPath}
        setImageUrl={setImageUrl}
        setIsLoadingBefore={setIsLoadingImage}
        isLoadingBefore={isLoadingImage}
        setIsLoadingAfter={setIsLoadingImageProcessed}
        isLoadingAfter={isLoadingImageProcessed}
        setIsForceUpdate={setIsForceUpdate}
        imageProcessedUrl={imageProcessedUrl}
        setImageProcessed={setImageProcessedTempPath}
        setImageProcessedUrl={setImageProcessedUrl}
        tempNameAfter={tempNameAfter}
        tempNameBefore={tempNameBefore}
      />

      <div style={{'display': 'flex', 'alignItems': 'center'}}>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '184px'}}>
          <div className={'fltr-second-text'}>
                        Name
          </div>
          <Input style={{marginTop: '7px', width: '100%'}}
            handleChange={(value) => {
              changeLocalization(value, 'name', localization, setName, setLocalization);
            }}
            value={getLocalizedValue(localization, 'name')}
          />
        </div>
        <AddToPack
          style={{marginLeft: '30px'}}
          element={element}
          joinPack={joinPack}
          setJoinPack={setJoinPack}
          setIsUpdateRelations={setIsUpdateRelations}
          packs={packs}
        />
      </div>

      <div style={{'display': 'flex', 'alignItems': 'center'}}>
        <DngInput
          style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '200px'}}
          elementUrl={elementUrl}
          elementFileTempUrl={elementFileTempUrl}
          setElementFileUrl={(tempUrl) => {
            setElementFileTempUrl(tempUrl);
            setElementPreviewUrl(null);
          }}
          setElementUrl={setElementUrl}
          isLoadingDNG={isLoadingDNG}
          setIsLoadingDNG={setIsLoadingDNG}
          setElementPreviewUrl={(elementPreviewUrl) => {
            setIsForceUpdate(true);
            setElementPreviewUrl(elementPreviewUrl);
          }}
          tempNameDNG={tempNameDNG}
          elementPreviewUrl={elementPreviewUrl}
          handleFileDrop={handleFileDrop}
          maxSizeDngBytes={5242880}
          dngLabel={'DNG max 5mb'}
        />
        <LutInput
          style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '200px', marginLeft: '15px'}}
          filterUrl={filterUrl}
          filterTempUrl={filterTempUrl}
          setFilterFileUrl={(tempUrl) => {
            setFilterTempUrl(tempUrl);
          }}
          tempName={uuid()}
          setFilterUrl={setFilterUrl}
          isLoading={isLoadingFilter}
          setIsLoading={setIsLoadingFilter}
          handleFileDrop={handleFileDrop}
        />
      </div>

      {
                element.contentTypeId === 'Preset' ? renderTags() :
                    <DescriptionElementsDetails
                      languages={languages}
                      setDescription={setDescription}
                      setLocalization={setLocalization}
                      localization={localization}
                    />
      }

    </div>;
  };

  return <div className="element-details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteElement}
      cancel={cancel}
      entity={element}
      handleSave={handleSave}
      isDisabled={!imageUrl || !imageProcessedUrl || !elementUrl || name === '' || !elementPreviewUrl}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  languages: state.appState.languages,
  packs: state.packs.packs,
  tags: state.tags.tags,
});

const mapDispatchToProps = (dispatch) => ({
  addElement: bindActionCreators(addElement, dispatch),
  updateElement: bindActionCreators(updateElement, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresetDetails);
