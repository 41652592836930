import {EditImg} from '../../general/EditImg';
import {isMobileOnly} from 'react-device-detect';
import React from 'react';
import {getStyle} from '../../../utils/StyleUtils';
import {saveFile} from '../../../utils/FilesUtils';
import {BrowserView} from 'react-device-detect';

export const BeforeAfter = ({imageUrl, imageProcessedUrl, isLoadingAfter, setIsLoadingAfter, tempNameAfter,
  handleFileDrop, setImageProcessed, setImageProcessedUrl,
}) => {
  const height = isMobileOnly ? '130px' : '164px';
  const width = isMobileOnly ? '140px' : '124px';

  return <div className={getStyle(('request-before-after-container'))}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className={'fltr-second-text'}>
                Photo Before
      </div>
      <div className= "fltr-img-edit-container"
        style={{marginTop: '5px', height, width}}
      >
        <img className={'fltr-img-edit'} src={imageUrl}
          style={{height, width}}
        />
        <BrowserView>
          <div className={'download-img-block'}
            onClick={() => {
              saveFile(imageUrl);
            }}
          >
              DOWNLOAD
          </div>
        </BrowserView>
      </div>
    </div>
    <div style={{marginLeft: isMobileOnly ? '8px' : '26px', display: 'flex', flexDirection: 'column'}}>
      <div className={'fltr-second-text'}>
                Photo After
      </div>
      <EditImg imgUrl={imageProcessedUrl}
        style={{marginTop: '5px', height, width}}
        heightImg={height}
        widthImg={width}
        isLoading={isLoadingAfter}
        onDrop={(file) => handleFileDrop(
            file,
            setImageProcessed,
            setImageProcessedUrl,
            setIsLoadingAfter,
            tempNameAfter,
            setImageProcessedUrl,
        )}
        clear={() => {
          setImageProcessed(null);
          setImageProcessedUrl(null);
        }}
      />
    </div>
  </div>;
};
