import {getItem} from './LocalStoargeUtill';

export const getLocalizedValue = (localization, propName, localeEternal) => {
  const locale = localeEternal ? localeEternal : getItem('currentLang', 'en');

  if (!localization || !localization[locale] || !localization[locale][propName]) {
    return '';
  }

  return localization[locale][propName];
};

export const changeLocalization = (newValue, propName, localization, setDefValue, setLocalization, localeEternal) => {
  if (newValue === null || newValue === undefined) {
    return;
  }
  const locale = localeEternal ? localeEternal : getItem('currentLang', 'en');

  if (locale === 'en') {
    setDefValue(newValue);
  }

  const newLocalization = Object.assign({}, localization, {
    [locale]: Object.assign({}, localization[locale], {
      [propName]: newValue,
    }),
  });

  setLocalization(newLocalization);
};

export const locales = ['en', 'ru', 'de', 'cs', 'nl', 'fr', 'id', 'it', 'ja', 'ms', 'nb', 'pl', 'pt', 'es', 'sv', 'th', 'vi', 'tr', 'zh-Hans', 'zh-Hant', 'ar', 'fr-CA', 'pt-BR', 'es-MX', 'el', 'hu'];


