// Action types to be dispatched by the user
export const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
export const WEBSOCKET_SEND = 'WEBSOCKET:SEND';
// Action types dispatched by the WebSocket implementation
export const WEBSOCKET_CONNECTING = 'WEBSOCKET:CONNECTING';
export const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
export const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET:MESSAGE';
export const WEBSOCKET_ERROR = 'WEBSOCKET:ERROR';

export const WebsocketServiceType = {
  FLTR: 'FLTR',
  FLTR_REQUESTS: 'FLTR_REQUESTS',
};

export const WebsocketAction = {
  // FLTR REQUSTS service
  SAVE_FLTR_REQUESTS: {name: 'SAVE_FLTR_REQUESTS', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  GET_FLTR_REQUESTS: {name: 'GET_FLTR_REQUESTS', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  ADD_REQUEST: {name: 'ADD_REQUEST', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  UPDATE_FLTR_REQUEST: {name: 'UPDATE_FLTR_REQUEST', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  DELETE_REQUEST: {name: 'DELETE_REQUEST', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  REQUEST_IS_PROCESSED: {name: 'REQUEST_IS_PROCESSED', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  SAVE_FLTR_REQUEST: {name: 'SAVE_FLTR_REQUEST', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  GET_FLTR_REQUEST: {name: 'GET_FLTR_REQUEST', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  FLTR_ADMIN_LOGOUT: {name: 'FLTR_ADMIN_LOGOUT', serviceType: WebsocketServiceType.FLTR_REQUESTS},
  ADMIN_UPDATE: {name: 'ADMIN_UPDATE', serviceType: WebsocketServiceType.FLTR_REQUESTS},

  // FLTR service
  FLTR_FEED_PUBLISH: {name: 'FLTR_FEED_PUBLISH', serviceType: WebsocketServiceType.FLTR},
  IS_FLTR_FEED_PUBLISHING: {name: 'IS_FLTR_FEED_PUBLISHING', serviceType: WebsocketServiceType.FLTR},
  STATUS_FLTR_FEED_PUBLISH: {name: 'STATUS_FLTR_FEED_PUBLISH', serviceType: WebsocketServiceType.FLTR},
  GET_FLTR_FEED: {name: 'GET_FLTR_FEED', serviceType: WebsocketServiceType.FLTR},
  SAVE_FLTR_FEED: {name: 'SAVE_FLTR_FEED', serviceType: WebsocketServiceType.FLTR},
};

export const WebsocketMessageType = {
  SINGLE: 'SINGLE',
  ARRAY: 'ARRAY',
  FRAMES: 'FRAMES',
};
