import React from 'react';
import {Input} from '../../general/Input';
import {changeLocalization, getLocalizedValue} from '../../../utils/LocalizationUtils';

export const DescriptionElementsDetails = ({
  languages, setDescription, setLocalization, localization,
}) => {
  const renderDescription = (lang, langKey) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={langKey + 'input'}>
      <div className={'fltr-second-text'}>
                Description {lang.toUpperCase()}
      </div>
      <Input style={{marginTop: '7px', width: '414px'}}
        handleChange={(value) => {
          changeLocalization(value, 'description', localization, setDescription, setLocalization, lang);
        }}
        value={getLocalizedValue(localization, 'description', lang)}
      />
    </div>;
  };

  return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
    {Object.keys(languages).map((langKey) => {
      return renderDescription(langKey, languages[langKey]);
    })}
  </div>;
};
