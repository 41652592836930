import uuid from 'uuid/v4';
import {
  DELETE_GROUP,
  IS_LOADING_GROUPS,
  SAVE_GROUPS,
  SELECT_GROUP,
  UPDATE_GROUP,
  UPDATE_ORDERS_INDEXES_GROUPS,
} from '../actions/groups';
import {
  REMOVE_PACK_FROM_GROUPS,
  UPDATE_PACK_IN_GROUP,
  UPDATE_RELATION_PACK_TO_GROUP,
} from '../actions/packs';

const getInitialState = () => {
  return {
    stateId: uuid(),
    groups: [],
    isLoadingGroups: false,
    selectedGroup: {id: -1},
    timeStampSaveGroups: 0,
    timeStampAddGroup: 0,
  };
};

export default function groups(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_GROUPS:
      const timeStampSaveGroups = action.requestTimeEpoch;
      const stateIdRequestedSaveGroups = action.stateId;
      if (
        timeStampSaveGroups > state.timeStampSaveGroups &&
                stateIdRequestedSaveGroups === state.stateId
      ) {
        return Object.assign({}, state, {
          groups: action.groups,
          isLoadingGroups: false,
          timeStampSaveGroups: timeStampSaveGroups,
        });
      }
      return state;


    case SELECT_GROUP:
      return Object.assign({}, state, {
        selectedGroup: action.group,
      });

    case IS_LOADING_GROUPS:
      return Object.assign({}, state, {
        isLoadingGroups: action.isLoading,
      });

    case UPDATE_GROUP:
      return Object.assign({}, state, {
        groups: state.groups.map((group) => {
          if (group.id === action.group.id) {
            return action.group;
          }
          return group;
        }),
      });

    case UPDATE_PACK_IN_GROUP:
      return Object.assign({}, state, {
        groups: state.groups.map((group) => {
          const isContainGroup = action.pack.packToGroups.find((packToGroup) => {
            return packToGroup.groupId === group.id;
          });
          if (isContainGroup) {
            const isGroupContainPack = group.packToGroups.find((packToGroup) => {
              return packToGroup.packId === action.pack.id;
            });
            if (!isGroupContainPack) {
              const newGroup = {...group};
              newGroup.packToGroups.push({
                groupId: group.id,
                packId: action.pack.id,
                order: isContainGroup.order,
                uuid: isContainGroup.uuid,
              });
              return newGroup;
            }
          } else {
            const isGroupContainPack = group.packToGroups.find((packToGroup) => {
              return packToGroup.packId === action.pack.id;
            });
            if (isGroupContainPack) {
              const newGroup = {...group};
              newGroup.packToGroups = newGroup.packToGroups.filter((packToGroup) => {
                return packToGroup.packId !== action.pack.id;
              });
              return newGroup;
            }
          }

          return group;
        }),
      });

    case DELETE_GROUP:
      return Object.assign({}, state, {
        groups: state.groups.filter((group) => {
          return group.id !== action.groupId;
        }),
      });

    case REMOVE_PACK_FROM_GROUPS:
      return Object.assign({}, state, {
        groups: state.groups.map((group) => {
          const newGroup = {...group};
          newGroup.packToGroups = newGroup.packToGroups.filter((packToGroup) => {
            return packToGroup.packId !== action.packId;
          });

          return newGroup;
        }),
      });

    case UPDATE_ORDERS_INDEXES_GROUPS:
      const groupsWithNewOrderIndexes = state.groups.map((group) => {
        const orderIndex = action.orderIndexes.find((orderIndex) => (orderIndex.entityId === group.id));

        if (orderIndex) {
          const newGroup = {...group};
          newGroup.order = orderIndex.orderIndex;
          return newGroup;
        }

        return group;
      });

      return Object.assign({}, state, {
        groups: groupsWithNewOrderIndexes,
      });

    case UPDATE_RELATION_PACK_TO_GROUP:
      const groupsUpdatedRelation = state.groups
          .map((group) => {
            const newGroup = {...group};
            if (group.id === action.newRelation.toEntityId) {
              newGroup.packToGroups.push({
                uuid: action.newRelation.uuid,
                packId: action.newRelation.fromEntityId,
                groupId: group.id,
                order: action.newRelation.orderIndex,
              });
            }

            if (group.id === action.fromGroupId) {
              newGroup.packToGroups = newGroup.packToGroups.filter((packToGroup) => {
                return packToGroup.packId !== action.newRelation.fromEntityId;
              });
            }

            return newGroup;
          });

      return Object.assign({}, state, {
        groups: groupsUpdatedRelation,
      });

    default: return state;
  }
}
