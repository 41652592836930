import React from 'react';
import {REQUEST_STATUS} from '../../../actions/requests';

export const RequestStatusSelectBody = ({changeStatus, onClose, isDisableComplete}) => {
  return <div className={'component-settings-container'}>
    <div className={'component-setting'} onClick={() => {
      changeStatus(REQUEST_STATUS.NEW);
      onClose();
    }}>
      <div className={'component-setting-text'}>New</div>
    </div>
    <div className={'component-setting'} onClick={() => {
      changeStatus(REQUEST_STATUS.IN_PROGRESS);
      onClose();
    }}>
      <div className={'component-setting-text'}>In Progress</div>
    </div>
    <div className={'component-setting'} style={{opacity: isDisableComplete ? '0.5' : undefined}} onClick={() => {
      if (!isDisableComplete) {
        changeStatus(REQUEST_STATUS.DONE);
        onClose();
      }
    }}>
      <div className={'component-setting-text'}>Done</div>
    </div>
  </div>;
};

