import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = {isActive: this.props.isActive}
    }

    switch = debounce(200, () => this.props.switch(this.state.isActive))

  componentWillUpdate (nextProps, nextState, nextContext) {
    if(this.props.isActive !== nextProps.isActive) {
      this.setState({isActive: nextProps.isActive})
    }
  }

    handleChangeSwitch = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.switch());
    }

    render () {
      console.log(this.state.isActive)
        return (
            <div className={'checkbox'} style={this.props.style}>
                <input type="checkbox"
                       disabled={this.props.isDisabled}
                       defaultChecked={this.state.isActive}
                       checked={this.state.isActive}
                       onChange={this.handleChangeSwitch}
                />
                <label style={{color: this.state.isActive ? "#212529" : "#7F8489"}}>
                    {this.props.text}
                </label>
            </div>
        )
    }
}
