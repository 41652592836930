import React from 'react';
import RemoteConfigSection from './RemoteConfigSection';

const Settings = ({}) => {
  return (
    <div style={{height: '100%', overflow: 'overlay', marginBottom: '100px'}}>
      <div className={'settings-container'}>
        <RemoteConfigSection/>
      </div>
    </div>
  );
};

export default Settings;
