import React from 'react';
import {Checkbox} from '../general/Checkbox';

export const CheckboxPackDetails = ({
  isFree, setIsFree, isNew, setIsNew, setIsPremium, isPremium,
  setIsSetToday, setTodayDate, isSetToday, setIsVisible, isVisible,
}) => {
  return <div style={{display: 'flex'}}>
    <Checkbox
      key={'free-checkbox'}
      switch={setIsFree}
      isActive={isFree}
      text={'Free'}
    />

    <Checkbox
      key={'new-checkbox'}
      isActive={isNew}
      style={{marginLeft: '32px'}}
      switch={setIsNew}
      text={'New'}
    />

    <Checkbox
      key={'premium-checkbox'}
      style={{marginLeft: '32px'}}
      switch={setIsPremium}
      isActive={isPremium}
      text={'Premium'}
    />

    <Checkbox
      key={'today-checkbox'}
      style={{marginLeft: '32px'}}
      switch={(isSwitch) => {
        setIsSetToday(isSwitch);
        if (!isSwitch) {
          setTodayDate(null);
        }
      }}
      isActive={isSetToday}
      text={'Today'}
    />

    <Checkbox
      key={'visibility-checkbox'}
      style={{marginLeft: '32px'}}
      switch={setIsVisible}
      isActive={isVisible}
      text={'Visibility'}
    />
  </div>;
};
