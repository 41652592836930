import {IS_UPDATING_CONTENT, SET_IS_BLOCK_PUBLISH} from './general';
import {createRequest} from '../utils/HttpRequestUtills';
import {batch} from 'react-redux';

export const UPDATE_TAG = 'UPDATE_TAG';
export const REMOVE_TAG_FROM_PACKS = 'REMOVE_TAG_FROM_PACKS';
export const REMOVE_TAG_FROM_ELEMENTS = 'REMOVE_TAG_FROM_ELEMENTS';
export const DELETE_TAG = 'DELETE_TAG';
export const SAVE_TAGS = 'SAVE_TAGS';
export const ADD_TAG = 'ADD_TAG';
export const IS_LOADING_TAGS = 'IS_LOADING_TAGS';
export const SET_DELETE_TAG = 'SET_DELETE_TAG';
export const SET_EDIT_TAG= 'SET_EDIT_TAG';
export const MOVE_TAGS = 'MOVE_TAGS';
export const ORDER_TAGS = 'ORDER_TAGS';
export const SELECT_TAGS_CONTENT_TYPE = 'SELECT_TAGS_CONTENT_TYPE';

export const TAGS_CONTENT_TYPES = {
  ALL: {
    id: 'All',
    name: 'All',
  },
  PRESET: {
    id: 'Preset',
    name: 'Presets',
  },
  HIGHLIGHT: {
    id: 'Highlight',
    name: 'IG Highlights',
  },
  IG_ICON: {
    id: 'iOSIcon',
    name: 'iOS Icons',
  },
};

export const TAGS_ORDER_TYPES = {
  IS_VISIBLE: 'IS_VISIBLE',
  IS_IMAGE: 'IS_IMAGE',
  NAME: 'NAME',
  COUNT_ELEMENTS: 'COUNT_ELEMENTS',
};

export const addTag = (tag) => (dispatch, getState) => {
  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });

  dispatch({
    type: ADD_TAG,
    tag,
    elements: getState().elements.elements,
  });

  const onload = () => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
  };
  const xhr = createRequest(dispatch, 'POST', '/tag', onload);

  xhr.send(JSON.stringify(tag));
};

export const updateTag = (tag) => (dispatch) => {
  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });
  dispatch({type: UPDATE_TAG, tag: {...tag}});

  const onload = () => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
  };
  const xhr = createRequest(dispatch, 'PATCH', '/tag', onload);

  xhr.send(JSON.stringify(tag));
};

export const deleteTag = (tagId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: SET_IS_BLOCK_PUBLISH,
      isBlockPublish: false,
    });
    dispatch({type: REMOVE_TAG_FROM_ELEMENTS, tagId});
    dispatch({type: DELETE_TAG, tagId});
  });
  const onload = () => {};

  const xhr = createRequest(dispatch, 'DELETE', '/tag/' + tagId, onload);

  xhr.send();
};

export const setDeleteTag = (tag) => (dispatch) => {
  dispatch({
    type: SET_DELETE_TAG,
    tag,
  });
};

export const setEditTag = (tag) => (dispatch) => {
  dispatch({
    type: SET_EDIT_TAG,
    tag,
  });
};

export const moveTag = (
    orderIndexes,
) => (dispatch) => {
  batch(() => {
    dispatch({
      type: SET_IS_BLOCK_PUBLISH,
      isBlockPublish: false,
    });
    dispatch({
      type: MOVE_TAGS,
      orderIndexes,
    });
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/tags/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes}));
};

export const orderTags = (orderParams) => (dispatch, getState) => {
  dispatch({
    type: ORDER_TAGS,
    orderParams,
    elements: getState().elements.elements,
  });
};

export const selectTagsContentType = (tagsContentType) => (dispatch, getState) => {
  dispatch({
    type: SELECT_TAGS_CONTENT_TYPE,
    tagsContentType,
    elements: getState().elements.elements,
  });
};
