import React, {useEffect, useState} from 'react';
import DropdownWrapper from '../general/DropdownWrapper';
import {ReactComponent as ArrowDownIcon} from '../../resources/imgs/arrow-down-icon.svg';
import {RequestAdminSelectBody} from './RequestAdminSelectBody';
import {bindActionCreators} from 'redux';
import {
  changeSearchRequestsAdminId,
} from '../../actions/requests';
import {connect} from 'react-redux';

const RequestAdminSelect = ({selectedAdminId, changeSearchRequestsAdminId, admins}) => {
  const [adminId, setAdminId] = useState(selectedAdminId);
  useEffect(() => {
    setAdminId(selectedAdminId);
  }, [selectedAdminId]);

  const renderSelect = (adminName) => {
    const iconStyle = {
      paddingLeft: '3px',
    };

    return <div className={'fltr-status-select-admin'}>
      {adminName}
      <ArrowDownIcon style={iconStyle}/>
    </div>;
  };

  const selectedAdmin = !adminId || admins.length === 0 ? null :
      admins.find((admin) => admin.id === adminId);
  const adminName = selectedAdmin ? selectedAdmin.nickname : 'All';

  return <div>
    <DropdownWrapper style={{right: '-100%'}}>
      {renderSelect(adminName)}
      <RequestAdminSelectBody
        admins={admins}
        selectedAdminId={adminId}
        selectAdminId={changeSearchRequestsAdminId}
      />
    </DropdownWrapper>
  </div>;
};

const mapStateToProps = (state) => ({
  selectedAdminId: state.requests.searchParamsRequests.adminId,
  admins: state.appState.admins,
  isLoadingAdmins: state.appState.isLoadingAdmins,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchRequestsAdminId: bindActionCreators(changeSearchRequestsAdminId, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestAdminSelect);
