import DropdownWrapper from '../../general/DropdownWrapper';
import {ReactComponent as ArrowButton} from '../../../resources/imgs/arrow-button-icon.svg';
import MoveTo from '../../general/MoveTo';
import React from 'react';

export const AddToPack = ({element, joinPack, setJoinPack, setIsUpdateRelations, packs, style}) => {
  return <div style={{...{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '200px'}, ...style}}>
    <div className={'fltr-second-text'}>
            Pack
    </div>
    <DropdownWrapper style={{right: '31%', paddingTop: '10px'}}>
      <div className={'fltr-input-select-container'} style={{marginTop: '7px', resize: 'vertical'}}>
        <div>{joinPack ? joinPack.name : ''}</div>
        <ArrowButton/>
      </div>

      <MoveTo
        moveTo={(element, pack) => {
          setIsUpdateRelations(true);
          setJoinPack(pack);
        }}
        items={packs.filter((pack) => {
          return element.contentTypeId === pack.contentTypeId && !element.elementToPacks.find((elementToPack) => {
            return elementToPack.packId === pack.id;
          });
        })}
        propName={'name'}
        sourceItem={element}
        isClose={true}
        containerClassName={'component-settings-container'}
      />

    </DropdownWrapper>
  </div>;
};
