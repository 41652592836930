import React from 'react';
import {connect} from 'react-redux';
import {showModal, typeModal} from '../../actions/general';
import AddHeader from '../general/AddHeader';
import {bindActionCreators} from 'redux';
import {GroupsList} from './GroupsList';
import {updateGroupsOrderIndexes} from '../../actions/groups';

const GroupsSection = ({groups, isLoadingGroups, selectedLang, packsInGroups,
  updateGroupsOrderIndexes, selectedContentTypeId, isLoadingPacks,
}) => {
  const filteredGroups = groups.filter((group) => (group.contentTypeId === selectedContentTypeId))
      .sort((a, b) => (a.order - b.order));

  return (
    <div className={'section-container'} style={{marginTop: '30px'}}>
      <AddHeader
        title={`Section (${filteredGroups.length})`}
        modalType={typeModal.GROUP_ADD}
      />
      <div style={{overflow: 'auto'}}>
        <GroupsList
          isLoadingGroups={isLoadingGroups}
          isLoadingPacks={isLoadingPacks}
          selectedLang={selectedLang}
          selectedContentTypeId={selectedContentTypeId}
          updateGroupsOrderIndexes={updateGroupsOrderIndexes}
          packsInGroups={packsInGroups}
          groups={filteredGroups}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  isLoadingGroups: state.groups.isLoadingGroups,
  selectedLang: state.appState.selectedLang,
  selectedContentTypeId: state.appState.selectedContentTypeId,
  isLoadingPacks: state.packs.isLoadingPacks,
  packsInGroups: state.packs.packsInGroups,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  updateGroupsOrderIndexes: bindActionCreators(updateGroupsOrderIndexes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSection);
