import React from 'react';
import momentZ from 'moment-timezone';
import {durationToHM, timeIntervalRequestMS, timeLeft, timeLeftForAttentionRequest} from '../../utils/TimeUtils';
import {Status} from '../general/Status';
import {getStyle} from '../../utils/StyleUtils';
import {BrowserView, MobileView} from 'react-device-detect';

export const Request = ({request, isSelected, editRequest, processingInterval = timeIntervalRequestMS}) => {
  const msLeftTime = timeLeft(request.createdAt, processingInterval, request.closeDate ? momentZ(request.closeDate) : undefined);
  const browserViewStyle = {display: 'flex'};

  const isExpired = msLeftTime < timeLeftForAttentionRequest;

  return (
    <div className={getStyle('request')}
      style={{backgroundColor: isSelected ? '#fafafa' : isExpired ? 'rgba(241,56,56,0.08)' : undefined}}
      onClick={() => {
        editRequest(request);
      }}
    >
      <img className={getStyle('requests-list-img')}
        src={request.imagePreviewUrl ? request.imagePreviewUrl : request.imageUrl}
      />
      <div className={'request-list-value-date'}>
        {momentZ(request.createdAt).tz(momentZ.tz.guess()).format('D MMM HH:mm')}
      </div>
      <BrowserView style={browserViewStyle}>
        <div className={'request-list-value-contact'}>
          {request.contact}
        </div>
      </BrowserView>
      <BrowserView style={browserViewStyle}>
        <div className={'request-list-value-id'}>
          {request.id}
        </div>
      </BrowserView>
      <div className={'request-list-value-end-in'}
        style={{color: isExpired ? '#F13838' : undefined}}
      >
        {durationToHM(msLeftTime)}
      </div>
      <BrowserView style={browserViewStyle}>
        <div className={'request-list-value-agent'}>
          {request.adminName}
        </div>
      </BrowserView>
      <div className={'request-list-value-status'}>
        <Status
          status={request.status}
        />
        <MobileView>
          <div className={getStyle('request-list-value-agent')}>
            {request.adminName}
          </div>
        </MobileView>
      </div>
    </div>
  );
};

