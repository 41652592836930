import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeOrderByRequests} from '../../actions/requests';
import {ReactComponent as ArrowUpIcon} from '../../resources/imgs/arrow-up-icon.svg';
import {ReactComponent as ArrowDownIcon} from '../../resources/imgs/arrow-down-icon.svg';
import {BrowserView} from 'react-device-detect';
import {getStyle} from '../../utils/StyleUtils';

const RequestsTableHeader = ({searchParamsRequests, changeOrderByRequests}) => {
  const renderOrderArrows = (orderParam) => {
    return searchParamsRequests.orderBy !== orderParam ?
            <div className={'requests-list-header-arrow-container'}>
              <ArrowUpIcon/>
              <ArrowDownIcon/>
            </div> :
            searchParamsRequests.orderIndex === 'ASC' ? <ArrowUpIcon/> : <ArrowDownIcon/>;
  };

  const onClickOrderHeader = (param) => {
    if (searchParamsRequests.orderBy !== param) {
      changeOrderByRequests({
        orderBy: param,
        orderIndex: 'DESC',
      });
    } else {
      changeOrderByRequests({
        orderBy: param,
        orderIndex: searchParamsRequests.orderIndex === 'DESC' ? 'ASC' : 'DESC',
      });
    }
  };

  return (
    <div className={getStyle('requests-table-header')}>
      <div className={getStyle('request-list-header-img')}/>
      <div className={'request-list-header-date'}
        onClick={() => onClickOrderHeader('createdAt')}
      >
        <div style={{paddingRight: '5px'}}>Date</div>
        {renderOrderArrows('createdAt')}
      </div>

      <BrowserView>
        <div className={'request-list-header-contact'}
          onClick={() => onClickOrderHeader('contact')}
        >
          <div style={{paddingRight: '5px'}}>Contact</div>
          {renderOrderArrows('contact')}
        </div>
      </BrowserView>
      <BrowserView>
        <div className={'request-list-header-id'}
          onClick={() => onClickOrderHeader('id')}
        >
          <div style={{paddingRight: '5px'}}>Id</div>
          {renderOrderArrows('id')}
        </div>
      </BrowserView>
      <div className={'request-list-header-end-in'}
        onClick={() => onClickOrderHeader('endIn')}
      >
        <div style={{paddingRight: '5px'}}>End in</div>
        {renderOrderArrows('endIn')}
      </div>
      <BrowserView>
        <div className={'request-list-header-agent'}
          onClick={() => onClickOrderHeader('adminName')}
        >
          <div style={{paddingRight: '5px'}}>Agent</div>
          {renderOrderArrows('adminName')}
        </div>
      </BrowserView>
      <div className={'request-list-header-status'}
        onClick={() => onClickOrderHeader('status')}
      >
        <div style={{paddingRight: '5px'}}>Status</div>
        {renderOrderArrows('status')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchParamsRequests: state.requests.searchParamsRequests,
});

const mapDispatchToProps = (dispatch) => ({
  changeOrderByRequests: bindActionCreators(changeOrderByRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTableHeader);
