import React from 'react';
import {FileInput} from './FileInput';

export const ImagesInput = ({style, handleFilesDrop, title, isLoading}) => {
  const fileInputStyle = {marginTop: '7px', width: '100%'};

  return <div style={style}>
    <div className={'fltr-second-text'}>
      {title}
    </div>
    <FileInput style={fileInputStyle}
      handleChange={(files) => {
        const filteredFiles = files.filter((file) => file.type.startsWith('image/'));
        handleFilesDrop(filteredFiles);
      }
      }
      isLoading={isLoading}
      placeholder={'Upload file'}
      value={''}
      isMultiFiles={true}
    />
  </div>;
};
