import momentZ from 'moment-timezone';

import {WebsocketAction} from './websockets/types';
import {SAVE_TAGS} from '../actions/tags';
import {SAVE_GROUPS} from '../actions/groups';
import {SAVE_ELEMENTS} from '../actions/elements';
import {SAVE_PACKS} from '../actions/packs';
import {SAVE_CONTENT_TYPES, SAVE_FEED, SAVE_VERSION} from '../actions/general';

export const fltrMidelware = (store) => (next) => (action) => {
  if (action !== undefined && action.type !== undefined) {
    switch (action.type) {
      // User request to connect
      case WebsocketAction.SAVE_FLTR_FEED.name: {
        const timeStampRequest = momentZ().format('x');

        const message = action.data.message;

        const feed = message.feed;
        const stateIdS = message.stateId.split('_');
        const groups = feed.groups ? feed.groups : [];
        const groupsFiltered = groups.filter((group) => {
          return [1, 2, 3].indexOf(group.id) === -1;
        });

        store.dispatch({
          type: SAVE_FEED,
          feed: feed,
        });

        store.dispatch({
          type: SAVE_CONTENT_TYPES,
          contentTypes: feed.contentTypes ? feed.contentTypes : [],
        });

        store.dispatch({
          type: SAVE_VERSION,
          version: feed.version,
        });

        store.dispatch({
          type: SAVE_PACKS,
          stateId: stateIdS[0],
          requestTimeEpoch: timeStampRequest,
          groups,
          packs: feed.packs ? feed.packs : [],
        });

        store.dispatch({
          type: SAVE_ELEMENTS,
          stateId: stateIdS[1],
          requestTimeEpoch: timeStampRequest,
          packs: feed.packs ? feed.packs : [],
          elements: feed.elements ? feed.elements : [],
        });

        store.dispatch({
          type: SAVE_GROUPS,
          stateId: stateIdS[2],
          requestTimeEpoch: timeStampRequest,
          groups: groupsFiltered,
        });

        store.dispatch({
          type: SAVE_TAGS,
          stateId: stateIdS[3],
          requestTimeEpoch: timeStampRequest,
          elements: feed.elements ? feed.elements : [],
          tags: feed.tags ? feed.tags : [],
        });
        break
      }

      default:
        break;
    }
    return next(action);
  }
};
