import momentZ from 'moment-timezone';

export const toUserTimeZoneDate = (date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mo1 = momentZ(date);
  const mo2 = momentZ(date).tz(timeZone);

  const modifiedDate = momentZ(date);
  modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

  return modifiedDate;
};

export const timeLeft = (date, timeInterval, formDate = momentZ()) => {
  const startDate = momentZ(date);
  const diff = formDate.diff(startDate);
  const leftMS = timeInterval - diff;
  return leftMS > 0 ? leftMS : 0;
};

export const durationToHM = (durationMS) => {
  const duration = momentZ.duration(durationMS, 'milliseconds');
  const minutes = Math.floor(duration.asMinutes());
  const hoursLeft = Math.floor(minutes / 60);
  const minutesLeft = minutes - (hoursLeft * 60);

  return hoursLeft + 'h ' + minutesLeft + 'm';
};

export const timeIntervalRequestMS = 86400000;
export const timeLeftForAttentionRequest = 7200000;

export const getTimestampUTCms = () => {
  return (new Date()).getTime();
};
