export const removeDuplicates = (arr, key) => {
  const flag = {};
  const unique = [];

  arr.forEach((elem) => {
    if (!flag[elem[key]]) {
      flag[elem[key]] = true;
      unique.push(elem);
    }
  });

  return unique;
};

export function compareString(propName) {
  return (a, b) => {
    if ( a[propName] < b[propName] ) {
      return -1;
    }
    if ( a[propName] > b[propName] ) {
      return 1;
    }
    return 0;
  };
}

export const arrayToObject = (array, value) => {
  return array.reduce(function(acc, cur, i) {
    acc[cur] = value;
    return acc;
  }, {});
};


export function intersectArrays(a, b) {
  let t;
  if (b.length > a.length) {
    t = b; b = a; a = t;
  }
  return a.filter(function(e) {
    return b.indexOf(e) > -1;
  });
}
