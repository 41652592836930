import React, {useEffect, useState} from 'react';
import {Input} from '../../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addElement, updateElement} from '../../../actions/elements';
import {changeLocalization, getLocalizedValue} from '../../../utils/LocalizationUtils';
import {Checkbox} from '../../general/Checkbox';
import {prepareFileToUpload} from '../../../utils/FilesUtils';
import uuid from 'uuid/v4';
import {AddToPack} from './AddToPack';
import {TodayInput} from './TodayInput';
import {DetailsFooter} from '../../general/DetailsFooter';
import {EditVideo} from '../../general/EditVideo';
import {MetaType, showModal, typeModal} from '../../../actions/general';
import {CheckboxElementsDetails} from './CheckboxElementsDetails';
import {DescriptionElementsDetails} from './DescriptionElementsDetails';

const IGEffectsDetails = ({element, mode, cancel, deleteElement,
  addElement, updateElement, languages, packs, meta, showModal
}) => {
  const [isSetToday, setIsSetToday] = useState(element.todayDate);

  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [isActive, setIsActive] = useState(element.isActive);
  const [isNew, setIsNew] = useState(element.isNew);
  const [isPremium, setIsPremium] = useState(element.isPremium);
  const [isFree, setIsFree] = useState(element.isFree);
  const [name, setName] = useState(element.name);
  const [video, setVideo] = useState(null);
  const [todayDate, setTodayDate] = useState(element.todayDate);
  const [videoUrl, setVideoUrl] = useState(element.videoUrl);
  const [elementUrl, setElementUrl] = useState(element.elementUrl);
  const [description, setDescription] = useState(element.description);
  const [localization, setLocalization] = useState(element.localization);

  const [joinPack, setJoinPack] = useState(null);

  useEffect(() => {
    if (meta) {
      if (meta.type && meta.type === MetaType.WithPack) {
        const pack = packs.find((pack) => pack.id === meta.packId);
        if (pack) {
          setJoinPack(pack);
        }
      }
    }
  }, [meta]);

  const handleFileDrop = (file, action, actionUrl, isCompress = true) => {
    if (file.size < 3457152) {
      setIsForceUpdate(true);
      prepareFileToUpload(file, action, actionUrl, isCompress, 3457152, false);
    }
  };

  const handleSave = () => {
    const elementForSave = {...element};
    elementForSave.isActive = isActive;
    elementForSave.isFree = isFree;
    elementForSave.isPremium = isPremium;
    elementForSave.isNew = isNew;
    elementForSave.name = name;
    elementForSave.description = description;
    elementForSave.isForceUpdate = isForceUpdate;
    elementForSave.video = video;
    elementForSave.todayDate = todayDate;
    elementForSave.videoUrl = videoUrl;
    elementForSave.elementUrl = elementUrl;
    elementForSave.localization = localization;
    elementForSave.isUpdateRelations = isUpdateRelations;
    elementForSave.elementToPacks = mode === 'EDIT' ? elementForSave.elementToPacks : [];
    elementForSave.order = 0;
    elementForSave.sku = elementForSave.sku ? elementForSave.sku : uuid();
    if (joinPack) {
      elementForSave.order = joinPack.elementToPacks.length + 1;
      elementForSave.elementToPacks.push({
        packId: joinPack.id,
        uuid: uuid(),
        order: joinPack.elementToPacks.length + 1,
      });
    }
        mode === 'EDIT' ? updateElement(elementForSave) : addElement(elementForSave);
        cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'}>
      <div className={'fltr-head-text'}>
        {(mode === 'EDIT' ? 'Edit ' : 'Add ') + element.contentTypeId}
      </div>

      {
        element.id ?       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <div className={'fltr-second-text'}>
            {element.id ? 'ID: ' + element.id : ''}
          </div>
          <div className={'fltr-second-btn'}
               onClick={() => {
                 if(element.id) {
                   showModal(typeModal.CREATE_PERSONAL_NOTIFICATION, true, {entityId: element.id})
                 }
               }}
          >
            Send notification
          </div>
        </div> : null
      }
    </div>;
  };

  const renderBody = () => {
    return <div className={'element-details-body'}>
      <CheckboxElementsDetails
        isFree={isFree}
        setIsFree={setIsFree}
        isNew={isNew}
        setIsNew={setIsNew}
        setIsPremium={setIsPremium}
        isPremium={isPremium}
        setIsSetToday={setIsSetToday}
        setTodayDate={setTodayDate}
        isSetToday={isSetToday}
        setIsActive={setIsActive}
        isActive={isActive}
      />

      {
                isSetToday ?
                    <TodayInput
                      style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}
                      setTodayDate={setTodayDate}
                      todayDate={todayDate}
                    /> : null
      }

      <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
        <div className={'fltr-second-text'}>
                    Video
        </div>
        <EditVideo videoUrl={videoUrl}
          style={{marginTop: '5px', height: '164px', width: '124px'}}
          heightVideo={'164px'}
          widthVideo={'124px'}
          onDrop={(file) => handleFileDrop(file, setVideo, setVideoUrl, false)}
          clear={() => {
            setVideo(null);
            setVideoUrl(null);
            setIsForceUpdate(false);
          }}
        />
      </div>

      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '184px'}}>
        <div className={'fltr-second-text'}>
                    Name
        </div>
        <Input style={{marginTop: '7px', width: '100%'}}
          handleChange={(value) => {
            changeLocalization(value, 'name', localization, setName, setLocalization);
          }}
          value={getLocalizedValue(localization, 'name')}
        />
      </div>

      <div style={{'display': 'flex', 'alignItems': 'center'}}>
        <AddToPack
          element={element}
          joinPack={joinPack}
          setJoinPack={setJoinPack}
          setIsUpdateRelations={setIsUpdateRelations}
          packs={packs}
        />

        <div style={{display: 'flex', marginTop: '13px',
          flexDirection: 'column', width: '184px', marginLeft: '13px'}}
        >
          <div className={'fltr-second-text'}>
                        Link
          </div>
          <Input style={{marginTop: '7px', width: '100%'}}
            handleChange={setElementUrl}
            value={elementUrl}
          />
        </div>
      </div>

      <DescriptionElementsDetails
        languages={languages}
        setDescription={setDescription}
        setLocalization={setLocalization}
        localization={localization}
      />
    </div>;
  };

  return <div className="element-details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteElement}
      cancel={cancel}
      entity={element}
      handleSave={handleSave}
      isDisabled={!videoUrl || !elementUrl || name === ''}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  languages: state.appState.languages,
  packs: state.packs.packs,
});

const mapDispatchToProps = (dispatch) => ({
  addElement: bindActionCreators(addElement, dispatch),
  updateElement: bindActionCreators(updateElement, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IGEffectsDetails);
