import React, {useState} from 'react';
import {getLocalizedValue} from '../../utils/LocalizationUtils';
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import PacksList, {PacksListType} from '../packs/PacksList';
import DropdownWrapper from '../general/DropdownWrapper';
import ComponentSettings from '../general/ComponentSettings';
import {ComponentType} from '../../actions/general';
import {Draggable} from 'react-beautiful-dnd';


export const Group = ({group, packs, selectedLang,
  style, isDraggingAny, draggableId, arrayIndex,
}) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const [isSeeAll, setSeAll] = useState(false);

  const isDragging = false;

  const renderHeader = () => {
    return <div className={'group-header'}
      onMouseLeave={() => setMouseOver(false)}
      onMouseEnter={() => setMouseOver(true)}
    >
      <div className={'drag-dots'}
        style={{opacity: (isMouseOver ? 1 : 0)}}/>
      <div className={'group-header-name'}>
        {getLocalizedValue(group.localization, 'name') + ` (${packs.length})`}
      </div>
      {renderButtons()}
    </div>;
  };
  const renderFooter = () => {
    if (packs.length < 4) {
      return null;
    }
    return <div className={'group-footer'} onClick={() => {
      setSeAll(!isSeeAll);
    }}>
      <div className={'group-footer-text'}>
        {isSeeAll ? 'COLLAPSE' : 'SEE ALL'}
      </div>
    </div>;
  };

  const renderButtons = () => {
    return <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
      <DropdownWrapper style={{right: '-100%'}}>
        <DotsHorizontal
          style={{opacity: (isMouseOver ? 1 : 0)}}
        />
        <ComponentSettings componentType={ComponentType.GROUP} componentTypeIn={null} item={group}/>
      </DropdownWrapper>
    </div>;
  };

  return (
    <Draggable
      key={group.id}
      draggableId={draggableId}
      index={arrayIndex}
    >
      {(provided, snapshot) => (
        <div className={'fltr-items-container-none-height'}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{...provided.draggableProps.style, ...{...style, ...{height: isDragging ? '30px': undefined}}}}
        >
          {renderHeader()}
          {isDraggingAny ? null : <PacksList
            packs={isSeeAll ? packs : packs.slice(0, 3)}
            selectedLang={selectedLang}
            externalId={group.id}
            groupId={group.id}
            packsListType={PacksListType.PACKS_IN_GROUP}
          />}
          {renderFooter()}
        </div>
      )}
    </Draggable>
  );
};

