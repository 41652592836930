import React from 'react';
import {
  changeSearchRequestsStatus,
} from '../../actions/requests';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getStyle} from '../../utils/StyleUtils';

const RequestsTabs = ({searchParamsRequests, changeSearchRequestsStatus, countRequests, isLoadingRequests}) => {
  return (
    <div className={getStyle('content-types-container')}>
      {['ALL'].concat(['NEW', 'IN_PROGRESS', 'DONE']).map((contentType, index) => {
        const isSelected = searchParamsRequests.status === contentType || (!searchParamsRequests.status && contentType === 'ALL');
        return <button
          key={contentType}
          className={'content-type-' +
            (isSelected ? 'selected' : 'unselected')
          }
          onClick={() => {
            changeSearchRequestsStatus(contentType);
          }}
          style={{marginLeft: index === 0 ? '0px' : '12px'}}
        >
          {contentType + (isSelected && countRequests ? ' (' + countRequests + ')' : '') }
        </button>;
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchParamsRequests: state.requests.searchParamsRequests,
  isLoadingRequests: state.requests.isLoadingRequests,
  countRequests: state.requests.countRequests,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchRequestsStatus: bindActionCreators(changeSearchRequestsStatus, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTabs);
