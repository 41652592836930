import React from 'react';
import LibraryHeader from './LibraryHeader';
import GroupsSection from '../groups/GroupsSetion';
import PacksSection from '../packs/PacksSection';
import ElementsSection from '../elements/ElementsSection';
import LibraryTagsSection from '../tags/LibraryTagsSection';

const Library = ({useRegisterDroppable}) => {
  return (
    <div className={'library-container'}>
      <LibraryHeader/>
      <div className={'library-body'}>
        <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
          <LibraryTagsSection/>
          <GroupsSection/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
          <PacksSection useRegisterDroppable={useRegisterDroppable}/>
          <ElementsSection/>
        </div>
      </div>
    </div>
  );
};

export default Library;
