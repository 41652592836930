import {toBase64} from './LocalStoargeUtill';
import Compressor from 'compressorjs';
import {configs} from '../resources/configs';
import {getAccessToken} from './AuthUtill';
import {createRequest} from './HttpRequestUtills';
import {saveAs} from 'file-saver';

export const compressImage = (imageFile, compressToBytesSize, callback) => {
  if (imageFile.size < compressToBytesSize || !imageFile.type.startsWith('image/') || imageFile.type === 'image/png') {
    callback(imageFile);
    return;
  }

  new Compressor(imageFile, {
    quality: 0.9,
    checkOrientation: true,
    success(result) {
      callback(result);
    },
    error(err) {
      console.log(err.message);
    },
  });
};

export const prepareFileToUpload = (file, action, actionUrl, setIsLoading, tempName, isUploadToS3 = true, compressToBytesSize = 4057152, isCompress = true) => {
  if (file) {
    if (!isUploadToS3) {
      toBase64(file, (fileBase64) => {
        action(fileBase64);
        actionUrl(URL.createObjectURL(file));
      });
    } else {
      setIsLoading(true);
      console.log('File size ' + file.size);
      if (file.type !== 'image/x-adobe-dng' && file.type !== 'image/tiff' && isCompress) {
        compressImage(file, compressToBytesSize, (fileCompressed) => {
          console.log('FileCompress size ' + fileCompressed.size);

          uploadFileToS3(fileCompressed, tempName, (pathTemp) => {
            action(pathTemp);
            setIsLoading(false);
            actionUrl(URL.createObjectURL(file));
          });
        });
      } else {
        uploadFileToS3(file, tempName, (pathTemp) => {
          action(pathTemp);
          setIsLoading(false);
          actionUrl(URL.createObjectURL(file));
        });
      }
    }
  }
};

export const uploadFileToS3 = (file, name, callback) => {
  const extension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
  let fileName = name + '.' + extension;
  // upload to s3 tif only
  fileName = fileName.replace('.dng', '.tif').replace('.DNG', '.tif');
  const xhr = new XMLHttpRequest();
  xhr.open('POST', configs.apiUrl + '/presigned-data/create', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const onload = (responseText) => {
    const presignedPostData = JSON.parse(responseText);
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function(e) {
      if (xhr.status >= 200 && xhr.status < 400) {
        callback(presignedPostData.fields.key);
      }
    };
  };

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        onload(xhr.responseText);
      }
    }
  };

  xhr.send(JSON.stringify({bucketType: 'RES_FLTR', path: 'temp', name: fileName}));
};

export const dngToJpgUrl = (dngFileUrl, dngFileTempUrl, callback) => {
  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    callback(response);
  };
  const xhr = createRequest(null, 'POST', '/dng/to/jpg', onload);

  if (dngFileUrl && !dngFileUrl.includes('blob:')) {
    const requestBody = {dngUrl: dngFileUrl};
    xhr.send(JSON.stringify(requestBody));
  } else if (dngFileTempUrl && dngFileTempUrl !== '') {
    const requestBody = {dngTempPath: dngFileTempUrl};
    xhr.send(JSON.stringify(requestBody));
  }
};

export const getFilenameFromUrl = (url) => {
  if (url) {
    const m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1] + '.' + url.replace(m[0], '');
    }
  }
  return '';
};

export const getFilenameWithoutType = (filename) => {
  if (filename) {
    const type = filename.split('.').pop();
    if (!type) {
      return filename;
    }

    return filename.replace('.' + type, '');
  }
  return '';
};

export const ab2str = (buf) => {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
};

export const webpIsSupported = (blob, callback) => {
  if (!window.createImageBitmap) {
    callback(false, blob);
    return;
  }

  createImageBitmap(blob).then(() => {
    callback(true, blob);
  }, (err) => {
    console.log(err);
    callback(false, blob);
  });
};


(function createImageBitmapIIFE(global) {
  function isCanvasImageSource(el) {
    const validElements = [
      'img',
      'video',
      'canvas',
    ];

    return (el && el.tagName && validElements.includes(el.tagName.toLowerCase()));
  }

  function idealSize(currentValue, newValue, numerator, denominator) {
    if (typeof newValue === 'number') return newValue;
    if (typeof numerator !== 'number' || typeof denominator !== 'number') return currentValue;

    return (numerator / denominator) * currentValue;
  }

  if (!('createImageBitmap' in global)) {
    global.createImageBitmap = async function polyfillCreateImageBitmap(data, opts) {
      return new Promise((resolve, reject) => {
        opts = opts || {};

        let dataURL;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (data instanceof Blob) {
          dataURL = URL.createObjectURL(data);
        } else if (isCanvasImageSource(data)) {
          const width = data.naturalWidth || data.videoWidth || data.clientWidth || data.width;
          const height = data.naturalHeight || data.videoHeight || data.clientHeight || data.height;
          canvas.width = idealSize(width, opts.resizeWidth, opts.resizeHeight, height);
          canvas.height = idealSize(height, opts.resizeHeight, opts.resizeWidth, width);

          ctx.drawImage(data, 0, 0, canvas.width, canvas.height);

          dataURL = canvas.toDataURL();
        } else if (data instanceof ImageData) {
          canvas.width = idealSize(data.width, opts.resizeWidth, opts.resizeHeight, data.height); ;
          canvas.height = idealSize(data.height, opts.resizeHeight, opts.resizeWidth, data.width);

          ctx.putImageData(data, 0, 0);

          dataURL = canvas.toDataURL();
        } else {
          throw new Error('createImageBitmap does not handle the provided image source type');
        }

        const img = new Image();
        img.onload = () => resolve(img);
        img.src = dataURL;
      });
    };
  }
})(window);

export const saveFile = (fileUrl) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', fileUrl, true);
  if (!configs.isLocal) {
    xhr.withCredentials = true;
  }
  xhr.responseType = 'blob';
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        saveAs(window.URL.createObjectURL(xhr.response), getFilenameFromUrl(fileUrl));
      }
    }
  };

  xhr.send();
};
