/* eslint-env browser */
import debounce from 'throttle-debounce/debounce'
import {SHOW_MODAL, typeModal} from '../actions/general';
import Modals from '../components/general/Modals';

export const INTERNET_CONNECTION = 'INTERNET_CONNECTION';

const createMiddleware = () => {

  const initialize = ({dispatch}) => {
    console.log('initialize connection')
    const onlineListener = debounce(500, () => {
      dispatch({type: SHOW_MODAL, modalType: typeModal.INTERNET_DISCONNECTED, isShow: false})
      dispatch({type: INTERNET_CONNECTION, isActive: true});
    });
    const offlineListener = debounce(500, () => {
      dispatch({type: SHOW_MODAL, modalType: typeModal.INTERNET_DISCONNECTED, isShow: true})
      dispatch({type: INTERNET_CONNECTION, isActive: false});
    });

    window.addEventListener("online", () => {
      console.log('online');
      onlineListener();
    })
    window.addEventListener("offline", () => {
      console.log('offline');
      offlineListener();
    })
  };

  let isInitialized = false;
  return (store) => (next) => (action) => {
    if(!isInitialized) {
      isInitialized = true;
      initialize(store);
    }
    return next(action);
  };
};

export default createMiddleware();
