import React from 'react';

export const TagsPack = ({allTags, elementIds}) => {
  return <div className={'tags-input-container'}>
    {
      allTags.filter((tag) => {
        return tag.elementsIds.find((elementId) => {
          return elementIds.indexOf(elementId) !== -1 && tag.isActive;
        });
      }).sort((tagA, tagB) => {
        return tagB.elementsIds.length - tagA.elementsIds.length;
      }).map((tag) => {
        return <div className={'tag-badge'}
          key={tag.id}
        >
          {`${tag.name}`}
        </div>;
      })
    }
  </div>;
};
