import React, { Component } from 'react'
import {getStyle} from '../../utils/StyleUtils';
import {Input} from './Input';


export class CreateNotificationWindow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceId: ''
        }
    }

    renderBody = () => {

        return <div className={"attention-body"}>
            <div className={"fltr-attention-text"} style={{marginTop: "12px", fontSize: '20px'}}>
                Input device id
            </div>
            <Input style={{marginTop: '20px', width: '75%'}}
                   handleChange={(value) => {
                       this.setState({deviceId: value})
                   }}
                   value={this.state.deviceId}
            />
        </div>
    }

    renderFooter = () => {

        return <div className={"attention-footer"}>
            <button className={"fltr-second-btn"} onClick={()=>{this.props.cancel()}}>
                Cancel
            </button>

            <button className={"save-btn"} style={{
                marginLeft: "25px",
            }}
                    onClick={() => {
                        this.props.onSend(this.state.deviceId, this.props.meta.entityId);
                        this.props.cancel();
                    }}
            >
                Send
            </button>
        </div>
    }

    render () {
        return (
            <div className={getStyle("attention-container")}>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }
}
