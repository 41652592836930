import {FileInput} from './FileInput';
import React from 'react';
import {isMobileOnly} from 'react-device-detect';
import {saveFile} from '../../utils/FilesUtils';
import MDSpinner from 'react-md-spinner';
import {getStyle} from '../../utils/StyleUtils';

export const LutInput = ({style, handleFileDrop, setFilterUrl, setFilterFileUrl, setIsLoading,
  filterUrl, tempName, isLoading,
}) => {
  const renderImg = () => {
    if (!filterUrl) {
      return null;
    }
    return <img className={getStyle('dng-img')} style={{marginRight: '7px'}}
      onClick={() => {
        saveFile(filterUrl);
      } }
      src={filterUrl}
    />;
  };

  if (isLoading) {
    return <div className={getStyle('dng-input-container')} style={style}>
      <div className={'fltr-second-text'} style={{fontSize: isMobileOnly ? '9px' : undefined}}>
        LUT
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: isMobileOnly ? '2px' : '7px', justifyContent: 'center'}}>
        <MDSpinner size={20} singleColor={'#00dada'}/>
      </div>
    </div>;
  }

  return <div className={getStyle('dng-input-container')} style={style}>
    <div className={'fltr-second-text'} style={{fontSize: isMobileOnly ? '9px' : undefined}}>
            LUT
    </div>
    <div style={{display: 'flex', alignItems: 'center', marginTop: isMobileOnly ? '2px' : '7px'}}>
      {renderImg()}
      <FileInput style={{width: '100%'}}
        handleChange={(file) => {
          if (file.type === 'image/png') {
            handleFileDrop(
                file,
                setFilterFileUrl,
                setFilterUrl,
                setIsLoading,
                tempName,
                true,
            );
          }
        }}
        placeholder={'Upload file'}
        value={filterUrl}
      />
    </div>
  </div>;
};
