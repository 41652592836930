import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Badge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive
        }
    }

    onChange = debounce(200, () => this.props.onChange(this.state.isActive))

    handleChange = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.onChange());
    }

    render () {
        return (
            <div className={'fltr-badge' + (this.state.isActive ? "-active" : "")}
                 onClick={this.handleChange}
                 style={this.props.style}
            >
                <div className={"fltr-badge-text" + (this.state.isActive ? "-active" : "")}>
                    {this.props.value}
                </div>
            </div>
        )
    }
}