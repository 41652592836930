import PresetDetails from './PresetDetails';
import React from 'react';
import AppDetails from './AppDetails';
import HighlightDetails from './HighlightDetails';
import IGEffectsDetails from './IGEffectsDetails';
import IOSIconDetails from './IOSIconDetails';

export const ElementDetails = ({mode, element, cancel, deleteElement, elementsCount, meta}) => {
  const contentType = meta && meta.packContentType ? meta.packContentType : element.contentTypeId;
  element.contentTypeId = contentType;
  if (contentType === 'Preset') {
    return <PresetDetails
      mode={mode}
      element={element}
      cancel={cancel}
      deleteElement={deleteElement}
      elementsCount={elementsCount}
      meta={meta}
    />;
  }

  if (contentType === 'App') {
    return <AppDetails
      mode={mode}
      element={element}
      cancel={cancel}
      deleteElement={deleteElement}
      elementsCount={elementsCount}
      meta={meta}
    />;
  }

  if (contentType === 'Highlight') {
    return <HighlightDetails
      mode={mode}
      element={element}
      cancel={cancel}
      deleteElement={deleteElement}
      elementsCount={elementsCount}
      meta={meta}
    />;
  }

  if (contentType === 'Effect') {
    return <IGEffectsDetails
      mode={mode}
      element={element}
      cancel={cancel}
      deleteElement={deleteElement}
      elementsCount={elementsCount}
      meta={meta}
    />;
  }

  if (contentType === 'iOSIcon') {
    return <IOSIconDetails
      mode={mode}
      element={element}
      cancel={cancel}
      deleteElement={deleteElement}
      elementsCount={elementsCount}
      meta={meta}
    />;
  }

  return <PresetDetails
    mode={mode}
    element={element}
    cancel={cancel}
    deleteElement={deleteElement}
    elementsCount={elementsCount}
    meta={meta}
  />;
};
