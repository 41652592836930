import React, {useEffect, useState} from 'react';
import {Input} from '../../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addElement, updateElement} from '../../../actions/elements';
import {changeLocalization, getLocalizedValue} from '../../../utils/LocalizationUtils';
import {prepareFileToUpload} from '../../../utils/FilesUtils';
import uuid from 'uuid/v4';
import {AddToPack} from './AddToPack';
import {TodayInput} from './TodayInput';
import {DetailsFooter} from '../../general/DetailsFooter';
import {EditImg} from '../../general/EditImg';
import {MetaType, showModal, typeModal} from '../../../actions/general';
import {CheckboxElementsDetails} from './CheckboxElementsDetails';
import {TagsInput} from '../../general/TagsInput';

const IOSIconDetails = ({element, mode, cancel, deleteElement,
  addElement, updateElement, languages, packs, meta, tags, showModal,
}) => {
  const [isSetToday, setIsSetToday] = useState(element.todayDate);
  const [tempName] = useState(uuid());

  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [isActive, setIsActive] = useState(element.isActive);
  const [isNew, setIsNew] = useState(element.isNew);
  const [isPremium, setIsPremium] = useState(element.isPremium);
  const [isFree, setIsFree] = useState(element.isFree);
  const [name, setName] = useState(element.name);
  const [imageTempPath, setImageTempPath] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [todayDate, setTodayDate] = useState(element.todayDate);
  const [imageUrl, setImageUrl] = useState(element.imageUrl);
  const [description, setDescription] = useState(element.description);
  const [localization, setLocalization] = useState(element.localization);
  const [tagIds, setTagIds] = useState(element.fltrTagIds);

  const [joinPack, setJoinPack] = useState(null);

  useEffect(() => {
    if (meta) {
      if (meta.type && meta.type === MetaType.WithPack) {
        const pack = packs.find((pack) => pack.id === meta.packId);
        if (pack) {
          setJoinPack(pack);
        }
      }
    }
  }, [meta]);

  const handleFileDrop = (file, action, actionUrl, setIsLoading, tempName, isUploadToS3 = true) => {
    setIsForceUpdate(true);
    prepareFileToUpload(file, action, actionUrl, setIsLoading, tempName, isUploadToS3, 4000000);
  };

  const handleSave = () => {
    const elementForSave = {...element};
    elementForSave.isActive = isActive;
    elementForSave.isFree = isFree;
    elementForSave.isPremium = isPremium;
    elementForSave.isNew = isNew;
    elementForSave.name = name;
    elementForSave.description = description;
    elementForSave.isForceUpdate = isForceUpdate;
    elementForSave.imageTempPath = imageTempPath;
    elementForSave.todayDate = todayDate;
    elementForSave.imageUrl = imageUrl;
    elementForSave.previewUrl = imageUrl;
    elementForSave.localization = localization;
    elementForSave.isUpdateRelations = isUpdateRelations;
    elementForSave.fltrTagIds = tagIds;
    elementForSave.elementToPacks = mode === 'EDIT' ? elementForSave.elementToPacks : [];
    elementForSave.order = 0;
    elementForSave.sku = elementForSave.sku ? elementForSave.sku : uuid();
    if (joinPack) {
      elementForSave.order = joinPack.elementToPacks.length + 1;
      elementForSave.elementToPacks.push({
        packId: joinPack.id,
        uuid: uuid(),
        order: joinPack.elementToPacks.length + 1,
      });
    }
        mode === 'EDIT' ? updateElement(elementForSave) : addElement(elementForSave);
        cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'}>
      <div className={'fltr-head-text'}>
        {(mode === 'EDIT' ? 'Edit ' : 'Add ') + element.contentTypeId}
      </div>

      {
        element.id ?       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <div className={'fltr-second-text'}>
            {element.id ? 'ID: ' + element.id : ''}
          </div>
          <div className={'fltr-second-btn'}
               onClick={() => {
                 if(element.id) {
                   showModal(typeModal.CREATE_PERSONAL_NOTIFICATION, true, {entityId: element.id})
                 }
               }}
          >
            Send notification
          </div>
        </div> : null
      }
    </div>;
  };

  const renderTags = () => {
    return <div style={{
      paddingTop: '17px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'fltr-second-text'}>
        Tags
      </div>
      <TagsInput
        tagIds={tagIds}
        allTags={tags}
        addTag={ (tag) => {
          setIsUpdateRelations(true);
          const newTagIds = [...tagIds].filter( (tagId) => tag.id !== tagId);
          newTagIds.push(tag.id);
          setTagIds(newTagIds);
        }}
        removeTag={(removeTagId) => {
          const newTagIds = [...tagIds].filter( (tagId) => removeTagId !== tagId);
          setIsUpdateRelations(true);
          setTagIds(newTagIds);
        }}
      />
      <div className={'fltr-second-text'} style={{
        paddingTop: '5px',
      }}>
        {description}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'element-details-body'}>
      <CheckboxElementsDetails
        isFree={isFree}
        setIsFree={setIsFree}
        isNew={isNew}
        setIsNew={setIsNew}
        setIsPremium={setIsPremium}
        isPremium={isPremium}
        setIsSetToday={setIsSetToday}
        setTodayDate={setTodayDate}
        isSetToday={isSetToday}
        setIsActive={setIsActive}
        isActive={isActive}
      />

      {
                isSetToday ?
                    <TodayInput
                      style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}
                      setTodayDate={setTodayDate}
                      todayDate={todayDate}
                    /> : null
      }

      <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
        <div className={'fltr-second-text'}>
                    Photo
        </div>
        <EditImg imgUrl={imageUrl}
          style={{marginTop: '5px', height: '124px', width: '164px'}}
          heightImg={'124px'}
          widthImg={'164px'}
          isLoading={isLoadingImage}
          onDrop={(file) => handleFileDrop(file, setImageTempPath, setImageUrl, setIsLoadingImage, tempName)}
          clear={() => {
            setImageTempPath(null);
            setImageUrl(null);
            setIsForceUpdate(false);
          }}
        />
      </div>

      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '184px'}}>
        <div className={'fltr-second-text'}>
                    Name
        </div>
        <Input style={{marginTop: '7px', width: '100%'}}
          handleChange={(value) => {
            changeLocalization(value, 'name', localization, setName, setLocalization);
          }}
          value={getLocalizedValue(localization, 'name')}
        />
      </div>

      <div style={{'display': 'flex', 'alignItems': 'center'}}>
        <AddToPack
          element={element}
          joinPack={joinPack}
          setJoinPack={setJoinPack}
          setIsUpdateRelations={setIsUpdateRelations}
          packs={packs}
        />
      </div>
      {
        renderTags()
      }
    </div>;
  };

  return <div className="element-details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteElement}
      cancel={cancel}
      entity={element}
      handleSave={handleSave}
      isDisabled={!imageUrl || name === ''}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  languages: state.appState.languages,
  packs: state.packs.packs,
  tags: state.tags.tags,
});

const mapDispatchToProps = (dispatch) => ({
  addElement: bindActionCreators(addElement, dispatch),
  updateElement: bindActionCreators(updateElement, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IOSIconDetails);
