import React, {useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {getStyle} from '../../utils/StyleUtils';

export const FileInput = ({style, value, handleChange, isLoading, isMultiFiles = false}) => {
  const [countFiles, setCountFiles] = useState(null);

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px'}}>
      <MDSpinner size={20} singleColor={'#00DADA'}/>
    </div>;
  }

  return (
    <div className={getStyle('fltr-input-file-container')} style={style}>
      <input type="file" className="fltr-input-file"
        multiple={isMultiFiles ? 'multiple' : ''}
        onChange={(event) => {
          const files = Object.keys(event.target.files)
              .map((keyFile) => event.target.files[keyFile]);
          if (event.target.files.length > 0) {
            setCountFiles(files.length);
            handleChange(
                                    isMultiFiles ? files : files[0],
            );
          }
        }}/>
      <div className="fltr-input-file-text">
        {isMultiFiles && countFiles ? (countFiles + ' files') : value }
      </div>
      <div className={getStyle('fltr-input-file-btn')}>
                            Choose
      </div>
    </div>
  );
};
