export const NotificationStatus = {
  SENT: 'SENT',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_FOR_SENDING: 'READY_FOR_SENDING',
  DISMISSED: 'DISMISSED',
  SKIPPED: 'SKIPPED',
};

export const NotificationStatusAction = {
  [NotificationStatus.SENT]: {
    name: 'Send again',
    actionStatus: NotificationStatus.READY_FOR_SENDING,
  },
  [NotificationStatus.IN_PROGRESS]: {
    name: 'Interrupt',
    actionStatus: NotificationStatus.DISMISSED,
  },
  [NotificationStatus.READY_FOR_SENDING]: {
    name: 'Dont send',
    actionStatus: NotificationStatus.DISMISSED,
  },
  [NotificationStatus.SKIPPED]: {
    name: 'Send',
    actionStatus: NotificationStatus.READY_FOR_SENDING,
  },
  [NotificationStatus.DISMISSED]: {
    name: 'Send',
    actionStatus: NotificationStatus.READY_FOR_SENDING,
  },
};
