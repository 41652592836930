import React, {useState} from 'react';
import {EditImg} from '../general/EditImg';
import {Input} from '../general/Input';
import {Badge} from '../general/Badge';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addHighlightPack, addPack, updatePack} from '../../actions/packs';
import {changeLocalization, getLocalizedValue} from '../../utils/LocalizationUtils';
import {compressImage, getFilenameWithoutType, uploadFileToS3} from '../../utils/FilesUtils';
import uuid from 'uuid/v4';
import {TodayInput} from '../elements/details/TodayInput';
import {DetailsFooter} from '../general/DetailsFooter';
import {ImagesInput} from '../general/ImagesInput';
import {CheckboxPackDetails} from './CheckboxPacksDetails';
import {NotificationStatusAction} from '../../models/notifiactions';
import {TagsPack} from './TagsPack';

const PackDetails = ({pack, mode, groups, cancel, deletePack, packsInGroups, tags,
  addPack, updatePack, packsCount, languages, addHighlightPack,
}) => {
  const [isSetToday, setIsSetToday] = useState(pack.todayDate);
  const [tempImageName] = useState(uuid());

  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [isVisible, setIsVisible] = useState(pack.isVisible);
  const [isNew, setIsNew] = useState(pack.isNew);
  const [isPremium, setIsPremium] = useState(pack.isPremium);
  const [isFree, setIsFree] = useState(pack.isFree);
  const [name, setName] = useState(pack.name);
  const [imageTempPath, setImageTempPath] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(pack.imageUrl);
  const [todayDate, setTodayDate] = useState(pack.todayDate);
  const [description, setDescription] = useState(pack.description);
  const [packToGroups, setPackToGroups] = useState(pack.packToGroups);
  const [localization, setLocalization] = useState(pack.localization);
  const [tagIds, setTagIds] = useState(pack.fltrTagIds);
  const [notificationStatus, setNotificationStatus] = useState(pack.notificationStatus);
  const [elements, setElements] = useState([]);

  const [isLoadingElementsImages, setIsLoadingElementsImages] = useState(false);

  const handleFileDrop = (file) => {
    if (file) {
      setIsLoadingImage(true);
      console.log('File size ' + file.size);
      compressImage(file, 4000000, (fileCompressed) => {
        console.log('FileCompress size ' + fileCompressed.size);
        uploadFileToS3(fileCompressed, tempImageName, (pathTemp) => {
          setImageTempPath(pathTemp);
          setIsForceUpdate(true);
          setIsLoadingImage(false);
          setImageUrl(URL.createObjectURL(file));
        });
      });
    }
  };

  const handleClickGroup = (groupId, isActive) => {
    const newPackToGroups = [...packToGroups];

    const packToGroup = newPackToGroups.find((newPackToGroup) => {
      return newPackToGroup.groupId === groupId;
    });
    if (isActive && !packToGroup) {
      newPackToGroups.push({
        groupId,
        packId: pack.id,
        uuid: uuid(),
        order: packsInGroups[groupId] ? packsInGroups[groupId].length + 1 : 0,
      });
    }

    if (!isActive && packToGroup) {
      const index = newPackToGroups.indexOf(packToGroup);
      newPackToGroups.splice(index, 1);
    }
    setIsUpdateRelations(true);
    setPackToGroups(newPackToGroups);
  };

  const handleSavePack = () => {
    const packForSave = {...pack};
    packForSave.isVisible = isVisible;
    packForSave.isNew = isNew;
    packForSave.isPremium = isPremium;
    packForSave.name = name;
    packForSave.description = description;
    packForSave.packToGroups = packToGroups;
    packForSave.fltrTagIds = tagIds;
    packForSave.isFree = isFree;
    packForSave.isForceUpdate = isForceUpdate;
    packForSave.imageTempPath = imageTempPath;
    packForSave.imageUrl = imageUrl;
    packForSave.previewUrl = imageUrl;
    packForSave.todayDate = todayDate;
    packForSave.localization = localization;
    packForSave.notificationStatus = notificationStatus;
    packForSave.sku = packForSave.sku ? packForSave.sku : uuid();
    packForSave.order = mode === 'EDIT' ? packForSave.order : packsCount;
    packForSave.isUpdateRelations = isUpdateRelations;
        mode === 'EDIT' ? updatePack(packForSave) :
            pack.contentTypeId === 'Highlight' || pack.contentTypeId === 'iOSIcon' ? addHighlightPack(packForSave, elements) : addPack(packForSave);
        cancel();
  };

  const renderHeader = () => {
    return <div className={'pack-details-header'} style={{flexDirection: 'row'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'fltr-head-text'}>
          {mode === 'EDIT' ? 'Edit Pack' : 'Add Pack'}
        </div>
        <div className={'fltr-second-text'}>
          {pack.id ? 'ID: ' + pack.id : ''}
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'fltr-head-text'} style={{fontSize: '14px'}}>
          Notification status:
        </div>
        <div className={'fltr-badge-text'}>
          {notificationStatus}
        </div>
        <div className={'save-btn'} style={{marginTop: '10px'}} onClick={() => {
          setNotificationStatus(NotificationStatusAction[notificationStatus].actionStatus);
        }}>
          {NotificationStatusAction[notificationStatus].name}
        </div>
      </div>
    </div>;
  };

  const renderGroups = () => {
    return <div style={{
      paddingTop: '17px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'fltr-second-text'}>
               Add to Group
      </div>
      <div className={'fltr-badge-container'} style={{marginTop: '2px'}}>
        {groups
            .filter((group) => (group.contentTypeId === pack.contentTypeId))
            .map((group) => {
              return <Badge
                key={group.id}
                style={{marginTop: '9px', marginLeft: '15px'}}
                onChange={(isActive)=>{
                  handleClickGroup(group.id, isActive);
                }}
                value={group.name}
                isActive={packToGroups.find((packToGroup) => {
                  return packToGroup.groupId === group.id;
                })}
              />;
            })}
      </div>
    </div>;
  };
  const renderTags = () => {
    return <div style={{
      paddingTop: '17px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'fltr-second-text'}>
                Tags
      </div>
      <TagsPack
        allTags={tags}
        elementIds={pack.elementToPacks.map((elementToPack) => {
          return elementToPack.elementId;
        })}
      />
      <div className={'fltr-second-text'} style={{
        paddingTop: '5px',
      }}>
        {description}
      </div>
    </div>;
  };

  const renderDescription = (lang, langKey) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={langKey + 'input'}>
      <div className={'fltr-second-text'}>
                Description {lang.toUpperCase()}
      </div>
      <Input style={{marginTop: '7px', width: '100%', resize: 'vertical'}}
        handleChange={(value) => {
          changeLocalization(value, 'description', localization, setDescription, setLocalization, lang);
        }}
        value={getLocalizedValue(localization, 'description', lang)}
      />
    </div>;
  };

  const renderBody = () => {
    return <div className={'pack-details-body'}>
      <CheckboxPackDetails
        isFree={isFree}
        setIsFree={setIsFree}
        isNew={isNew}
        setIsNew={setIsNew}
        setIsPremium={setIsPremium}
        isPremium={isPremium}
        setIsSetToday={setIsSetToday}
        setTodayDate={setTodayDate}
        isSetToday={isSetToday}
        setIsVisible={setIsVisible}
        isVisible={isVisible}
      />
      {
                isSetToday ?
                    <TodayInput
                      style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}
                      setTodayDate={setTodayDate}
                      todayDate={todayDate}
                    /> : null
      }
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '164px'}}>
        <div className={'fltr-second-text'}>
                    Photo
        </div>
        <EditImg imgUrl={imageUrl}
          style={{marginTop: '5px', height: '124px', width: '164px'}}
          heightImg={'124px'}
          widthImg={'164px'}
          onDrop={handleFileDrop}
          isLoading={isLoadingImage}
          clear={() => {
            setImageTempPath(null);
            setImageUrl(null);
            setIsForceUpdate(false);
          }}
        />
      </div>

      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '200px'}}>
          <div className={'fltr-second-text'}>
                        Name
          </div>
          <Input style={{marginTop: '7px', width: '100%'}}
            handleChange={(value) => {
              changeLocalization(value, 'name', localization, setName, setLocalization);
            }}
            value={getLocalizedValue(localization, 'name')}
          />
        </div>

        {mode === 'ADD' && (pack.contentTypeId === 'Highlight' || pack.contentTypeId === 'iOSIcon') ?
                <ImagesInput
                  style={{
                    display: 'flex', marginTop: '13px', flexDirection: 'column',
                    width: '200px', marginLeft: '30px',
                  }}
                  title={'Elements'}
                  isLoading={isLoadingElementsImages}
                  handleFilesDrop={(files) => {
                    setIsLoadingElementsImages(true);
                    const elements = [];

                    files.forEach((file) => {
                      compressImage(file, 4000000, (fileCompressed) => {
                        uploadFileToS3(fileCompressed, uuid(), (pathTemp) => {
                          elements.push({
                            path: pathTemp,
                            name: getFilenameWithoutType(file.name),
                          });
                          if (files.length === files.length) {
                            setElements(elements);
                            setIsLoadingElementsImages(false);
                          }
                        });
                      });
                    });
                  }}
                /> : null}
      </div>
      {renderGroups()}
      { ['Preset', 'Highlight', 'iOSIcon'].indexOf(pack.contentTypeId) !== -1 ? renderTags() :
              <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
                {Object.keys(languages).map((langKey) => {
                  return renderDescription(langKey, languages[langKey]);
                })
                }
              </div>
      }
    </div>;
  };

  return <div className="pack-details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deletePack}
      cancel={cancel}
      entity={pack}
      handleSave={handleSavePack}
      isDisabled={!imageUrl || name === '' || isLoadingElementsImages}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  packsInGroups: state.packs.packsInGroups,
  packsCount: state.packs.packs.length,
  tags: state.tags.tags,
  languages: state.appState.languages,
});

const mapDispatchToProps = (dispatch) => ({
  addPack: bindActionCreators(addPack, dispatch),
  updatePack: bindActionCreators(updatePack, dispatch),
  addHighlightPack: bindActionCreators(addHighlightPack, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackDetails);
