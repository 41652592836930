import React, {useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import update from 'immutability-helper';
import Element from './Element';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {moveElementInPack, addElementToPack,
  replaceElementFromPackToPack, removeElementFromPack,
} from '../../actions/elements';
import useGlobalDndState, {sourceDndDroppable, typeDndAction} from '../general/Dnd/DndState';
import uuid from 'uuid/v4';
import {ComponentType, MetaType, showModal, typeModal} from '../../actions/general';
import {unwrapId, wrapId} from '../../utils/DragAndDropUtils';
import {Droppable} from 'react-beautiful-dnd';

export const ElementsListType = {
  ONLY_ELEMENTS: 'ONLY_ELEMENTS',
  ELEMENTS_IN_PACK: 'ELEMENTS_IN_PACK',
  ELEMENTS_IN_TODAY: 'ELEMENTS_IN_TODAY',
  ELEMENTS_IN_PREMIUM: 'ELEMENTS_IN_PREMIUM',
};

const ElementsList = ({elements, isLoadingElements, selectedLang, elementsAll, replaceElementFromPackToPack,
  elementsListType, moveElementInPack, isRenderList = true,
  packId, elementsInPacks, addElementToPack, showModal, packContentType,
}) => {
  const [droppableUUID] = useState(uuid());

  const droppableId = packId ? wrapId(packId, droppableUUID) : sourceDndDroppable.ListElements;
  const [localElements, setElements] = useState(elements);

  useEffect(() => {
    setElements(elements);
  }, [elements]);


  const [dndState] = useGlobalDndState(droppableId);

  useEffect(() => {
    if (dndState) {
      if (dndState.action === typeDndAction.MOVE) {
        moveDnd(dndState.draggableId, dndState.toIndex);
      }
      if (dndState.action === typeDndAction.ADD) {
        addDnd(dndState.draggableId, dndState.toIndex);
      }
      if (dndState.action === typeDndAction.REMOVE_LOCAL) {
        removeLocalDnd(dndState.draggableId);
      }
      if (dndState.action === typeDndAction.REPLACE) {
        replaceDnd(dndState.draggableId, dndState.toIndex, unwrapId(dndState.sourceDroppableId));
      }
      if (dndState.action === typeDndAction.REMOVE) {
        removeDnd(dndState.draggableId);
      }
    }
  }, [dndState]);

  const moveDnd = (id, atIndex) => {
    const {element, index} = findElement(id);
    const newLocalElements = update(localElements, {
      $splice: [[index, 1], [atIndex, 0, element]],
    });

    moveElementInPack(
        newLocalElements.map((element, index) => {
          return {
            entityId: element.uuidRelation,
            entityIdOld: element.id,
            orderIndex: index,
          };
        }),
        element,
        index,
        atIndex,
        packId,
    );

    setElements(newLocalElements);
  };

  const addDnd = (id, atIndex) => {
    const element = elementsAll.filter((c) => c.id === id)[0];
    const newElement = {...element};
    newElement.uuidRelation = uuid();

    const localElement = localElements.find((localElement) => localElement.id === newElement.id);
    const newLocalElements = localElements.filter((localElement) => localElement.id !== newElement.id);
    newLocalElements.splice(atIndex, 0, newElement);
    setElements(newLocalElements);

    addElementToPack(
        newLocalElements.map((element, index) => {
          return {
            entityId: element.uuidRelation,
            entityIdOld: element.id,
            orderIndex: index,
          };
        }),
        newElement,
        atIndex,
        packId,
        {
          toEntityId: packId,
          fromEntityId: newElement.id,
          orderIndex: atIndex,
          uuid: newElement.uuidRelation,
        },
            localElement ? localElement.uuidRelation : undefined,
    );
  };

  const removeDnd = (id) => {
    const {element} = findElement(id);

    const newLocalElements = localElements.filter((localElement) => localElement.id !== element.id);
    setElements(newLocalElements);

    removeElementFromPack(element, packId, element.uuidRelation);
  };

  const removeLocalDnd = (id) => {
    const {element} = findElement(id);

    const newLocalElements = localElements.filter((localElement) => localElement.id !== element.id);
    setElements(newLocalElements);
  };

  const replaceDnd = (id, toIndex, fromPackId) => {
    const element = elementsInPacks[fromPackId].filter((c) => c.id === id)[0];
    const fromIndex = elementsInPacks[fromPackId].indexOf(element);
    const oldUUID = element.uuidRelation;
    element.uuidRelation = uuid();

    const localElement = localElements.find((localElement) => localElement.id === element.id);
    const newLocalElements = localElements.filter((localElement) => localElement.id !== element.id);
    newLocalElements.splice(toIndex, 0, element);

    replaceElementFromPackToPack(
        newLocalElements.map((element, index) => {
          return {
            entityId: element.uuidRelation,
            entityIdOld: element.id,
            orderIndex: index,
          };
        }),
        element,
        fromIndex,
        toIndex,
        fromPackId,
        packId,
        {
          toEntityId: packId,
          fromEntityId: element.id,
          orderIndex: toIndex,
          uuid: element.uuidRelation,
        },
        oldUUID,
            localElement ? localElement.uuidRelation : undefined,
    );

    setElements(newLocalElements);
  };

  if (isLoadingElements) {
    return (
      <div className='spinner-container' style={{height: '424px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  const findElement = (id) => {
    const element = localElements.filter((c) => c.id === id)[0];
    return {
      element,
      index: localElements.indexOf(element),
    };
  };

  const renderAdd = () => {
    return <div className={'add-content-container'} style={{marginLeft: '13px'}}
      onClick={() => {
        showModal(typeModal.ELEMENT_ADD, true, {type: MetaType.WithPack, packId: packId, packContentType});
      }}
    >

      <img className={'element-list-img-in-pack'}
        style={{border: 'none'}}
        src={require('../../resources/imgs/add-content-icon.jpg').default}
      />

      <div style={{display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '10px',
      }}>
        <div className={'pack-list-value-name'}>
                    Add element
        </div>
      </div>
    </div>;
  };

  return (
    <Droppable droppableId={droppableId} type={ComponentType.ELEMENT} isDropDisabled={!packId}>
      {(provided, snapshot) => (
        <div className={'element-list'}
          ref={provided.innerRef}
          style={{
            marginTop: (elementsListType === ElementsListType.ONLY_ELEMENTS ? '18px' : undefined),
            marginLeft: (elementsListType === ElementsListType.ELEMENTS_IN_PACK ? '24px' : undefined),
          }}
        >
          {isRenderList && elementsListType !== ElementsListType.ONLY_ELEMENTS? renderAdd() : null}
          {
                isRenderList ? localElements.map((element, index) => {
                  return <Element
                    key={element.id}
                    element={element}
                    arrayIndex={index}
                    id={element.id}
                    draggableId={wrapId(element.id, droppableId)}
                    isSelected={false}
                    elementListType={elementsListType}
                    packId={packId}
                    isBlockDnD={elementsListType === ElementsListType.ONLY_ELEMENTS}
                  />;
                }) : <div style={{height: '1px'}}></div>
          }
          {provided.placeholder}
          {localElements.length === 0 && isRenderList ? <div style={{height: '1px'}}></div> : null}
        </div>
      )}
    </Droppable>
  );
};

const mapStateToProps = (state) => ({
  isLoadingElements: state.elements.isLoadingElements,
  selectedLang: state.appState.selectedLang,
  elementsAll: state.elements.elements,
});

const mapDispatchToProps = (dispatch) => ({
  moveElementInPack: bindActionCreators(moveElementInPack, dispatch),
  addElementToPack: bindActionCreators(addElementToPack, dispatch),
  replaceElementFromPackToPack: bindActionCreators(replaceElementFromPackToPack, dispatch),
  removeElementFromPack: bindActionCreators(removeElementFromPack, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementsList);
