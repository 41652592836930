import React, {useEffect, useState} from 'react';
import {ComponentType} from '../../actions/general';
import {PacksListType} from '../packs/PacksList';
import {bindActionCreators} from 'redux';
import {
  setDeletePack,
  setEditPack,
  updatePack,
} from '../../actions/packs';
import {connect} from 'react-redux';
import MoveTo from './MoveTo';
import {
  addElementToPack,
  removeElementFromPack,
  setDeleteElement,
  setEditElement,
  updateElement,
} from '../../actions/elements';
import {ElementsListType} from '../elements/ElementsList';
import {setDeleteGroup, setEditGroup, updateGroup} from '../../actions/groups';
import {copyToClipboard} from '../../utils/LocalStoargeUtill';
import uuid from 'uuid/v4';
import useGlobalDndState, {typeDndAction} from './Dnd/DndState';
import {setDeleteTag, setEditTag, updateTag} from '../../actions/tags';

const ComponentSettings = ({componentType, componentTypeIn, updatePack, sourceItemId, elementsInPacks,
  editElement, packs, updateElement, editGroup, deleteGroup, removeElementFromPack,
  item, editPack, deletePack, onClose, groups, deleteElement, addElementToPack, updateTag,
  editTag, deleteTag,
}) => {
  const [moveToProps, setMoveToProps] = useState(null);
  const [isShowMoveTo, setIsShowMoveTo] = useState(false);
  const [globalState, globalActions] = useGlobalDndState();

  useEffect(() => {
    if (moveToProps) {
      setIsShowMoveTo(true);
    }
  }, [moveToProps]);

  const getSettings = () => {
    let settings = [];
    // PACK
    if (componentType === ComponentType.PACK) {
      const edit = {name: 'Edit', action: () => (onClose(), editPack(item))};
      const showHide = {name: (item.isVisible ? 'Hide' : 'Show'), action: () => {
        onClose();
        updatePack(
            Object.assign({}, item, {
              isVisible: !item.isVisible,
            }),
        );
      }};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deletePack(item))};
      const moveTo = {name: componentTypeIn === PacksListType.PACKS_IN_GROUP ? 'Move to Section' : 'Add to Section', action: () => {
        setMoveToProps({
          moveTo: (pack, group) => {
            onClose();
            if (sourceItemId) {
              globalActions.changeDroppableState(
                  group.id,
                  {
                    action: typeDndAction.REPLACE,
                    toIndex: 0,
                    draggableId: pack.id,
                    sourceDroppableId: sourceItemId,
                  },
              );
            } else {
              globalActions.changeDroppableState(
                  group.id,
                  {
                    action: typeDndAction.ADD,
                    draggableId: pack.id,
                    toIndex: 0,
                  },
              );
            }
          },
          items: groups.filter((group) => (
            item.contentTypeId === group.contentTypeId && group.id !== sourceItemId
          ),
          ),
          propName: 'name',
        });
      }};
      const remove = {name: 'Delete from Section', action: () => {
        globalActions.changeDroppableState(
            sourceItemId,
            {
              action: typeDndAction.REMOVE,
              draggableId: item.id,
            },
        );
        onClose();
      }};
      const premiumAction = {name: (item.isPremium ? 'Unpremium' : 'Premium'), action: () => {
        updatePack(
            Object.assign({}, item, {
              isPremium: !item.isPremium,
            }),
        );
        onClose();
      }};
      const copyLink = {name: 'Copy Link', action: () => {
        onClose();
        copyToClipboard(`https://fltr.go.link?adj_t=142vntrv_148xfc8l&adj_engagement_type=fallback_click&adj_label=${btoa(JSON.stringify({pack: String(item.id)}))}`);
      }};

      if (componentTypeIn === PacksListType.ONLY_PACKS) {
        settings = [edit, moveTo, showHide, copyLink, deleteAction];
      }

      if (componentTypeIn === PacksListType.PACKS_IN_TODAY ) {
        settings = [edit, showHide, copyLink, deleteAction];
      }

      if (componentTypeIn === PacksListType.PACKS_IN_GROUP) {
        settings = [edit, showHide, moveTo, copyLink, remove];
      }

      if (componentTypeIn === PacksListType.PACKS_IN_PREMIUM) {
        settings = [premiumAction, copyLink, edit, deleteAction];
      }
    }
    // ELEMENT
    if (componentType === ComponentType.ELEMENT) {
      const edit = {name: 'Edit', action: () => (onClose(), editElement(item))};
      const showHide = {name: (item.isActive ? 'Hide' : 'Show'), action: () => {
        onClose();
        updateElement(
            Object.assign({}, item, {
              isActive: !item.isActive,
            }),
        );
      }};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteElement(item))};
      const moveTo = {name: 'Copy to', action: () => {
        setMoveToProps({
          moveTo: (element, pack) => {
            const newElement = {...element};
            newElement.uuidRelation = uuid();

            addElementToPack(
                null,
                newElement,
                elementsInPacks[pack.id].length + 1,
                pack.id,
                {
                  toEntityId: pack.id,
                  fromEntityId: newElement.id,
                  orderIndex: elementsInPacks[pack.id].length + 1,
                  uuid: newElement.uuidRelation,
                },
            );
            removeElementFromPack(item, sourceItemId, item.uuidRelation);
            onClose();
          },
          items: packs.filter((pack) => (
            item.contentTypeId === pack.contentTypeId && pack.id !== sourceItemId),
          ),
          propName: 'name',
        });
      }};
      const remove = {name: 'Delete', action: () => {
        removeElementFromPack(item, sourceItemId, item.uuidRelation);
        onClose();
      }};
      const premiumAction = {name: (item.isPremium ? 'Unpremium' : 'Premium'), action: () => {
        onClose();
        updateElement(
            Object.assign({}, item, {
              isPremium: !item.isPremium,
            }),
        );
      }};
      const copyLink = {name: 'Copy Link', action: () => {
        onClose();
          copyToClipboard(`https://fltr.go.link?adj_t=142vntrv_148xfc8l&adj_engagement_type=fallback_click&adj_label=${btoa(JSON.stringify({element: String(item.id)}))}`);
      }};

      if (componentTypeIn === ElementsListType.ONLY_ELEMENTS) {
        settings = [edit, showHide, deleteAction, copyLink, moveTo];
      }

      if (componentTypeIn === ElementsListType.ELEMENTS_IN_PACK) {
        settings = [edit, showHide, moveTo, copyLink, remove];
      }

      if (componentTypeIn === ElementsListType.ELEMENTS_IN_PREMIUM) {
        settings = [premiumAction, copyLink, edit, deleteAction];
      }

      if (componentTypeIn === ElementsListType.ELEMENTS_IN_TODAY ) {
        settings = [edit, showHide, deleteAction, copyLink];
      }
    }
    // GROUP
    if (componentType === ComponentType.GROUP) {
      const edit = {name: 'Edit', action: () => (onClose(), editGroup(item))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteGroup(item))};
      settings = [edit];
      if (item.id > 3) {
        settings.push(deleteAction);
      }
    }
    // TAG
    if (componentType === ComponentType.TAG) {
      const edit = {name: 'Edit', action: () => (onClose(), editTag(item))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteTag(item))};
      const showHide = {isDisabled: !item.imageUrl, name: (item.isActive ? 'Hide' : 'Show'), action: () => {
        onClose();
        updateTag(
            Object.assign({}, item, {
              isActive: !item.isActive,
            }),
        );
      }};
      settings = [edit, showHide, deleteAction];
    }
    return settings;
  };

  return (
    <div className={'component-settings-container'}>
      {
                isShowMoveTo ?
                <MoveTo
                  moveTo={moveToProps.moveTo}
                  items={moveToProps.items}
                  propName={moveToProps.propName}
                  sourceItem={item}
                /> :
                getSettings().map((setting, index) => {
                  return <div
                    className={'component-setting'}
                    key={index + 'setting_' + setting.name + item.id}
                    onClick={setting.isDisabled ? () => {} : setting.action}
                  >
                    <div style={{opacity: (setting.isDisabled ? 0.5 : 1)}}
                      className={'component-setting-text'}>{setting.name}</div>
                  </div>;
                })
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  packs: state.packs.packs,
  elementsInPacks: state.elements.elementsInPacks,
});

const mapDispatchToProps = (dispatch) => ({
  editPack: bindActionCreators(setEditPack, dispatch),
  deletePack: bindActionCreators(setDeletePack, dispatch),
  updatePack: bindActionCreators(updatePack, dispatch),

  editElement: bindActionCreators(setEditElement, dispatch),
  deleteElement: bindActionCreators(setDeleteElement, dispatch),
  updateElement: bindActionCreators(updateElement, dispatch),
  addElementToPack: bindActionCreators(addElementToPack, dispatch),
  removeElementFromPack: bindActionCreators(removeElementFromPack, dispatch),

  deleteGroup: bindActionCreators(setDeleteGroup, dispatch),
  updateGroup: bindActionCreators(updateGroup, dispatch),
  editGroup: bindActionCreators(setEditGroup, dispatch),

  updateTag: bindActionCreators(updateTag, dispatch),
  editTag: bindActionCreators(setEditTag, dispatch),
  deleteTag: bindActionCreators(setDeleteTag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentSettings);

