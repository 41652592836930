import uuid from 'uuid/v4';
import {
  ADD_ELEMENT,
  DELETE_ELEMENT,
  IS_LOADING_ELEMENTS,
  SAVE_ELEMENTS,
  SELECT_ELEMENT,
  UPDATE_ELEMENT,
  UPDATE_RELATION_ELEMENT_TO_PACK,
  MOVE_ELEMENTS_IN_PACK,
  ADD_ELEMENT_TO_PACK, REPLACE_ELEMENTS_IN_PACKS, REMOVE_ELEMENT_FROM_PACK,
} from '../actions/elements';
import {DELETE_PACK_FROM_ELEMENTS} from '../actions/packs';
import update from 'immutability-helper';
import {REMOVE_TAG_FROM_ELEMENTS} from '../actions/tags';

const getInintialState = () => {
  return {
    stateId: uuid(),
    elements: [],
    selectedElement: {id: -1},
    isLoadingElements: false,
    timeStampSaveElements: 0,
    timeStampAddPresets: 0,
    elementsInPacks: {},
  };
};

export default function elements(state = getInintialState(), action) {
  switch (action.type) {
    case SAVE_ELEMENTS:
      const timeStampSaveElements = action.requestTimeEpoch;
      const stateIdRequestedSaveElements = action.stateId;

      if (
        timeStampSaveElements > state.timeStampSaveElements &&
                stateIdRequestedSaveElements === state.stateId
      ) {
        const elementsInPacks = {};

        action.packs.forEach((pack) => {
          elementsInPacks[pack.id] = [];
        });

        action.elements.forEach((element) => {
          element.elementToPacks.forEach((elementToPack) => {
            const copyElement = {...element};
            copyElement.orderIndex = elementToPack.order;
            copyElement.uuidRelation = elementToPack.uuid;
            elementsInPacks[elementToPack.packId].push(copyElement);
          });
        });

        Object.keys(elementsInPacks).forEach((key) => {
          elementsInPacks[key] = elementsInPacks[key].sort((a, b) => {
            return a.orderIndex - b.orderIndex;
          });
        });

        return Object.assign({}, state, {
          elements: action.elements,
          isLoadingElements: false,
          timeStampSaveElements: timeStampSaveElements,
          elementsInPacks: elementsInPacks,
        });
      }
      return state;

    case MOVE_ELEMENTS_IN_PACK:
      const movedElements = update(state.elementsInPacks[action.packId], {
        $splice: [[action.fromIndex, 1], [action.toIndex, 0, action.element]],
      });

      return Object.assign({}, state, {
        elementsInPacks: Object.assign({}, state.elementsInPacks, {
          [action.packId]: movedElements,
        }),
      });

    case ADD_ELEMENT_TO_PACK:
      const toElementsAdd = [...state.elementsInPacks[action.toPackId]]
          .filter((element) => (element.id !== action.element.id));

      toElementsAdd.splice(action.toIndex, 0, action.element);

      const newElemToAddElemToPack = {...action.element};
      if (newElemToAddElemToPack.elementToPacks.length === 0) {
        // For replace from Elements Section
        newElemToAddElemToPack.elementToPacks.push({});
      }

      return Object.assign({}, state, {
        elementsInPacks: Object.assign({}, state.elementsInPacks, {
          [action.toPackId]: toElementsAdd,
        }),
        elements: state.elements
            .map((element) => element.id === action.id ? newElemToAddElemToPack : element),
      });

    case REPLACE_ELEMENTS_IN_PACKS:
      const toElementsReplaced = [...state.elementsInPacks[action.toPackId]]
          .filter((element) => (element.id !== action.element.id));

      toElementsReplaced.splice(action.toIndex, 0, action.element);

      const fromElementsReplaced = state.elementsInPacks[action.fromPackId]
          .filter((element) => (element.id !== action.element.id));

      return Object.assign({}, state, {
        elementsInPacks: Object.assign({}, state.elementsInPacks, {
          [action.toPackId]: toElementsReplaced,
          [action.fromPackId]: fromElementsReplaced,
        }),
      });

    case UPDATE_ELEMENT:
      const isUpdateRelations = action.element.isUpdateRelations;
      delete action.element.isUpdateRelations;

      const elementsInPacksForUpdateElement = {...state.elementsInPacks};
      Object.keys(elementsInPacksForUpdateElement)
          .forEach((elementInPackKey) => {
            let isContainElement = false;

            elementsInPacksForUpdateElement[elementInPackKey] = elementsInPacksForUpdateElement[elementInPackKey]
                .map((element) => {
                  if (element.id === action.element.id) {
                    isContainElement = true;
                    const newElement = {...element, ...action.element};
                    newElement.uuidRelation = element.uuidRelation;
                    return newElement;
                  }
                  return element;
                });
            if (isUpdateRelations) {
              const elementToPack = action.element.elementToPacks
                  .find((elementToPack) => elementToPack.packId === Number(elementInPackKey));
              if (!isContainElement && elementToPack) {
                elementsInPacksForUpdateElement[elementInPackKey]
                    .push({...action.element, ...{uuidRelation: elementToPack.uuid}});
              }
              if (isContainElement && !elementToPack) {
                elementsInPacksForUpdateElement[elementInPackKey] = elementsInPacksForUpdateElement[elementInPackKey]
                    .filter((element) => (element.id !== action.element.id));
              }
            }
          });

      return Object.assign({}, state, {
        elementsInPacks: elementsInPacksForUpdateElement,
        elements: state.elements.map((element) => {
          if (element.id === action.element.id) {
            return action.element;
          }
          return element;
        }),
      });

    case REMOVE_TAG_FROM_ELEMENTS: {
      const elementsInPacks = {...state.elementsInPacks};
      Object.keys(elementsInPacks)
          .forEach((elementInPackKey) => {
            elementsInPacks[elementInPackKey] = elementsInPacks[elementInPackKey]
                .map((element) => {
                  const newElement = {...element};
                  newElement.fltrTagIds = element.fltrTagIds.filter((fltrTagId) => {
                    return fltrTagId !== action.tagId;
                  });
                  return newElement;
                });
          });

      const elementsWithoutTag = state.elements
          .map((element) => {
            const newElement = {...element};
            newElement.fltrTagIds = element.fltrTagIds.filter((fltrTagId) => {
              return fltrTagId !== action.tagId;
            });
            return newElement;
          });

      return Object.assign({}, state, {
        elements: elementsWithoutTag,
        elementsInPacks,
      });
    }

    case REMOVE_ELEMENT_FROM_PACK:
      const fromElementsRemove = [...state.elementsInPacks[action.fromPackId]]
          .filter((element) => (element.id !== action.element.id));

      const isContainRelation = Object.keys(state.elementsInPacks)
          .find((elementsInPacksKey) => {
            return Number(elementsInPacksKey) !== action.fromPackId &&
                        state.elementsInPacks[elementsInPacksKey].find((element) => (element.id === action.element.id));
          });

      return Object.assign({}, state, {
        elementsInPacks: Object.assign({}, state.elementsInPacks, {
          [action.fromPackId]: fromElementsRemove,
        }),
        elements: state.elements
            .map((element) => {
              if (element.id === action.element.id && !isContainRelation) {
                const newElement = {...element};
                newElement.elementToPacks = [];
                return newElement;
              }

              return element;
            }),
      });

    case SELECT_ELEMENT:
      return Object.assign({}, state, {
        selectedElement: action.element,
      });

    case ADD_ELEMENT:
      const elementsForAddNew = [...state.elements];
      elementsForAddNew.push(action.element);

      return Object.assign({}, state, {
        elements: elementsForAddNew,
        isLoadingElements: false,
      });

    case UPDATE_RELATION_ELEMENT_TO_PACK:
      const elementsUpdatedRelation = state.elements
          .map((element) => {
            const newElement = {...element};
            if (element.id === action.newRelation.fromEntityId) {
              newElement.elementToPacks = element.elementToPacks.filter((elementToPack) => {
                return elementToPack.uuid !== action.newRelation.oldUUID;
              });
              newElement.elementToPacks.push({
                uuid: action.newRelation.uuid,
                elementId: element.id,
                packId: action.newRelation.toEntityId,
                order: action.newRelation.orderIndex,
              });
            }

            return newElement;
          });

      return Object.assign({}, state, {
        elements: elementsUpdatedRelation,
      });

    case DELETE_ELEMENT:
      const elementsInPacksForDeleteElement = {...state.elementsInPacks};
      Object.keys(elementsInPacksForDeleteElement)
          .forEach((elementInPackKey) => {
            elementsInPacksForDeleteElement[elementInPackKey] = elementsInPacksForDeleteElement[elementInPackKey]
                .filter((element) => (element.id !== action.elementId));
          });

      return Object.assign({}, state, {
        elementsInPacks: elementsInPacksForDeleteElement,
        elements: state.elements.filter((element) => {
          return element.id !== action.elementId;
        }),
      });

    case DELETE_PACK_FROM_ELEMENTS:
      const elementsInPacksDeletePack = {...state.elementsInPacks};
      const elementIds = elementsInPacksDeletePack[action.packId].map((element) => element.id);
      delete elementsInPacksDeletePack[action.packId];

      return Object.assign({}, state, {
        elementsInPacks: elementsInPacksDeletePack,
        elements: state.elements.map((element) => {
          if (elementIds.find((elementId) => elementId === element.id)) {
            const newElement = {...element};
            newElement.elementToPacks.pop();
            if (newElement.elementToPacks.length === 0 && action.isDeleteWithPack) {
              return null;
            }
            return newElement;
          }
          return element;
        }).filter((element) => element),
      });

    case IS_LOADING_ELEMENTS:
      return Object.assign({}, state, {
        isLoadingElements: action.isLoading,
      });

    default: return state;
  }
}
