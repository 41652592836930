import {SET_IS_BLOCK_PUBLISH, SET_IS_RELOAD_FEED} from './general';
import {createRequest} from '../utils/HttpRequestUtills';

export const SAVE_GROUPS = 'SAVE_GROUPS';
export const IS_LOADING_GROUPS = 'IS_LOADING_GROUPS';
export const SELECT_GROUP = 'SELECT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP ='UPDATE_GROUP';
export const UPDATE_ORDERS_INDEXES_GROUPS = 'UPDATE_ORDERS_INDEXES_GROUPS';
export const SET_EDIT_GROUP = 'SET_EDIT_GROUP';
export const SET_DELETE_GROUP = 'SET_DELETE_GROUP';

export const deleteGroup = (groupId) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({type: DELETE_GROUP, groupId});

  const onload = () => {};

  const xhr = createRequest(dispatch, 'DELETE', '/group/' + groupId, onload);

  xhr.send();
};

export const addGroup = (group) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({type: IS_LOADING_GROUPS, isLoading: true});

  const onload = () => {
    dispatch({type: SET_IS_RELOAD_FEED, isReload: true});
  };
  const xhr = createRequest(dispatch, 'POST', '/group', onload);

  xhr.send(JSON.stringify(group));
};

export const updateGroup = (group) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({type: UPDATE_GROUP, group});

  const onload = () => {};

  const xhr = createRequest(dispatch, 'PATCH', '/group', onload);

  xhr.send(JSON.stringify(group));
};

export const updateGroupsOrderIndexes = (orderIndexes) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: UPDATE_ORDERS_INDEXES_GROUPS,
    orderIndexes,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/groups/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes}));
};

export const setEditGroup = (group) => (dispatch) => {
  dispatch({
    type: SET_EDIT_GROUP,
    group,
  });
};

export const setDeleteGroup = (group) => (dispatch) => {
  dispatch({
    type: SET_DELETE_GROUP,
    group,
  });
};
