import React from 'react';
import {getStyle} from '../../../utils/StyleUtils';
import {isMobileOnly} from 'react-device-detect';
import {REQUEST_STATUS} from '../../../actions/requests';
import {durationToHM} from '../../../utils/TimeUtils';
import momentZ from 'moment-timezone';

export const RequestsDetailsFooter = ({deleteEntity, handleSave, entity, isEnabledComplete, isHideDelete}) => {
  const textButton = getTextDetailFooter(entity);
  let leadTime = null;
  if (entity.closeDate && entity.inProgressDate) {
    const inProgressDate = momentZ(entity.inProgressDate);
    const closeDate = momentZ(entity.closeDate);

    const diff = closeDate.diff(inProgressDate);
    leadTime = durationToHM(diff);
  }

  if (isMobileOnly) {
    return <div className={getStyle('details-footer')}>
      <button className={REQUEST_STATUS.DONE !== entity.status ? 'save-btn-footer' : 'fltr-second-btn-footer'}
        onClick={handleSave}
        disabled={isEnabledComplete && REQUEST_STATUS.NEW !== entity.status}
      >
        {textButton}
      </button>
      <div>
        {leadTime ? 'Lead time ' + leadTime : undefined}
      </div>
    </div>;
  }

  return <div className={getStyle('details-footer')} style={{justifyContent: isHideDelete ? 'flex-end' : undefined}}>
    {isHideDelete ? null : <button className={'fltr-second-delete-btn'}
      style={{marginRight: 'auto'}}
      onClick={() => {
        deleteEntity(entity);
      }}
      disabled={isHideDelete}
    >
          Delete
    </button>}

    <button className={REQUEST_STATUS.DONE !== entity.status ? 'save-btn' : 'fltr-second-btn'}
      style={{marginLeft: '24px', display: 'flex', flexDirection: 'column'}}
      onClick={handleSave}
      disabled={isEnabledComplete && REQUEST_STATUS.NEW !== entity.status}
    >
      {textButton}
      <div>
        {leadTime ? 'Lead time ' + leadTime : undefined}
      </div>
    </button>
  </div>;
};

const getTextDetailFooter = (request) => {
  switch (request.status) {
    case REQUEST_STATUS.DONE: {
      return 'Completed by ' + request.adminName;
    }
    case REQUEST_STATUS.IN_PROGRESS: {
      return 'Request is Done';
    }
    case REQUEST_STATUS.NEW: {
      return 'Start Working';
    }
  }
};


