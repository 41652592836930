import React, {useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import DropdownWrapper from '../general/DropdownWrapper';
import ComponentSettings from '../general/ComponentSettings';
import {ComponentType} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Draggable} from 'react-beautiful-dnd';
import {setEditTag} from '../../actions/tags';

const Tag = ({id, tag, isBlockDnD, editTag,
  draggableId, arrayIndex, selectedContentTypeId,
}) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const renderButtons = () => {
    return <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
      <DropdownWrapper style={{right: '-100%'}}>
        <DotsHorizontal
          style={{opacity: (isMouseOver ? 1 : 0)}}
        />
        <ComponentSettings
          componentType={ComponentType.TAG}
          item={tag}
        />
      </DropdownWrapper>
    </div>;
  };
  const isDragging = false;

  const getImageSrc = () => {
    switch (selectedContentTypeId) {
      case 'Preset': return tag.imageUrl;
      case 'Highlight': return tag.highlightImageUrl;
      case 'iOSIcon': return tag.iconImageUrl;
      default: return tag.imageUrl ? tag.imageUrl : tag.highlightImageUrl ? tag.highlightImageUrl : tag.iconImageUrl;
    }
  };
  const style = {
    cursor: (isBlockDnD ? 'pointer' : 'move'),
    marginBottom: (isDragging ? '20px' : '0px'),
  };
  return (
    <Draggable
      key={tag.id}
      draggableId={draggableId}
      index={arrayIndex}
    >
      {(provided, snapshot) => (
        <div className={'pack-list-content'}
          onMouseLeave={() => setMouseOver(false)}
          onMouseEnter={() => setMouseOver(true)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{...provided.draggableProps.style}}
          {...provided.dragHandleProps}
          onClick={() => editTag(tag)}
        >
          <div className={'tag-list-item'}
            style={style}
          >
            { isBlockDnD ? null :
                            <div className={'drag-dots'}
                              style={{
                                opacity: (isMouseOver ? 1 : 0),
                              }}
                            >
                            </div>
            }

            <img className={'tag-list-img'}
              style={{opacity: (isDragging ? 0 : (tag.isActive ? '1' : '0.4'))}}
              src={getImageSrc()}
            />

            <div style={{display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '10px',
              opacity: (isDragging ? 0 : (tag.isActive ? '1' : '0.4')),
            }}>
              <div className={'pack-list-value-name'}>
                {`${tag.name} (${tag.elementsIds.length})`}
              </div>
            </div>

            {renderButtons()}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editTag: bindActionCreators(setEditTag, dispatch),
});

export default connect(null, mapDispatchToProps)(Tag);
