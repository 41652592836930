import React from 'react';
import PremiumList from './PremiumList';

const Premium = ({}) => {
  return (
    <div style={{height: '100%', overflow: 'overlay', marginBottom: '100px'}}>
      <div className={'today-container'}>
        <PremiumList/>
      </div>
    </div>
  );
};

export default Premium;
