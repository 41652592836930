import uuid from 'uuid/v4';
import {
  ADD_TAG,
  DELETE_TAG,
  IS_LOADING_TAGS,
  MOVE_TAGS,
  SAVE_TAGS,
  UPDATE_TAG,
  ORDER_TAGS,
  TAGS_CONTENT_TYPES, TAGS_ORDER_TYPES, SELECT_TAGS_CONTENT_TYPE,
} from '../actions/tags';
import update from 'immutability-helper';
import {DELETE_ELEMENT, UPDATE_ELEMENT_TAGS} from '../actions/elements';

const getInintialState = () => {
  return {
    stateId: uuid(),
    tags: [],
    sortedTags: [],
    isLoadingTags: false,
    timeStampSaveTags: 0,
    timeStampAddTag: 0,
    orderTagsParams: {
      orderBy: TAGS_ORDER_TYPES.NAME,
      orderIndex: 'ASC',
    },
    selectedTagsContentType: TAGS_CONTENT_TYPES.ALL,
  };
};

export default function tags(state = getInintialState(), action) {
  switch (action.type) {
    case SAVE_TAGS: {
      const timeStampSaveTags = action.requestTimeEpoch;
      const stateIdRequestedSaveTags = action.stateId;

      if (
        timeStampSaveTags > state.timeStampSaveTags &&
        stateIdRequestedSaveTags === state.stateId
      ) {
        const sortedEntities = sortTags(
            action.tags,
            action.elements,
            state.orderTagsParams,
            state.selectedTagsContentType,
        );
        return Object.assign({}, state, {
          tags: action.tags.sort((a, b) => {
            return a.order - b.order;
          }),
          isLoadingTags: false,
          timeStampSaveTags: timeStampSaveTags,
          sortedTags: sortedEntities,
        });
      }
      return state;
    }

    case ORDER_TAGS:
      const sortedEntities = sortTags(
          state.tags,
          action.elements,
          action.orderParams,
          state.selectedTagsContentType,
      );
      return Object.assign({}, state, {
        orderTagsParams: action.orderParams,
        sortedTags: sortedEntities,
      });

    case DELETE_TAG:
      return Object.assign({}, state, {
        tags: state.tags.filter((tag) => {
          return tag.id !== action.tagId;
        }),
        sortedTags: state.sortedTags.filter((tag) => {
          return tag.id !== action.tagId;
        }),
      });

    case UPDATE_TAG:
      return Object.assign({}, state, {
        tags: state.tags.map((tag) => {
          if (tag.id === action.tag.id) {
            return action.tag;
          }
          return tag;
        }),
        sortedTags: state.sortedTags.map((tag) => {
          if (tag.id === action.tag.id) {
            return action.tag;
          }
          return tag;
        }),
      });

    case ADD_TAG: {
      const tags = [...state.tags];
      tags.push(action.tag);

      return Object.assign({}, state, {
        tags: tags,
        sortedTags: sortTags(
            tags,
            action.elements,
            state.orderTagsParams,
            state.selectedTagsContentType,
        ),
      });
    }

    case IS_LOADING_TAGS:
      return Object.assign({}, state, {
        isLoadingTags: action.isLoading,
      });

    case MOVE_TAGS:
      const movedTags = state.tags.map((tag) => {
        const orderIndex = action.orderIndexes.find((orderIndex) => {
          return orderIndex.entityId === tag.id;
        });
        if (orderIndex) {
          tag.order = orderIndex.orderIndex;
        }
        return tag;
      }).sort((tagA, tagB) => tagA.order - tagB.order);

      return Object.assign({}, state, {
        tags: movedTags,
      });

    case SELECT_TAGS_CONTENT_TYPE:
      return Object.assign({}, state, {
        selectedTagsContentType: action.tagsContentType,
        sortedTags: sortTags(
            state.tags,
            action.elements,
            state.orderTagsParams,
            action.tagsContentType,
        ),
      });

    case DELETE_ELEMENT:
      const tagsWithoutElementId = state.tags.map((tag) => {
        const newTag = {...tag};
        newTag.elementsIds = newTag.elementsIds.filter((elementId) => {
          return elementId !== action.elementId;
        });
        return newTag;
      });
      return Object.assign({}, state, {
        tags: tagsWithoutElementId,
        sortedTags: sortTags(
            tagsWithoutElementId,
            action.elements,
            state.orderTagsParams,
            state.selectedTagsContentType,
        ),
      });

    case UPDATE_ELEMENT_TAGS: {
      const addedTagIds = [];
      const removedTagIds = [];
      action.oldElement.fltrTagIds.forEach((oldTagId) => {
        if (action.newElement.fltrTagIds.indexOf(oldTagId) === -1) {
          removedTagIds.push(oldTagId);
        }
      });
      action.newElement.fltrTagIds.forEach((oldTagId) => {
        if (action.oldElement.fltrTagIds.indexOf(oldTagId) === -1) {
          addedTagIds.push(oldTagId);
        }
      });

      const updatedTags = [...state.tags];
      updatedTags.forEach((tag) => {
        if (addedTagIds.indexOf(tag.id) !== -1) {
          tag.elementsIds.push(action.newElement.id);
        }
        if (removedTagIds.indexOf(tag.id) !== -1) {
          tag.elementsIds = tag.elementsIds.filter((elementId) => {
            return elementId !== action.newElement.id;
          });
        }
      });
      return Object.assign({}, state, {
        tags: updatedTags,
        sortedTags: sortTags(
            updatedTags,
            action.elements,
            state.orderTagsParams,
            state.selectedTagsContentType,
        ),
      });
    }

    default: return state;
  }
}

const getImageTypeName = (selectedTagsContentType) => {
  switch (selectedTagsContentType) {
    case TAGS_CONTENT_TYPES.PRESET: return 'imageUrl';
    case TAGS_CONTENT_TYPES.HIGHLIGHT: return 'highlightImageUrl';
    case TAGS_CONTENT_TYPES.IG_ICON: return 'iconImageUrl';
    case TAGS_CONTENT_TYPES.ALL: return null;
  }
};

const sortTags = (tags, elements, orderParams, selectedTagsContentType) => {
  const copyTags = JSON.parse(JSON.stringify(tags));
  let filteredTags = selectedTagsContentType === TAGS_CONTENT_TYPES.ALL ? copyTags : copyTags.filter((tag) => {
    const elementsIds = tag.elementsIds.filter((elementId) => {
      const element = elements.find((element) => {
        return element.id === elementId && element.contentTypeId === selectedTagsContentType.id;
      });
      return !!element;
    });
    return elementsIds.length !== 0;
  });

  filteredTags = filteredTags.sort((tagA, tagB) => {
    let a; let b;
    if (orderParams.orderBy === TAGS_ORDER_TYPES.NAME) {
      a = tagA.name.toLowerCase();
      b = tagB.name.toLowerCase();
    }
    if (orderParams.orderBy === TAGS_ORDER_TYPES.COUNT_ELEMENTS) {
      a = tagA.elementsIds.length;
      b = tagB.elementsIds.length;
      return orderParams.orderIndex === 'ASC' ? a - b : b - a;
    }
    if (orderParams.orderBy === TAGS_ORDER_TYPES.IS_VISIBLE) {
      return orderParams.orderIndex === 'ASC' ? (tagA.isActive ? -1 : 1) : (tagB.isActive ? -1 : 1);
    }
    if (orderParams.orderBy === TAGS_ORDER_TYPES.IS_IMAGE) {
      const imageUrlName = getImageTypeName(selectedTagsContentType);

      const imageUrlA = imageUrlName ? tagA[imageUrlName] : tagA.imageUrl ? tagA.imageUrl : tagA.highlightImageUrl ? tagA.highlightImageUrl : tagA.iconImageUrl;
      const imageUrlB = imageUrlName? tagB[imageUrlName] : tagB.imageUrl ? tagB.imageUrl : tagB.highlightImageUrl ? tagB.highlightImageUrl : tagB.iconImageUrl;

      return orderParams.orderIndex === 'ASC' ? (imageUrlA ? -1 : 1) : (imageUrlB ? -1 : 1);
    }
    if (a < b) {
      return orderParams.orderIndex === 'ASC' ? -1 : 1;
    }
    if (a > b) {
      return orderParams.orderIndex === 'DESC' ? -1 : 1;
    }
    return 0;
  });

  return filteredTags;
};
