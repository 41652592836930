import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Switcher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive
        }
    }

    switch = debounce(200, () => this.props.switch(this.state.isActive))

    componentWillUpdate (nextProps, nextState, nextContext) {
        if(this.props.isActive !== nextProps.isActive) {
            this.setState({isActive: nextProps.isActive})
        }
    }

    handleChangeSwitch = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.switch());
    }

    render () {
        return (
            <div style={{display: "flex"}} className={'switcher'}>
                <input type="checkbox" id="switch"
                       className={"switcher-input"}
                       disabled={this.props.isDisabled}
                       defaultChecked={this.state.isActive}
                       checked={this.state.isActive}
                       onChange={this.handleChangeSwitch}
                />
                <label className={"switcher-label"} htmlFor="switch"/>
            </div>
        )
    }
}