import React, { Component } from 'react'
import { ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';
import {Switcher} from "./Switcher";
import {getStyle} from '../../utils/StyleUtils';


export class DeleteAttention extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActiveSwitcher: false
        }
    }

    switch = () => {
        this.setState({isActiveSwitcher: !this.state.isActiveSwitcher})
    }

    renderBody = () => {

        return <div className={"attention-body"}>
            <AttentionIcon/>
            <div className={"fltr-attention-text"} style={{marginTop: "12px"}}>
                Are you sure ?
            </div>
            <div className={getStyle("fltr-attention-description-text")} style={{marginTop: "25px"}}>
                Your will not be able to recover this <b>{this.props.description}</b>
            </div>
        </div>
    }

    renderFooter = () => {

        return <div className={"attention-footer"}>
            {this.props.isSwitcher ? <div style={{display: "flex", marginRight: "auto", alignItems: "center"}}>
                <div className={"fltr-switcher-text"} style={{marginRight: "13px"}}>{this.props.switchText}</div>
                <Switcher isActive={false} switch={() =>this.switch()}/>
            </div> : null
            }


            <button className={"fltr-second-btn"} onClick={()=>{this.props.cancel()}}>
                Cancel
            </button>

            <button className={"fltr-delete-btn"} style={{
                marginLeft: "25px",
            }}
                    onClick={() => {
                        this.props.onDelete(this.state.isActiveSwitcher)
                    }}
            >
                Yes, delete it!
            </button>
        </div>
    }

    render () {
        return (
            <div className={getStyle("attention-container")}>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }
}