import React from 'react';
import MDSpinner from 'react-md-spinner';
import momentZ from 'moment-timezone';
import {connect} from 'react-redux';
import {Today} from './Today';
import {bindActionCreators} from 'redux';
import {Droppable} from 'react-beautiful-dnd';
import {selectPack} from '../../actions/packs';
import {ComponentType, typeModal} from '../../actions/general';
import AddHeader from '../general/AddHeader';

const TodayList = ({isLoadingPacks, packs, elements,
  isLoadingElements, selectPack, selectedPackIds}) => {
  if (isLoadingPacks || isLoadingElements) {
    return (
      <div className='spinner-container' style={{marginTop: '212px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  const groupedComponents = {};

  elements.forEach((element) => {
    if (element.todayDate && momentZ(element.todayDate).isAfter(momentZ().subtract(7, 'days'))) {
      element.componentType = ComponentType.ELEMENT;
      const keyGroupedComponent = momentZ(element.todayDate).format('YYYY-MM-DD');
      if (!groupedComponents[keyGroupedComponent]) {
        groupedComponents[keyGroupedComponent] = [];
      }
      groupedComponents[keyGroupedComponent].push(element);
    }
  });

  packs.forEach((pack) => {
    if (pack.todayDate && momentZ(pack.todayDate).isAfter(momentZ().subtract(7, 'days'))) {
      pack.componentType = ComponentType.PACK;
      const keyGroupedComponent = momentZ(pack.todayDate).format('YYYY-MM-DD');
      if (!groupedComponents[keyGroupedComponent]) {
        groupedComponents[keyGroupedComponent] = [];
      }
      groupedComponents[keyGroupedComponent].push(pack);
    }
  });

  const sortedGroupedComponents = Object.keys(groupedComponents).sort((a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }).map((groupedComponentKey) => {
    return {[groupedComponentKey]: groupedComponents[groupedComponentKey]};
  });
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <AddHeader
        title={``}
        modalType={typeModal.ELEMENT_ADD}
        meta={{
          packContentType: 'Preset',
          todayDate: sortedGroupedComponents.length !== 0 ? momentZ(Object.values(sortedGroupedComponents[0])[0][0].todayDate).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.000Z') : undefined,
          isFree: true,
          isActive: true,
          isToday: true,
        }}
      />
      <Droppable droppableId={'todayList'} type={ComponentType.PACK} isDropDisabled={true}>
        {(provided, snapshot) => (
          <div className={'section-container'}
            ref={provided.innerRef}
          >
            {
              sortedGroupedComponents.map((groupedComponent, index) => {
                const groupedComponentKey = Object.keys(groupedComponent)[0];
                return <Today
                  key={groupedComponentKey}
                  style={{marginTop: (index > 0 ? '15px' : undefined)}}
                  todayDate={groupedComponentKey}
                  components={groupedComponent[groupedComponentKey]}
                  elements={elements}
                  selectPack={selectPack}
                  selectedPackIds={selectedPackIds}
                />;
              })
            }
          </div>
        )}
      </Droppable>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingPacks: state.packs.isLoadingPacks,
  selectedLang: state.appState.selectedLang,
  elements: state.elements.elements,
  isLoadingElements: state.elements.isLoadingElements,
  packs: state.packs.packs,
  selectedPackIds: state.packs.selectedPackIds,
});

const mapDispatchToProps = (dispatch) => ({
  selectPack: bindActionCreators(selectPack, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TodayList);
