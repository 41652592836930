import React, {useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import update from 'immutability-helper';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Droppable} from 'react-beautiful-dnd';
import useGlobalDndState, {sourceDndDroppable, typeDndAction} from '../general/Dnd/DndState';
import {wrapId} from '../../utils/DragAndDropUtils';
import {ComponentType} from '../../actions/general';
import deepEqual from 'deep-equal';
import Tag from './Tag';
import {moveTag, updateTag} from '../../actions/tags';

function tagsListEqual(prev, next) {
  return deepEqual(prev, next);
}

class InnerList extends React.PureComponent {
  render() {
    return this.props.tags.map((tag, index) => {
      return <Tag
        key={tag.id + this.props.droppableId}
        arrayIndex={index}
        isDragging={this.props.isDragging}
        tag={tag}
        id={tag.id}
        draggableId={wrapId(tag.id, this.props.droppableId)}
        editTag={null}
        tags={this.props.tags}
        selectedContentTypeId={this.props.selectedContentTypeId}
        isBlockDnD={false}
      />;
    });
  }
}

const LibraryTagsList = ({
  tags, isLoadingTags, moveTag, selectedContentTypeId,
}) => {
  const droppableId = sourceDndDroppable.ListTags;
  const [localTags, setTags] = useState(tags);
  const [isDragging, setIsDragging] = useState(false);

  const [dndState] = useGlobalDndState(droppableId);

  useEffect(() => {
    if (dndState) {
      if (dndState.action === typeDndAction.MOVE) {
        moveDnd(dndState.draggableId, dndState.toIndex);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.ADD) {
        // addDnd(dndState.draggableId, dndState.toIndex);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REMOVE_LOCAL) {
        // removeLocalDnd(dndState.draggableId);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REPLACE) {
        // replaceDnd(dndState.draggableId, dndState.toIndex, Number(dndState.sourceDroppableId));
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REMOVE) {
        // removeDnd(dndState.draggableId);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.START_DRAGGING) {
        setIsDragging(true);
      }
      if (dndState.action === typeDndAction.END_DRAGGING) {
        setIsDragging(false);
      }
    }
  }, [dndState]);

  useEffect(() => {
    setTags(tags);
  }, [tags]);

  const moveDnd = (id, atIndex) => {
    const {tag, index} = findTag(id);
    const newLocalTags = update(localTags, {
      $splice: [[index, 1], [atIndex, 0, tag]],
    });
    setTags(newLocalTags);

    moveTag(
        newLocalTags.map((tag, index) => {
          return {
            entityId: tag.id,
            orderIndex: index,
          };
        }),
    );
  };

  const findTag = (id) => {
    const tag = localTags.filter((c) => c.id === id)[0];
    return {
      tag,
      index: localTags.indexOf(tag),
    };
  };

  if (isLoadingTags) {
    return (
      <div className='spinner-container' style={{height: '424px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <Droppable droppableId={droppableId} type={ComponentType.TAG}>
      {(provided) => (
        <div className='pack-list'
          ref={provided.innerRef}
        >
          <InnerList
            tags={localTags}
            droppableId={droppableId}
            editTag={null}
            isDragging={isDragging}
            selectedContentTypeId={selectedContentTypeId}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const mapStateToProps = (state) => ({
  isLoadingTags: state.tags.isLoadingTags,
});

const mapDispatchToProps = (dispatch) => ({
  moveTag: bindActionCreators(moveTag, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LibraryTagsList, tagsListEqual));
