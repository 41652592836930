import {batch} from 'react-redux';

import {
  REMOVE_ELEMENT_FROM_PACKS,
} from './packs';
import {IS_UPDATING_CONTENT, SET_IS_BLOCK_PUBLISH, SET_IS_RELOAD_FEED} from './general';
import {createRequest} from '../utils/HttpRequestUtills';

export const SAVE_ELEMENTS = 'SAVE_ELEMENTS';
export const ADD_ELEMENT = 'ADD_ELEMENT';

export const MOVE_ELEMENTS_IN_PACK = 'MOVE_ELEMENTS_IN_PACK';
export const ADD_ELEMENT_TO_PACK = 'ADD_ELEMENT_TO_PACK';
export const REPLACE_ELEMENTS_IN_PACKS = 'REPLACE_ELEMENTS_IN_PACKS';
export const REMOVE_ELEMENT_FROM_PACK = 'REMOVE_ELEMENT_FROM_PACK';

export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
export const SELECT_ELEMENT = 'SELECT_ELEMENT';
export const SET_EDIT_ELEMENT = 'SET_EDIT_ELEMENT';
export const SET_DELETE_ELEMENT = 'SET_DELETE_ELEMENT';
export const UPDATE_ELEMENT_IN_PACK = 'UPDATE_ELEMENT_IN_PACK';
export const UPDATE_RELATION_ELEMENT_TO_PACK = 'UPDATE_RELATION_ELEMENT_TO_PACK';
export const UPDATE_ELEMENT_TAGS = 'UPDATE_ELEMENT_TAGS';

export const UPDATE_ORDERS_INDEXES_ELEMENTS_IN_PACK = 'UPDATE_ORDERS_INDEXES_ELEMENTS_IN_PACK';
export const IS_LOADING_ELEMENTS = 'IS_LOADING_ELEMENTS';

export const addElement = (element) => (dispatch) => {
  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });
  dispatch({
    type: IS_LOADING_ELEMENTS,
    isLoading: true,
  });

  const onload = () => {
    dispatch({type: SET_IS_RELOAD_FEED, isReload: true});
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
  };
  const xhr = createRequest(dispatch, 'POST', '/element', onload);

  xhr.send(JSON.stringify(element));
};

export const addElementToPack = (
    orderIndexes,
    element,
    toIndex,
    toPackId,
    newRelation,
    replaceUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: ADD_ELEMENT_TO_PACK,
    toPackId,
    toIndex,
    element,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/element-to-packs/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes, newRelation, replaceUUID}));
};

export const replaceElementFromPackToPack = (
    orderIndexes,
    element,
    fromIndex,
    toIndex,
    fromPackId,
    toPackId,
    newRelation,
    oldUUID,
    replaceUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: REPLACE_ELEMENTS_IN_PACKS,
    toPackId,
    fromPackId,
    toIndex,
    element,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/element-to-packs/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes, newRelation, replaceUUID, oldUUID}));
};

export const moveElementInPack = (
    orderIndexes,
    element,
    fromIndex,
    toIndex,
    toPackId,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: MOVE_ELEMENTS_IN_PACK,
    packId: toPackId,
    fromIndex,
    toIndex,
    element,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/element-to-packs/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes}));
};

export const deleteElement = (elementId) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({type: REMOVE_ELEMENT_FROM_PACKS, elementId});
  dispatch({type: DELETE_ELEMENT, elementId, elements: getState().elements.elements});

  const onload = () => {};

  const xhr = createRequest(dispatch, 'DELETE', '/element/' + elementId, onload);

  xhr.send();
};

export const removeElementFromPack = (
    element,
    fromPackId,
    oldUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: REMOVE_ELEMENT_FROM_PACK,
    fromPackId,
    element,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/element-to-packs/order-indexes', onload);

  xhr.send(JSON.stringify({oldUUID}));
};

export const updateElement = (element) => (dispatch, getState) => {
  const elements = getState().elements.elements;
  batch(() => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: true,
    });
    dispatch({
      type: UPDATE_ELEMENT_TAGS,
      newElement: element,
      oldElement: elements.find((oldElement) => {
        return oldElement.id === element.id;
      }),
      elements,
    });

    dispatch({type: UPDATE_ELEMENT, element: {...element}});
  });

  const onload = () => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
  };
  const xhr = createRequest(dispatch, 'PATCH', '/element', onload);

  xhr.send(JSON.stringify(element));
};

export const setEditElement = (element) => (dispatch, getState) => {
  if (!element) {
    dispatch({
      type: SET_EDIT_ELEMENT,
      pack: element,
    });
  } else {
    const elementsInPacks = getState().elements.elementsInPacks;
    const elementToPacks = [];

    Object.keys(elementsInPacks).forEach((keyPackId) => {
      elementsInPacks[keyPackId].forEach((elementInPack, index) => {
        if (elementInPack.id === element.id) {
          elementToPacks.push({
            packId: Number(keyPackId),
            elementId: element.id,
            order: index,
            uuid: elementInPack.uuidRelation,
          });
        }
      });
    });
    dispatch({
      type: SET_EDIT_ELEMENT,
      element: {...element, ...{elementToPacks}},
    });
  }
};

export const setDeleteElement = (element) => (dispatch) => {
  dispatch({
    type: SET_DELETE_ELEMENT,
    element,
  });
};
