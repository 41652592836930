import React from 'react';

export const typeDndAction = {
  MOVE: 'MOVE',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  REMOVE_LOCAL: 'REMOVE_LOCAL',
  REPLACE: 'REPLACE',
  START_DRAGGING: 'START_DRAGGING',
  END_DRAGGING: 'END_DRAGGING',
};

export const sourceDndDroppable = {
  ListPacks: 'ListPacks',
  ListElements: 'ListElements',
  ListGroups: 'ListGroups',
  ListTags: 'ListTags',
};

function setState(newState) {
  this.state = {...this.state, ...newState};
  this.listeners.forEach((listener) => {
    if (listener.id) {
      listener.listener(this.state[listener.id]);
    } else {
      listener.listener(this.state);
    }
  });
}

function useCustom(React, droppableId) {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    this.listeners.push({listener: newListener, id: droppableId});
    if (droppableId) {
      this.state[droppableId] = null;
    }

    return () => {
      this.listeners = this.listeners.filter((listener) => listener.id !== droppableId);
      this.state[droppableId] = undefined;
    };
  }, []);

  if (droppableId) {
    return [this.state[droppableId], this.actions];
  }

  return [this.state, this.actions];
}

function associateActions(store, actions) {
  const associatedActions = {};
  Object.keys(actions).forEach((key) => {
    if (typeof actions[key] === 'function') {
      associatedActions[key] = actions[key].bind(null, store);
    }
    if (typeof actions[key] === 'object') {
      associatedActions[key] = associateActions(store, actions[key]);
    }
  });
  return associatedActions;
}

const changeDroppableState = (store, droppableId, value) => {
  const newState = {...store.state};
  newState[droppableId] = value;
  store.setState(newState);
};

const useDndState = (React, initialState, actions) => {
  const store = {state: initialState, listeners: []};
  store.setState = setState.bind(store);
  store.actions = associateActions(store, actions);
  return useCustom.bind(store, React);
};


const initialState = {};

const useGlobalDndState = useDndState(React, initialState, {changeDroppableState});

export default useGlobalDndState;
