import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'
import ReactDOM from 'react-dom';

export class Input extends Component {

    constructor(props) {
        super(props)
        this.state = {inputValue: this.props.value}
    }

    handleChange = debounce(
        this.props.debounce ? this.props.debounce : 200,
        () => this.props.handleChange(this.state.inputValue)
    )

    handleChangeInput = (value) => {
        const {excludeCharacters = []} = this.props
        excludeCharacters.forEach(excludeCharacter => {
            value = value.replace(excludeCharacter, '');
        })

        this.setState({inputValue: value}, () => this.handleChange())
    }

    componentWillUpdate (nextProps, nextState, nextContext) {
        if(this.props.value !== nextProps.value) {
            this.setState({inputValue: nextProps.value})
        }
    }

    componentDidMount() {
        this.focusDiv();
    }
    focusDiv = () => {
        if(this.props.isFocus){
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(this.refs.theDiv)
                if(node && node.focus){
                    node.focus()
                }
            }, 100);
        }
    }

    render () {
        return (
            <input type="text"
                   value={this.state.inputValue}
                   onChange={(event) => (
                       this.handleChangeInput(event.target.value)
                   )}
                   ref={"theDiv"}
                   style={this.props.style}
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   className={this.props.className ? this.props.className : "fltr-input"}/>
        )
    }
}