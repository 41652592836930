import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {BrowserRouter, Route, useHistory, useLocation} from 'react-router-dom';
import thunk from 'redux-thunk';
import websocket from './middlewares/websockets';
import reducer from './reducers';
import {withAuthenticator} from './components/authorization';
import Head from './components/general/Head';
import Library from './components/library';
import Today from './components/today';
import Premium from './components/premium';
import {isMobile} from 'react-device-detect';

import momentZ from 'moment-timezone';
import Modals from './components/general/Modals';
import {withDnd} from './components/general/Dnd/WithDnd';
import Requests from './components/requests';
import {AdminType} from './reducers/auth';
import {getStyle} from './utils/StyleUtils';
import Tags from './components/tags';
import Settings from './components/settings';
import {getAdmin, getAdminIamActions} from './utils/AuthUtill';
import {compareString} from './utils/ArrayUtils';
import {fltrMidelware} from './middlewares/FltrMidelware';
import internetStatusMidelware from './middlewares/InternetStatusMidelware';
import './resources/sass/index.scss'

momentZ.tz.setDefault('UTC');

const routsTemplates = {
  'FLTR_REQUESTS_MANAGER': [
    {path: '/requests', component: Requests, order: 4},
    {path: '/requests/:requestId', component: Requests, order: 4},
  ],
  'default': [
    {path: '/', component: Library, order: 0},
    {path: '/today', component: Today, order: 1},
    {path: '/premium', component: Premium, order: 2},
    {path: '/requests/:requestId', component: Requests, order: 3},
    {path: '/requests', component: Requests, order: 4},
    {path: '/tags', component: Tags, order: 5},
    {path: '/settings', component: Settings, order: 6},
  ],
  'webUI:AccessFltrRequests': [
    {path: '/requests', component: Requests, order: 4},
    {path: '/requests/:requestId', component: Requests, order: 4},
  ],
  'webUI:AccessIndividualFltrRequests': [
    {path: '/requests', component: Requests, order: 4},
    {path: '/requests/:requestId', component: Requests, order: 4},
  ],
  'webUI:AccessFltrFeed': [
    {path: '/', component: Library, order: 0},
    {path: '/today', component: Today, order: 1},
    {path: '/premium', component: Premium, order: 2},
  ],
  'webUI:AccessFltrTags': [
    {path: '/tags', component: Tags, order: 5},
  ],
  'webUI:AccessFltrConfig': [
    {path: '/settings', component: Settings, order: 5},
  ],
};

const HandlerLocation = ({admin, iamActions, routs}) => {
  const history = useHistory();
  const location = useLocation();

  if (isMobile && location.pathname === '/') {
    // eslint-disable-next-line max-len
    if (iamActions.indexOf('webUI:AccessFltrFeed') === -1 && (admin.adminType === AdminType.FLTR_REQUESTS_MANAGER || iamActions.indexOf('webUI:AccessIndividualFltrRequests') !== -1 || iamActions.indexOf('webUI:AccessFltrRequests') !== -1)) {
      history.push('/requests');
    }
  } else if (routs.length !== 0 && location.pathname === '/' && routs[0].path !== '/') {
    history.push(routs[0].path);
  }
  return null;
};

const WithProvider = ({}) => {
  const store = createStore(
      reducer,
      composeWithDevTools(applyMiddleware(thunk, internetStatusMidelware, websocket, fltrMidelware)),
  );
  const admin = getAdmin();
  const iamActions = getAdminIamActions();

  let routs;

  if (iamActions.length !== 0) {
    const routsSet = {};

    iamActions.forEach((action) => {
      if (action.includes('webUI')) {
        const tempRouts = routsTemplates[action];
        if (tempRouts) {
          tempRouts.forEach((route) => {
            routsSet[route.path] = route;
          });
        }
      }
    });
    routs = Object.values(routsSet).sort(compareString('order'));
  } else {
    routs = admin && (admin.adminType === AdminType.FLTR_REQUESTS_MANAGER) ?
        routsTemplates[AdminType.FLTR_REQUESTS_MANAGER] : routsTemplates['default'];
  }

  return <Provider store={store}>
    <BrowserRouter>
      <div className="app-container">
        <Modals/>
        <Head/>
        <HandlerLocation
          admin={admin}
          iamActions={iamActions}
          routs={routs}
        />
        <div className={getStyle('body-container')}>
          {
            routs.map((rout) => {
              // eslint-disable-next-line react/jsx-key
              return <Route exact path={rout.path} component={rout.component}/>;
            })
          }
        </div>
      </div>
    </BrowserRouter>
  </Provider>;
};

export default withDnd(withAuthenticator(WithProvider));
