import React, {useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import update from 'immutability-helper';
import Pack from './Pack';
import {bindActionCreators} from 'redux';
import {
  addPackToGroup,
  movePackInGroup, removePackFromGroup,
  replacePackFromGroupToGroup,
  selectPack,
  updatePack,
} from '../../actions/packs';
import {connect} from 'react-redux';
import {Droppable} from 'react-beautiful-dnd';
import useGlobalDndState, {sourceDndDroppable, typeDndAction} from '../general/Dnd/DndState';
import {wrapId} from '../../utils/DragAndDropUtils';
import {ComponentType} from '../../actions/general';
import uuid from 'uuid/v4';
import deepEqual from 'deep-equal';

export const PacksListType = {
  ONLY_PACKS: 'ONLY_PACKS',
  PACKS_IN_GROUP: 'PACKS_IN_GROUP',
  PACKS_IN_TODAY: 'PACKS_IN_TODAY',
  PACKS_IN_PREMIUM: 'PACKS_IN_PREMIUM',
};

function packsListEqual(prev, next) {
  return deepEqual(prev, next);
}

class InnerList extends React.PureComponent {
  render() {
    return this.props.packs.map((pack, index) => {
      return <Pack
        key={pack.id + this.props.droppableId}
        arrayIndex={index}
        isDragging={this.props.isDragging}
        pack={pack}
        id={pack.id}
        draggableId={wrapId(pack.id, this.props.droppableId)}
        isSelected={this.props.selectedPackIds[this.props.packsListType].indexOf(pack.id + '_' + this.props.externalId) !== -1}
        selectPack={this.props.selectPack}
        editPack={null}
        externalId={this.props.externalId}
        packListType={this.props.packsListType}
        elements={this.props.elements}
        isBlockDnD={this.props.packsListType !== PacksListType.PACKS_IN_GROUP}
        groupId={this.props.groupId}
      />;
    });
  }
}

const PacksList = ({packs, isLoadingPacks, selectedLang, selectPack, packsInGroups, elementsInPacks,
  packsListType, externalId = '', movePackInGroup, addPackToGroup,
  selectedPackIds, groupId, packsAll, removePackFromGroup, replacePackFromGroupToGroup,
}) => {
  const droppableId = groupId ? String(groupId) : sourceDndDroppable.ListPacks;
  const [localPacks, setPacks] = useState(packs);
  const [isDragging, setIsDragging] = useState(false);

  const [dndState] = useGlobalDndState(droppableId);

  useEffect(() => {
    if (dndState) {
      if (dndState.action === typeDndAction.MOVE) {
        moveDnd(dndState.draggableId, dndState.toIndex);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.ADD) {
        addDnd(dndState.draggableId, dndState.toIndex);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REMOVE_LOCAL) {
        removeLocalDnd(dndState.draggableId);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REPLACE) {
        replaceDnd(dndState.draggableId, dndState.toIndex, Number(dndState.sourceDroppableId));
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.REMOVE) {
        removeDnd(dndState.draggableId);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.START_DRAGGING) {
        setIsDragging(true);
      }
      if (dndState.action === typeDndAction.END_DRAGGING) {
        setIsDragging(false);
      }
    }
  }, [dndState]);

  useEffect(() => {
    setPacks(packs);
  }, [packs]);

  const moveDnd = (id, atIndex) => {
    const {pack, index} = findPack(id);
    const newLocalPacks = update(localPacks, {
      $splice: [[index, 1], [atIndex, 0, pack]],
    });

    movePackInGroup(
        newLocalPacks.map((pack, index) => {
          return {
            entityId: pack.uuidRelation,
            entityIdOld: pack.id,
            orderIndex: index,
          };
        }),
        pack,
        index,
        atIndex,
        groupId,
    );

    setPacks(newLocalPacks);
  };

  const replaceDnd = (id, toIndex, fromGroupId) => {
    const pack = packsInGroups[fromGroupId].filter((c) => c.id === id)[0];
    const fromIndex = packsInGroups[fromGroupId].indexOf(pack);
    const oldUUID = pack.uuidRelation;
    pack.uuidRelation = uuid();

    const localPack = localPacks.find((localPack) => localPack.id === pack.id);
    const newLocalPacks = localPacks.filter((localPack) => localPack.id !== pack.id);
    newLocalPacks.splice(toIndex, 0, pack);

    replacePackFromGroupToGroup(
        newLocalPacks.map((pack, index) => {
          return {
            entityId: pack.uuidRelation,
            entityIdOld: pack.id,
            orderIndex: index,
          };
        }),
        pack,
        fromIndex,
        toIndex,
        fromGroupId,
        groupId,
        {
          toEntityId: groupId,
          fromEntityId: pack.id,
          orderIndex: toIndex,
          uuid: pack.uuidRelation,
        },
        oldUUID,
            localPack ? localPack.uuidRelation : undefined,
    );

    setPacks(newLocalPacks);
  };

  const removeDnd = (id) => {
    const {pack} = findPack(id);

    const newLocalPacks = localPacks.filter((localPack) => localPack.id !== pack.id);
    setPacks(newLocalPacks);

    removePackFromGroup(pack, groupId, pack.uuidRelation);
  };

  const removeLocalDnd = (id) => {
    const {pack} = findPack(id);

    const newLocalPacks = localPacks.filter((localPack) => localPack.id !== pack.id);
    setPacks(newLocalPacks);
  };

  const addDnd = (id, atIndex) => {
    const pack = packsAll.filter((c) => c.id === id)[0];
    const newPack = {...pack};
    newPack.uuidRelation = uuid();

    const localPack = localPacks.find((localPack) => localPack.id === newPack.id);
    const newLocalPacks = localPacks.filter((localPack) => localPack.id !== newPack.id);
    newLocalPacks.splice(atIndex, 0, newPack);
    setPacks(newLocalPacks);

    addPackToGroup(
        newLocalPacks.map((pack, index) => {
          return {
            entityId: pack.uuidRelation,
            entityIdOld: pack.id,
            orderIndex: index,
          };
        }),
        newPack,
        atIndex,
        groupId,
        {
          toEntityId: groupId,
          fromEntityId: newPack.id,
          orderIndex: atIndex,
          uuid: newPack.uuidRelation,
        },
            localPack ? localPack.uuidRelation : undefined,
    );
  };

  const findPack = (id) => {
    const pack = localPacks.filter((c) => c.id === id)[0];

    return {
      pack,
      index: localPacks.indexOf(pack),
    };
  };

  if (isLoadingPacks) {
    return (
      <div className='spinner-container' style={{height: '424px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <Droppable droppableId={droppableId} type={ComponentType.PACK} isDropDisabled={!groupId}>
      {(provided) => (

        <div className={packsListType === PacksListType.PACKS_IN_GROUP ? 'pack-list-in-group' : 'pack-list'}
          ref={provided.innerRef}
        >

          <InnerList
            packs={localPacks}
            droppableId={droppableId}
            selectedPackIds={selectedPackIds}
            packsListType={packsListType}
            externalId={externalId}
            selectPack={selectPack}
            editPack={null}
            groupId={groupId}
            isDragging={isDragging}
          />
          {provided.placeholder}

        </div>
      )}
    </Droppable>
  );
};

const mapStateToProps = (state) => ({
  isLoadingPacks: state.packs.isLoadingPacks,
  selectedLang: state.appState.selectedLang,
  selectedPackIds: state.packs.selectedPackIds,
  packsInGroups: state.packs.packsInGroups,
  packsAll: state.packs.packs,
});

const mapDispatchToProps = (dispatch) => ({
  movePackInGroup: bindActionCreators(movePackInGroup, dispatch),
  replacePackFromGroupToGroup: bindActionCreators(replacePackFromGroupToGroup, dispatch),
  selectPack: bindActionCreators(selectPack, dispatch),
  updatePack: bindActionCreators(updatePack, dispatch),
  addPackToGroup: bindActionCreators(addPackToGroup, dispatch),
  removePackFromGroup: bindActionCreators(removePackFromGroup, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PacksList, packsListEqual));
