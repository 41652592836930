import React, {useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import update from 'immutability-helper';
import {Group} from './Group';
import useGlobalDndState, {sourceDndDroppable, typeDndAction} from '../general/Dnd/DndState';
import {ComponentType} from '../../actions/general';
import {Droppable} from 'react-beautiful-dnd';
import {wrapId} from '../../utils/DragAndDropUtils';

class InnerList extends React.PureComponent {
  render() {
    return this.props.groups.map((group, index) => {
      return <Group
        style={{marginTop: (index > 0 ? '15px' : undefined), height: this.props.isDragging ? '50px' : undefined}}
        key={group.id}
        group={group}
        arrayIndex={index}
        id={group.id}
        packs={this.props.packs[group.id] ? this.props.packs[group.id] : []}
        isSelected={false}
        isBlockDnD={false}
        isDraggingAny={this.props.isDragging}
        draggableId={wrapId(group.id, this.props.droppableId)}
      />;
    });
  }
}

export const GroupsList = ({groups, isLoadingGroups, packsInGroups, updateGroupsOrderIndexes}) => {
  const droppableId = sourceDndDroppable.ListGroups;

  const [dndState] = useGlobalDndState(droppableId);
  const [localGroups, setGroups] = useState(groups);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setGroups(groups);
  }, [groups]);

  useEffect(() => {
    if (dndState) {
      if (dndState.action === typeDndAction.MOVE) {
        moveDnd(dndState.draggableId, dndState.toIndex);
        setIsDragging(false);
      }
      if (dndState.action === typeDndAction.START_DRAGGING) {
        setIsDragging(true);
      }
    }
  }, [dndState]);

  const moveDnd = (id, atIndex) => {
    const {group, index} = findGroup(id);
    const newLocalGroups = update(localGroups, {
      $splice: [[index, 1], [atIndex, 0, group]],
    });

    updateGroupsOrderIndexes(
        newLocalGroups.map((group, index) => {
          return {
            entityId: group.id,
            orderIndex: index,
          };
        }),
    );

    setGroups(newLocalGroups);
  };

  if (isLoadingGroups) {
    return (
      <div className='spinner-container' style={{marginTop: '212px'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  const findGroup = (id) => {
    const group = localGroups.filter((c) => c.id === id)[0];
    return {
      group,
      index: localGroups.indexOf(group),
    };
  };

  return (
    <Droppable droppableId={droppableId} type={ComponentType.GROUP}>
      {(provided, snapshot) => (
        <div className={'group-list'}
          style={{paddingBottom: '50px'}}
          ref={provided.innerRef}
        >
          <InnerList
            groups={localGroups}
            packs={packsInGroups}
            isDragging={isDragging}
            droppableId={droppableId}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
