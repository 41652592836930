import {
  SAVE_CONTENT_TYPES,
  SELECT_CONTENT_TYPE,
  SELECT_LANG,
  SET_IS_RELOAD_FEED,
  SHOW_MODAL,
  typeModal,
  IS_PUBLISHED,
  SAVE_VERSION,
  SAVE_CLOUD_FEED,
  SAVE_FEED,
  SET_IS_BLOCK_PUBLISH,
  IS_UPDATING_CONTENT,
  SAVE_ADMINS, SET_IS_LOADING_ADMINS, SET_IS_LOADING_REMOTE_CONFIG, SAVE_REMOTE_CONFIG,
} from '../actions/general';
import momentZ from 'moment-timezone';
import deepEqual from 'deep-equal';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {SET_DELETE_PACK, SET_EDIT_PACK} from '../actions/packs';
import {SET_DELETE_ELEMENT, SET_EDIT_ELEMENT} from '../actions/elements';
import {SET_DELETE_GROUP, SET_EDIT_GROUP} from '../actions/groups';
import {SET_DELETE_TAG, SET_EDIT_TAG} from '../actions/tags';
import {
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECTING, WEBSOCKET_DISCONNECT,
  WEBSOCKET_ERROR,
  WEBSOCKET_OPEN,
  WebsocketAction, WebsocketServiceType,
} from '../middlewares/websockets/types';
import {SET_DELETE_REQUEST, SET_EDIT_REQUEST, SET_PROCESSED_REQUEST} from '../actions/requests';
import {arrayToObject} from '../utils/ArrayUtils';
import {StatusPublishFeed} from '../models/Feed';
import {INTERNET_CONNECTION} from '../middlewares/InternetStatusMidelware';

const getInintialState = () => ({
  isLogged: false,
  admins: [],
  modals: (initModalsState()),
  isReloadFeed: true,
  languages: {
    en: 'English',
    de: 'German',
    ru: 'Russian',
  },
  cloudFeed: null,
  feed: null,
  isPublishing: false,
  remoteConfig: null,
  contentTypes: [],
  selectedContentTypeId: getItem('selectedContentTypeId', 'Preset'),
  selectedLang: getItem('currentLang', 'en'),
  packForEdit: null,
  packForDelete: null,
  elementForEdit: null,
  elementForDelete: null,
  groupForEdit: null,
  groupForDelete: null,
  requestForDelete: null,
  requestForProcessed: null,
  tagForDelete: null,
  tagForEdit: null,
  version: null,
  isBlockPublish: true,
  isUpdatingContent: false,
  isConnectedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  isConnectingWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  isClosedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  isLoadingAdmins: false,
  isLoadingRemoteConfig: false,
  isActiveInternetConnection: true
});

export default function appState(state = getInintialState(), action) {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [action.modalType]: {isShow: action.isShow, meta: action.meta},
        }),
      });

    case SET_IS_RELOAD_FEED:
      return Object.assign({}, state, {
        isReloadFeed: action.isReload,
      });

    case SAVE_FEED: {
      return Object.assign({}, state, {
        feed: action.feed,
        version: action.feed.version,
        isBlockPublish: state.cloudFeed ? isBlockPublish(action.feed, state.cloudFeed) : true,
      });
    }

    case SELECT_LANG:
      saveItem(action.lang, 'currentLang');
      return Object.assign({}, state, {
        selectedLang: action.lang,
      });

    case SAVE_CONTENT_TYPES:
      return Object.assign({}, state, {
        contentTypes: action.contentTypes.filter((contentType) => {
          return ['Overlay', 'Font', 'Storie'].indexOf(contentType.id) === -1;
        }),
      });

    case WebsocketAction.FLTR_FEED_PUBLISH.name:
      return Object.assign({}, state, {
        version: action.data.message.version,
        isPublishing: false,
      });

    case SELECT_CONTENT_TYPE:
      saveItem(action.contentTypeId, 'selectedContentTypeId');
      return Object.assign({}, state, {
        selectedContentTypeId: action.contentTypeId,
      });

    case WebsocketAction.STATUS_FLTR_FEED_PUBLISH.name:
      return Object.assign({}, state, {
        isPublishing: action.data.message.status === StatusPublishFeed.PUBLISHING,
        isBlockPublish: action.data.message.status === StatusPublishFeed.PUBLISHING,
      });

    case SET_EDIT_PACK:
      return Object.assign({}, state, {
        packForEdit: action.pack,
        modals: Object.assign({}, state.modals, {
          [typeModal.PACK_EDIT]: {isShow: Boolean(action.pack), meta: {}},
        }),
      });

    case SET_DELETE_PACK:
      return Object.assign({}, state, {
        packForDelete: action.pack,
        modals: Object.assign({}, state.modals, {
          [typeModal.PACK_DELETE]: {isShow: Boolean(action.pack), meta: {}},
        }),
      });

    case SET_EDIT_ELEMENT:
      return Object.assign({}, state, {
        elementForEdit: action.element,
        modals: Object.assign({}, state.modals, {
          [typeModal.ELEMENT_EDIT]: {isShow: Boolean(action.element), meta: {}},
        }),
      });

    case WebsocketAction.REQUEST_IS_PROCESSED.name:
      const message = action.data;
      const request = message.message.request;

      return Object.assign({}, state, {
        requestForDelete: null,
        requestForProcessed: request,
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_EDIT]: {isShow: false, meta: {}},
          [typeModal.REQUEST_DELETE]: {isShow: false, meta: {}},
          [typeModal.REQUEST_IS_PROCESSED]: {isShow: true, meta: {}},
        }),
      });

    case SET_PROCESSED_REQUEST:
      return Object.assign({}, state, {
        requestForDelete: action.element,
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_IS_PROCESSED]: {isShow: Boolean(action.request), meta: {}},
        }),
      });

    case SET_DELETE_ELEMENT:
      return Object.assign({}, state, {
        elementForDelete: action.element,
        modals: Object.assign({}, state.modals, {
          [typeModal.ELEMENT_DELETE]: {isShow: Boolean(action.element), meta: {}},
        }),
      });

    case SET_EDIT_GROUP:
      return Object.assign({}, state, {
        groupForEdit: action.group,
        modals: Object.assign({}, state.modals, {
          [typeModal.GROUP_EDIT]: {isShow: Boolean(action.group), meta: {}},
        }),
      });

    case SET_DELETE_GROUP:
      return Object.assign({}, state, {
        groupForDelete: action.group,
        modals: Object.assign({}, state.modals, {
          [typeModal.GROUP_DELETE]: {isShow: Boolean(action.group), meta: {}},
        }),
      });

    case SET_DELETE_REQUEST:
      return Object.assign({}, state, {
        requestForDelete: action.request,
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_DELETE]: {isShow: Boolean(action.request), meta: {}},
        }),
      });

    case WebsocketAction.SAVE_FLTR_REQUEST.name:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_EDIT]: {isShow: Boolean(action.data.message), meta: {}},
        }),
      });

    case SET_EDIT_REQUEST:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_EDIT]: {isShow: Boolean(action.request), meta: {}},
        }),
      });

    case WebsocketAction.DELETE_REQUEST.name: {
      const message = action.data;
      const requestId = message.message.requestId;
      const isDeleteRequestForDelete = state.requestForDelete && state.requestForDelete.id === requestId;
      const isDeleteRequestForEdit = state.requestForEdit && state.requestForEdit.id === requestId;
      return Object.assign({}, state, {
        requestForDelete: isDeleteRequestForDelete ? null : state.requestForDelete,
        requestForEdit: isDeleteRequestForEdit ? null : state.requestForEdit,
        modals: Object.assign({}, state.modals, {
          [typeModal.REQUEST_EDIT]: {isShow: isDeleteRequestForEdit, meta: {}},
          [typeModal.REQUEST_DELETE]: {isShow: isDeleteRequestForDelete, meta: {}},
        }),
      });
    }

    case SET_DELETE_TAG:
      return Object.assign({}, state, {
        tagForDelete: action.tag,
        modals: Object.assign({}, state.modals, {
          [typeModal.TAG_DELETE]: {isShow: Boolean(action.tag), meta: {}},
        }),
      });

    case SET_EDIT_TAG:
      return Object.assign({}, state, {
        tagForEdit: action.tag,
        modals: Object.assign({}, state.modals, {
          [typeModal.TAG_EDIT]: {isShow: Boolean(action.tag), meta: {}},
        }),
      });

    case SAVE_CLOUD_FEED:
      return Object.assign({}, state, {
        cloudFeed: action.cloudFeed,
        isBlockPublish: state.feed ? isBlockPublish(state.feed, action.cloudFeed) : true,
      });

    case WEBSOCKET_DISCONNECT:
      return Object.assign({}, state, {
        isClosedWSS: arrayToObject(Object.values(WebsocketServiceType), action.isLogout),
      });

    case SET_IS_BLOCK_PUBLISH:
      return Object.assign({}, state, {
        isBlockPublish: action.isBlockPublish,
      });

    case IS_UPDATING_CONTENT:
      return Object.assign({}, state, {
        isUpdatingContent: action.isUpdating,
        isBlockPublish: action.isUpdating,
      });

    case WEBSOCKET_OPEN:
      return Object.assign({}, state, {
        isConnectedWSS: {...state.isConnectedWSS, ...{[action.serviceType]: true}},
        isConnectingWSS: {...state.isConnectingWSS, ...{[action.serviceType]: false}},
      });

    case WEBSOCKET_CLOSED:
      return Object.assign({}, state, {
        isConnectedWSS: {...state.isConnectedWSS, ...{[action.serviceType]: false}},
        isConnectingWSS: {...state.isConnectingWSS, ...{[action.serviceType]: false}},
      });

    case WEBSOCKET_CONNECTING:
      return Object.assign({}, state, {
        isConnectingWSS: {...state.isConnectingWSS, ...arrayToObject(action.serviceTypes, true)},
      });

    case WEBSOCKET_ERROR:
      return Object.assign({}, state, {
        modals: (initModalsState()),
        isConnectedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
        isConnectingWSS: arrayToObject(Object.values(WebsocketServiceType), false),
      });

    case SAVE_ADMINS: {
      return Object.assign({}, state, {
        admins: action.admins,
        isLoadingAdmins: false,
      });
    }

    case SET_IS_LOADING_ADMINS:
      return Object.assign({}, state, {
        isLoadingAdmins: action.isLoading,
      });

    case SET_IS_LOADING_REMOTE_CONFIG:
      return Object.assign({}, state, {
        isLoadingRemoteConfig: action.isLoading,
      });

    case SAVE_REMOTE_CONFIG:
      return Object.assign({}, state, {
        remoteConfig: action.remoteConfig,
        isLoadingRemoteConfig: false,
      });

    case WebsocketAction.ADMIN_UPDATE.name: {
      const message = action.data;
      const admin = message.message.admin;
      if (admin && admin.id === state.admin.id) {
        saveItem(admin, 'admin');
      }

      return Object.assign({}, state, {
        admin: admin && admin.id === state.admin.id ? admin : state.admin,
        admins: state.admins.map((existAdmin) => {
          return admin.id === existAdmin.id ? admin : existAdmin;
        }),
      });
    }

    case INTERNET_CONNECTION: {
      return Object.assign({}, state, {
        isActiveInternetConnection: action.isActive,
        isClosedWSS: arrayToObject(Object.values(WebsocketServiceType), !action.isActive),
        isConnectedWSS: arrayToObject(Object.values(WebsocketServiceType), action.isActive),
        isReloadFeed: action.isActive
      });
    }

    default:
      return state;
  }
}

const initModalsState = () => {
  const modalState = {};
  Object.keys(typeModal).forEach((keyModal) => {
    modalState[keyModal] = {isShow: false, meta: {}};
  });

  return modalState;
};

const isBlockPublish = (feed, feedCloud) => {
  const feedFiltered = JSON.parse(JSON.stringify(feed));
  feedFiltered.packs.forEach((pack) => {
    delete pack.fltrTagIds;
    delete pack.fltrTags;
    delete pack.description;
    delete pack.localization;
  });

  feedFiltered.elements.forEach((element) => {
    delete element.fltrTagIds;
    delete element.fltrTags;
    delete element.description;
    delete element.localization;
  });
  delete feedFiltered.tags;

  const feedCloudFiltered = JSON.parse(JSON.stringify(feedCloud));
  feedCloudFiltered.packs.forEach((pack) => {
    delete pack.description;
    delete pack.localization;
  });

  feedCloudFiltered.elements.forEach((element) => {
    delete element.description;
    delete element.localization;
  });

  let isBlock = deepEqual(feedFiltered, feedCloudFiltered);
  const entitiesNames = ['pack', 'groups', 'tags', 'elements'];
  const publishDate = momentZ(feedCloud.version.publishDate);

  for (const entityName in entitiesNames) {
    if (!isBlock) {
      break;
    }
    for (const entity in feed[entityName]) {
      if (momentZ(entity.updatedAt).isAfter(publishDate)) {
        isBlock = false;
        break;
      }
    }
  }

  return isBlock;
};
