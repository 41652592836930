import uuid from 'uuid/v4';
import update from 'immutability-helper';

import {
  SAVE_PACKS,
  IS_LOADING_PACKS,
  DELETE_PACK,
  UPDATE_PACK,
  SELECT_PACK,
  ADD_ELEMENT_TO_PACKS,
  REMOVE_ELEMENT_FROM_PACKS,
  REMOVE_GROUP_FROM_PACKS,
  UPDATE_RELATION_PACK_TO_GROUP,
  MOVE_PACKS_IN_GROUP,
  REPLACE_PACKS_IN_GROUPS, ADD_PACK_TO_GROUP, REMOVE_PACK_FROM_GROUP,
} from '../actions/packs';
import {PacksListType} from '../components/packs/PacksList';
import {
  UPDATE_ELEMENT_IN_PACK,
  UPDATE_RELATION_ELEMENT_TO_PACK,
} from '../actions/elements';
import {DELETE_GROUP} from '../actions/groups';
import {REMOVE_TAG_FROM_PACKS} from '../actions/tags';

const getInintialState = () => {
  return {
    stateId: uuid(),
    packs: [],
    isLoadingPacks: false,
    selectedPackIds: generateSelectedPackIds(),
    timeStampSavePacks: 0,
    timeStampAddPacks: 0,
    packsInGroups: {},
  };
};

const generateSelectedPackIds = () => {
  const selectedPackIds = {};
  Object.keys(PacksListType).forEach((key) => {
    selectedPackIds[key] = [];
  });
  return selectedPackIds;
};

export default function packs(state = getInintialState(), action) {
  switch (action.type) {
    case SAVE_PACKS:
      const timeStampSavePacks = action.requestTimeEpoch;
      const stateIdRequestedSavePacks = action.stateId;

      if (
        timeStampSavePacks > state.timeStampSavePacks &&
                stateIdRequestedSavePacks === state.stateId
      ) {
        const packsInGroups = {};

        action.groups.forEach((group) => {
          packsInGroups[group.id] = [];
        });

        action.packs.forEach((pack) => {
          pack.packToGroups.forEach((packToGroup) => {
            const copyPack = {...pack};
            copyPack.orderIndex = packToGroup.order;
            copyPack.uuidRelation = packToGroup.uuid;
            packsInGroups[packToGroup.groupId].push(copyPack);
          });
        });

        Object.keys(packsInGroups).forEach((key) => {
          packsInGroups[key] = packsInGroups[key].sort((a, b) => {
            return a.orderIndex - b.orderIndex;
          });
        });

        return Object.assign({}, state, {
          packs: action.packs,
          isLoadingPacks: false,
          timeStampSavePacks: timeStampSavePacks,
          packsInGroups: packsInGroups,
        });
      }
      return state;

    case ADD_PACK_TO_GROUP:
      const toPacksAdd = state.packsInGroups[action.toGroupId] ?
                [...state.packsInGroups[action.toGroupId]].filter((pack) => (pack.id !== action.pack.id)) :
                [];

      toPacksAdd.splice(action.toIndex, 0, action.pack);

      return Object.assign({}, state, {
        packsInGroups: Object.assign({}, state.packsInGroups, {
          [action.toGroupId]: toPacksAdd,
        }),
      });

    case MOVE_PACKS_IN_GROUP:
      const movedPacks = update(state.packsInGroups[action.groupId], {
        $splice: [[action.fromIndex, 1], [action.toIndex, 0, action.pack]],
      });

      return Object.assign({}, state, {
        packsInGroups: Object.assign({}, state.packsInGroups, {
          [action.groupId]: movedPacks,
        }),
      });

    case REPLACE_PACKS_IN_GROUPS:
      const toPacksReplaced = state.packsInGroups[action.toGroupId] ?
                [...state.packsInGroups[action.toGroupId]].filter((pack) => (pack.id !== action.pack.id)) :
                [];
      toPacksReplaced.splice(action.toIndex, 0, action.pack);

      const fromPacksReplaced = state.packsInGroups[action.fromGroupId]
          .filter((pack) => (pack.id !== action.pack.id));

      return Object.assign({}, state, {
        packsInGroups: Object.assign({}, state.packsInGroups, {
          [action.toGroupId]: toPacksReplaced,
          [action.fromGroupId]: fromPacksReplaced,
        }),
      });

    case REMOVE_PACK_FROM_GROUP:
      const fromPacksRemove = [...state.packsInGroups[action.fromGroupId]]
          .filter((pack) => (pack.id !== action.pack.id));

      return Object.assign({}, state, {
        packsInGroups: Object.assign({}, state.packsInGroups, {
          [action.fromGroupId]: fromPacksRemove,
        }),
      });

    case SELECT_PACK:
      const newSelectedPackIds = [...state.selectedPackIds[action.packsListType]];
      const indexInArray = newSelectedPackIds.indexOf(action.packId);
      if (indexInArray === -1) {
        newSelectedPackIds.push(action.packId);
      } else {
        newSelectedPackIds.splice(indexInArray, 1);
      }
      return Object.assign({}, state, {
        selectedPackIds: Object.assign({}, state.selectedPackIds, {
          [action.packsListType]: newSelectedPackIds,
        }),
      });

    case DELETE_GROUP:
      const packsInGroupsDeleteGroup = {...state.packsInGroups};
      delete packsInGroupsDeleteGroup[action.groupId];

      return Object.assign({}, state, {
        packsInGroups: packsInGroupsDeleteGroup,
      });

    case IS_LOADING_PACKS:
      return Object.assign({}, state, {
        isLoadingPacks: action.isLoading,
      });

    case DELETE_PACK:
      const packsInGroupsForDeletePack = {...state.packsInGroups};
      Object.keys(packsInGroupsForDeletePack)
          .forEach((packInGroupKey) => {
            packsInGroupsForDeletePack[packInGroupKey] = packsInGroupsForDeletePack[packInGroupKey]
                .filter((pack) => (pack.id !== action.packId));
          });

      return Object.assign({}, state, {
        packsInGroups: packsInGroupsForDeletePack,
        packs: state.packs.filter((pack) => {
          return pack.id !== action.packId;
        }),
      });

    case UPDATE_PACK:
      const isUpdateRelations = action.pack.isUpdateRelations;
      delete action.pack.isUpdateRelations;

      const packsInGroupsForUpdatePack = {...state.packsInGroups};
      Object.keys(packsInGroupsForUpdatePack)
          .forEach((packInGroupKey) => {
            let isContainPack = false;

            packsInGroupsForUpdatePack[packInGroupKey] = packsInGroupsForUpdatePack[packInGroupKey]
                .map((pack) => {
                  if (pack.id === action.pack.id) {
                    isContainPack = true;
                    const newPack = {...pack, ...action.pack};
                    newPack.uuidRelation = pack.uuidRelation;
                    return newPack;
                  }
                  return pack;
                });
            if (isUpdateRelations) {
              const packToGroup = action.pack.packToGroups
                  .find((packToGroup) => packToGroup.groupId === Number(packInGroupKey));
              if (!isContainPack && packToGroup) {
                packsInGroupsForUpdatePack[packInGroupKey]
                    .push({...action.pack, ...{uuidRelation: packToGroup.uuid}});
              }
              if (isContainPack && !packToGroup) {
                packsInGroupsForUpdatePack[packInGroupKey] = packsInGroupsForUpdatePack[packInGroupKey]
                    .filter((pack) => (pack.id !== action.pack.id));
              }
            }
          });

      return Object.assign({}, state, {
        packsInGroups: packsInGroupsForUpdatePack,
        packs: state.packs.map((pack) => {
          if (pack.id === action.pack.id) {
            return action.pack;
          }
          return pack;
        }),
      });

      // DEPRECATED
    case ADD_ELEMENT_TO_PACKS:
      const packsWithNewElement = state.packs
          .map((pack) => {
            const newElementToPacksFromElement = action.element.elementToPacks
                .find((elementToPack) => {
                  return pack.id === elementToPack.packId;
                });
            if (newElementToPacksFromElement) {
              const newElementToPacks = [...pack.elementToPacks];
              newElementToPacks.push(newElementToPacksFromElement);

              const newPack = {...pack};
              newPack.elementToPacks = newElementToPacks;
              newPack.elements = newPack.elements + 1;
              return newPack;
            }
            return pack;
          });

      return Object.assign({}, state, {
        packs: packsWithNewElement,
      });

    case REMOVE_ELEMENT_FROM_PACKS:
      const packsWithoutElement = state.packs
          .map((pack) => {
            const newPack = {...pack};
            newPack.elementToPacks = pack.elementToPacks.filter((elementToPack) => {
              return elementToPack.elementId !== action.elementId;
            });
            newPack.elements = newPack.elementToPacks.length;
            return newPack;
          });

      return Object.assign({}, state, {
        packs: packsWithoutElement,

      });

    case REMOVE_TAG_FROM_PACKS: {
      const packsInGroups = {...state.packsInGroups};
      Object.keys(packsInGroups)
          .forEach((packInGroupKey) => {
            packsInGroups[packInGroupKey] = packsInGroups[packInGroupKey]
                .map((pack) => {
                  const newPack = {...pack};
                  newPack.fltrTagIds = pack.fltrTagIds.filter((fltrTagId) => {
                    return fltrTagId !== action.tagId;
                  });
                  return newPack;
                });
          });

      const packsWithoutTag = state.packs
          .map((pack) => {
            const newPack = {...pack};
            newPack.fltrTagIds = pack.fltrTagIds.filter((fltrTagId) => {
              return fltrTagId !== action.tagId;
            });
            return newPack;
          });

      return Object.assign({}, state, {
        packs: packsWithoutTag,
        packsInGroups,
      });
    }

    case REMOVE_GROUP_FROM_PACKS:
      const packsWithoutGroup = state.packs
          .map((pack) => {
            const newPack = {...pack};
            newPack.packToGroups = pack.packToGroups.filter((packToGroups) => {
              return packToGroups.groupId !== action.groupId;
            });
            return newPack;
          });

      return Object.assign({}, state, {
        packs: packsWithoutGroup,
      });

    case UPDATE_RELATION_PACK_TO_GROUP:
      const packsUpdatedRelation = state.packs
          .map((pack) => {
            const newPack = {...pack};
            if (pack.id === action.newRelation.fromEntityId) {
              newPack.packToGroups = pack.packToGroups.filter((packToGroups) => {
                return packToGroups.uuid !== action.newRelation.oldUUID;
              });
              newPack.packToGroups.push({
                uuid: action.newRelation.uuid,
                packId: pack.id,
                groupId: action.newRelation.toEntityId,
                order: action.newRelation.orderIndex,
              });
            }

            return newPack;
          });

      return Object.assign({}, state, {
        packs: packsUpdatedRelation,
      });

    case UPDATE_RELATION_ELEMENT_TO_PACK:
      const packsUpdatedRelationElement = state.packs
          .map((pack) => {
            const newPack = {...pack};
            if (pack.id === action.newRelation.toEntityId) {
              newPack.elementToPacks.push({
                uuid: action.newRelation.uuid,
                elementId: action.newRelation.fromEntityId,
                packId: pack.id,
                order: action.newRelation.orderIndex,
              });
            }

            if (pack.id === action.fromPackId) {
              newPack.packToGroups = newPack.elementToPacks.filter((elementToPack) => {
                return elementToPack.packId !== action.newRelation.fromEntityId;
              });
            }

            return newPack;
          });

      return Object.assign({}, state, {
        packs: packsUpdatedRelationElement,
      });

    case UPDATE_ELEMENT_IN_PACK:
      return Object.assign({}, state, {
        packs: state.packs.map((pack) => {
          const isContainPack = action.element.elementToPacks.find((elementToPack) => {
            return elementToPack.packId === pack.id;
          });
          if (isContainPack) {
            const isGroupContainPack = pack.elementToPacks.find((elementToPack) => {
              return elementToPack.elementId === action.element.id;
            });
            if (!isGroupContainPack) {
              const newPack = {...pack};
              newPack.elementToPacks.push({elementToPackId: -1, packId: pack.id, elementId: action.element.id});
              return newPack;
            }
          } else {
            const isGroupContainPack = pack.elementToPacks.find((elementToPack) => {
              return elementToPack.elementId === action.element.id;
            });
            if (isGroupContainPack) {
              const newPack = {...pack};
              newPack.elementToPacks = newPack.elementToPacks.filter((elementToPack) => {
                return elementToPack.elementId !== action.element.id;
              });
              return newPack;
            }
          }

          return pack;
        }),
      });

    default: return state;
  }
}
