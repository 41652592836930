import React from 'react';
import TagsSection from './TagsSection';
import {
  selectTagsContentType,
  TAGS_CONTENT_TYPES,
} from '../../actions/tags';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const Tags = ({selectedTagsContentType, selectTagsContentType}) => {
  return (
    <div style={{height: '100%', overflow: 'overlay', marginBottom: '100px'}}>
      <div className={'settings-container'}>
        <div className={'tags-list-filter-container'}>
          {
            [
              TAGS_CONTENT_TYPES.ALL,
              TAGS_CONTENT_TYPES.PRESET,
              TAGS_CONTENT_TYPES.HIGHLIGHT,
              TAGS_CONTENT_TYPES.IG_ICON,
            ].map((tagContentType) => {
              return <div key={tagContentType.id} className={'tags-list-filter-btn' + (selectedTagsContentType.id === tagContentType.id ? '' : '-disabled')}
                onClick={() => {
                  selectTagsContentType(tagContentType);
                }}
              >
                {tagContentType.name.toUpperCase()}
              </div>;
            })
          }
        </div>
        <TagsSection/>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  selectedTagsContentType: state.tags.selectedTagsContentType,
});

const mapDispatchToProps = (dispatch) => ({
  selectTagsContentType: bindActionCreators(selectTagsContentType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
