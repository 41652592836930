import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {logout} from '../../actions/auth'
import {NavLink} from "react-router-dom";

import { ReactComponent as LibraryIcon} from '../../resources/imgs/library-icon.svg';
import { ReactComponent as TodayIcon} from '../../resources/imgs/today-icon.svg';
import { ReactComponent as PremiumIcon} from '../../resources/imgs/premium-icon.svg';
import { ReactComponent as RequestsIcon} from '../../resources/imgs/requests-icon.svg';
import { ReactComponent as SettingsIcon} from '../../resources/imgs/settings-icon.svg';
import { ReactComponent as NavbarIcon} from '../../resources/imgs/navbar-icon.svg';
import { ReactComponent as LogoutIcon} from '../../resources/imgs/logout-icon.svg';
import {
    getAdmins,
    getCloudFeed,
    getFeed,
    getRemoteConfig,
    saveAdmin
} from "../../actions/general";
import {configs} from "../../resources/configs";
import {getAdmin, getAdminIamActions, isAuth, registerCookiesFromLocalStorage} from '../../utils/AuthUtill'
import {FaHashtag as Hashtag} from 'react-icons/fa';
import {AdminType} from '../../reducers/auth';
import {connectWSS, disconnectWSS} from '../../actions/websockets';
import {isMobile, MobileView} from "react-device-detect";
import {getStyle} from '../../utils/StyleUtils';
import {Nav} from './stylesJS/Styles';
import {WebsocketServiceType} from '../../middlewares/websockets/types';
import {compareString} from "../../utils/ArrayUtils";

const menuVariants = {
    opened: {
        top: 50,
    },
    closed: {
        top: "-100vh"
    }
};

class Head extends Component {

    constructor(props) {
        super(props);
        this.state = {isActiveNavbar: false, iamActions: getAdminIamActions()}
    }

    isActive = (path) => {
        return (match, location) => {
            return location.pathname === path;
        }
    }

    componentDidMount() {
        if(this.props.isReloadFeed && this.state.iamActions.indexOf('fltrFeed:GetFeed') !== -1 && this.state.iamActions.indexOf('fltrFeed:GetCloudFeed') !== -1){
            registerCookiesFromLocalStorage()
            this.props.getFeed()
            this.props.getCloudFeed()
        }
        if(this.state.iamActions.length === 0) {
            this.props.logout()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.isReloadFeed && this.state.iamActions.indexOf('fltrFeed:GetFeed') !== -1 && this.state.iamActions.indexOf('fltrFeed:GetCloudFeed') !== -1){
          registerCookiesFromLocalStorage()
          this.props.getFeed()
            this.props.getCloudFeed()
        }
    }

    componentWillMount () {
        this.connectToServer(this.props)
        registerCookiesFromLocalStorage()
        if(!this.props.isLoadingAdmins && this.props.admins.length === 0 && this.state.iamActions.indexOf('common:GetAdmins') !== -1){
            this.props.getAdmins();
        }

        if(!this.props.isLoadingRemoteConfig && this.state.iamActions.indexOf('common:GetAdmins') !== -1){
            this.props.getRemoteConfig();
        }
    }

    componentWillUpdate (nextProps, nextState, nextContext) {
        this.connectToServer(nextProps);
    }

    connectToServer = (props) => {
        if (!props.isConnectedWSS && isAuth() && !props.isConnectingWSS && !props.isClosedWSS) {
            console.log('Connect TO server ' + props.isConnectedWSS);
            let serviceTypes = [];
            if(this.state.iamActions.length !== 0) {
                if(this.state.iamActions.indexOf('fltrFeed:AccessToFltrWebsockets') !== -1) {
                    serviceTypes.push(WebsocketServiceType.FLTR);
                }
                if(this.state.iamActions.indexOf('fltrRequests:AccessToFltrRequestsWebsockets') !== -1) {
                    serviceTypes.push(WebsocketServiceType.FLTR_REQUESTS);
                }
            } else {
                serviceTypes = [WebsocketServiceType.FLTR_REQUESTS, WebsocketServiceType.FLTR];
            }
            props.connectWSS(serviceTypes)
        }
    }

    renderItems = (navs) => {
        const items = [];

        navs.sort(compareString('order')).forEach((nav) => {
            items.push(
                <NavLink
                    isActive={this.isActive(nav.isActive)}
                    to={nav.to}  className="head-item-link"
                    activeClassName={"head-item-link-active"}
                    key={nav.name}
                    style={{paddingTop: isMobile ? '30px' : undefined}}
                    onClick={() => {this.setState({isActiveNavbar: false})}}
                >
                    {nav.icon}
                    <div className={"head-item-text"}>
                        {nav.name}
                    </div>
                </NavLink>
            )})

        items.push(
            <div className={"head-item-link head-item-logout"} onClick={this.props.logout} style={{
                marginLeft: isMobile ? '20px' : "auto",
                marginRight: isMobile ? '20px' : undefined,
                paddingTop: isMobile ? '56px' : undefined,
            }}>
                <LogoutIcon/>
                <div className={"head-item-text"}>
                    Logout
                </div>
            </div>
        )

        return items;
    }

    renderMobileItems = (navs) => {
        return <Nav
            initial={false}
            variants={menuVariants}
            animate={this.state.isActiveNavbar ? "opened" : "closed"}
        >
            {this.renderItems(navs)}
        </Nav>
    }

    render () {
        const admin = getAdmin();
        const iamActions = getAdminIamActions();

        let navs = [];
        if (iamActions.length !== 0) {
            iamActions.forEach(action => {
                if(action.includes('webUI')) {
                    navs = navs.concat(navsTemplates[action] ? navsTemplates[action] : [])
                }
            })
        } else {
            navs = admin.adminType === AdminType.FLTR_REQUESTS_MANAGER
                ? navsTemplates[AdminType.FLTR_REQUESTS_MANAGER] : navsTemplates['default'];
        }


        const color = !configs.isLocal ? 'none' : this.props.isConnectedWSS ? 'green' : this.props.isConnectingWSS ? 'yellow' : 'red';

        return (
            <div className={getStyle('head-panel-container')}>
                <div className={getStyle('head-panel')}>
                    <div className="head-item-logo-container">
                        <div className="head-item-logo" style={{backgroundColor:  color}}/>
                        <div className="head-item-logo-text">FLTR</div>
                        {configs.isDev ? <div className="head-item-logo-text" style={{color: 'red'}}>DEV</div> : null}
                    </div>
                        {
                            isMobile ? undefined : this.renderItems(navs)
                        }
                    <MobileView style={{alignSelf: 'center'}}>

                        <NavbarIcon
                            onClick={() => {
                                this.setState({isActiveNavbar: !this.state.isActiveNavbar})
                            }}
                        />
                    </MobileView>
                </div>
                {this.renderMobileItems(navs)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isReloadFeed: state.appState.isReloadFeed,
    isConnectedWSS: state.appState.isConnectedWSS[WebsocketServiceType.FLTR] && state.appState.isConnectedWSS[WebsocketServiceType.FLTR_REQUESTS],
    isConnectingWSS: state.appState.isConnectingWSS[WebsocketServiceType.FLTR] || state.appState.isConnectingWSS[WebsocketServiceType.FLTR_REQUESTS],
    admins: state.appState.admins,
    isLoadingAdmins: state.appState.isLoadingAdmins,
    isLoadingRemoteConfig: state.appState.isLoadingRemoteConfig,
    isClosedWSS: state.appState.isClosedWSS[WebsocketServiceType.FLTR] || state.appState.isClosedWSS[WebsocketServiceType.FLTR_REQUESTS],
})

const mapDispatchToProps = (dispatch) => ({
    logout: bindActionCreators(logout, dispatch),
    getFeed: bindActionCreators(getFeed, dispatch),
    getCloudFeed: bindActionCreators(getCloudFeed, dispatch),
    connectWSS: bindActionCreators(connectWSS, dispatch),
    disconnectWSS: bindActionCreators(disconnectWSS, dispatch),
    getAdmins: bindActionCreators(getAdmins, dispatch),
    getRemoteConfig: bindActionCreators(getRemoteConfig, dispatch),
    saveAdmin: bindActionCreators(saveAdmin, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Head)

const navsTemplates = {
    FLTR_REQUESTS_MANAGER: [
        {isActive: '/requests', to: '/requests', icon: <RequestsIcon/>, name: 'Requests', order: 0}
    ],
    INPREVIEW_PROMO_FLTR_REQUESTS: [
        {isActive: '/requests', to: '/requests', icon: <RequestsIcon/>, name: 'Requests', order: 0}
    ],
    default: [
        {isActive: '/', to: '/', icon: <LibraryIcon/>, name: 'Library', order: 0},
        {isActive: '/today', to: '/today', icon: <TodayIcon/>, name: 'Today', order: 1},
        {isActive: '/premium', to: '/premium', icon: <PremiumIcon/>, name: 'Premium', order: 2},
        {isActive: '/requests', to: '/requests', icon: <RequestsIcon/>, name: 'Requests', order: 3},
        {isActive: '/tags', to: '/tags', icon: <Hashtag/>, name: 'Tags', order: 4},
        {isActive: '/settings', to: '/settings', icon: <SettingsIcon/>, name: 'Settings', order: 5},
    ],
    'webUI:AccessFltrRequests': [
        {isActive: '/requests', to: '/requests', icon: <RequestsIcon/>, name: 'Requests', order: 3}
    ],
    'webUI:AccessIndividualFltrRequests': [
        {isActive: '/requests', to: '/requests', icon: <RequestsIcon/>, name: 'Requests', order: 3}
    ],
    'webUI:AccessFltrFeed': [
        {isActive: '/', to: '/', icon: <LibraryIcon/>, name: 'Library', order: 0},
        {isActive: '/today', to: '/today', icon: <TodayIcon/>, name: 'Today', order: 1},
        {isActive: '/premium', to: '/premium', icon: <PremiumIcon/>, name: 'Premium', order: 2},
    ],
    'webUI:AccessFltrConfig': [
        {isActive: '/settings', to: '/settings', icon: <SettingsIcon/>, name: 'Settings', order: 5},
    ],
    'webUI:AccessFltrTags': [
        {isActive: '/tags', to: '/tags', icon: <Hashtag/>, name: 'Tags', order: 4},
    ]
}
