import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {updateRemoteConfig} from '../../actions/general';
import {bindActionCreators} from 'redux';
import MDSpinner from 'react-md-spinner';
import {getStyle} from '../../utils/StyleUtils';
import {Switcher} from '../general/Switcher';
import {Input} from '../general/Input';
import {timeIntervalRequestMS} from '../../utils/TimeUtils';
import {Checkbox} from '../general/Checkbox';

const RemoteConfigSection = ({remoteConfig, updateRemoteConfig, isLoadingRemoteConfig}) => {
  const [isEnableRequests, setIsEnableRequests] = useState(remoteConfig ? remoteConfig.isEnableRequests : false);
  const [limitRequestsForUser, setLimitRequestsForUser] = useState(remoteConfig ? remoteConfig.limitRequestsForUser : 0);
  const [limitRequestsForAll, setLimitRequestsForAll] = useState(remoteConfig ? remoteConfig.limitRequestsForAll : 0);
  const [requestsFakeCount, setRequestsFakeCount] = useState(remoteConfig ? remoteConfig.requestsFakeCount : 0);
  const [requestsFakeAdditionalCount, setRequestsFakeAdditionalCount] = useState(remoteConfig ? remoteConfig.requestsFakeAdditionalCount : 3000);
  const [requestsCount, setRequestsCount] = useState(remoteConfig ? remoteConfig.requestsCount : 0);
  const [weightPhoto, setWeightPhoto] = useState(remoteConfig ? remoteConfig.weightPhoto : 10);
  const [isEnableTodayNotifications, setIsEnableTodayNotifications] = useState(remoteConfig ? remoteConfig.isEnableTodayNotifications : false);
  const [isChanged, setIsChanged] = useState(false);
  const [requestProcessingInterval, setRequestProcessingInterval] = useState(remoteConfig ? remoteConfig.requestProcessingInterval : timeIntervalRequestMS);
  useEffect(() => {
    if (remoteConfig) {
      setIsEnableRequests(remoteConfig.isEnableRequests);
      setLimitRequestsForUser(remoteConfig.limitRequestsForUser);
      setLimitRequestsForAll(remoteConfig.limitRequestsForAll);
      setRequestsFakeCount(remoteConfig.requestsFakeCount);
      setRequestsCount(remoteConfig.requestsCount);
      setWeightPhoto(remoteConfig.weightPhoto);
      setRequestProcessingInterval(remoteConfig.requestProcessingInterval);
      setRequestsFakeAdditionalCount(remoteConfig.requestsFakeAdditionalCount);
      setIsEnableTodayNotifications(remoteConfig.isEnableTodayNotifications);
    }
  }, [remoteConfig]);

  if (isLoadingRemoteConfig || !remoteConfig) {
    return (
      <div className={getStyle('remote-config-container')}>
        <div className='spinner-container' style={{height: '424px'}}>
          <MDSpinner size={100} singleColor={'#00DADA'}/>
        </div>
      </div>
    );
  }

  const onSave = () => {
    const remoteConfig = {
      isEnableRequests,
      limitRequestsForUser,
      limitRequestsForAll,
      requestsFakeCount,
      requestsCount,
      weightPhoto,
      requestProcessingInterval,
      requestsFakeAdditionalCount,
      isEnableTodayNotifications,
    };

    updateRemoteConfig(remoteConfig);
  };

  const renderProp = (title, value, onEdit, isInput, paddingTop = '14px') => {
    return <div className={'remote-config-prop-container'} style={{paddingTop}} key={title}>
      <div className={'remote-config-prop-title'}>{title}</div>
      {isInput ? <Input
        value={value}
        handleChange={(value) => {
          setIsChanged(true);
          const valueNumber = Number(value);
          if (Number.isInteger(valueNumber)) {
            onEdit(valueNumber);
          }
        }}
        style={{width: '100px', textAlign: 'center', paddingLeft: '0px'}}
      /> :
        <div className={'remote-config-prop-text'}>{value}</div>}
    </div>;
  };

  return (
    <div className={getStyle('remote-config-container')}>
      <div className={'remote-config-header'}>
        <div className={'remote-config-header-text'}>Remote config</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className={'remote-config-switcher-text'}>AVAILABILITY</div>
          <Switcher
            isActive={isEnableRequests}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsEnableRequests(isEnabled);
            }}
          />
        </div>
      </div>
      <div className={'remote-config-body'}>
        {renderProp('Number of requests', requestsCount, setRequestsCount, false, '0px')}
        {renderProp('Number of fake requests', requestsFakeAdditionalCount, setRequestsFakeAdditionalCount, true)}
        {renderProp('Number of user-visible requests', requestsFakeCount, setRequestsFakeCount)}
        {renderProp('Max requests per day for 1 user', limitRequestsForUser, setLimitRequestsForUser, true)}
        {renderProp('Max requests per day for all users', limitRequestsForAll, setLimitRequestsForAll, true)}
        {renderProp('Size photo MB', weightPhoto / 1048576, (weight) => {
          setWeightPhoto(weight * 1048576);
        }, true)}
        {renderProp('Processing interval (sec)', requestProcessingInterval, setRequestProcessingInterval, true)}
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <div className={'remote-config-switcher-text'}>Today push notifications</div>
          <Checkbox
            isActive={isEnableTodayNotifications}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsEnableTodayNotifications(isEnabled);
            }}
          />
        </div>
      </div>
      <div className={'remote-config-footer'}>
        <button className={'save-btn'} disabled={!isChanged} onClick={() => {
          onSave();
          setIsChanged(false);
        }}>
          Save
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingRemoteConfig: state.appState.isLoadingRemoteConfig,
  remoteConfig: state.appState.remoteConfig,
});

const mapDispatchToProps = (dispatch) => ({
  updateRemoteConfig: bindActionCreators(updateRemoteConfig, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfigSection);
