import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getStatusPublishFeed, selectContentType, showModal, typeModal} from '../../actions/general';
import MDSpinner from 'react-md-spinner';
import momentZ from 'moment-timezone';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {WebsocketServiceType} from '../../middlewares/websockets/types';

const LibraryHeader = ({contentTypes, selectedContentTypeId, selectContentType, getStatusPublishFeed,
  version, isPublishing: isPublishing, showModal, isBlockPublish, isUpdatingContent, isConnectedWSS}) => {
  const [refreshInterval, setRefreshInterval] = useState(0);

  useEffect(() => {
    if (isConnectedWSS) {
      getStatusPublishFeed();
      setTimeout(function() {
        setRefreshInterval(refreshInterval + 1);
      }, 2400000);
    }
  }, [refreshInterval, isConnectedWSS]);

  const renderContentTypes = () => {
    return <div className={'content-types-container'}>
      {contentTypes
          .sort((a, b) => (a.id - b.id))
          .map((contentType, index) => {
            return <button
              key={contentType.id}
              className={'content-type-' + (selectedContentTypeId === contentType.id ? 'selected' : 'unselected')}
              onClick={() => {
                selectContentType(contentType.id);
              }}
              style={{marginLeft: index === 0 ? '0px' : '12px'}}
            >
              {contentType.shortNames}
            </button>;
          })}
    </div>;
  };

  const renderPublish = () => {
    return <div className={'publish-container'}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'fltr-second-text'}>
          {version ? 'Published ' + toUserTimeZoneDate(momentZ(version.publishDate)).format('DD.MM.YYYY HH:mm') : ''}
        </div>
        <div className={'fltr-second-text'}>
          {version ? 'Publisher ' + version.publisher : ''}
        </div>
      </div>
      <div style={{width: '100px', paddingLeft: '14px'}}>
        {
                    isPublishing ?
                    <div className='spinner-container' >
                      <MDSpinner size={30} singleColor={'#00DADA'}/>
                    </div> :
                    <button className={'publish-btn'}
                      disabled={isBlockPublish || isUpdatingContent}
                      onClick={()=>{
                        showModal(typeModal.PUBLISH, true);
                      }}
                    >
                      {isUpdatingContent ? 'Uploading' : 'Publish'}
                    </button>
        }
      </div>
    </div>;
  };

  return (
    <div className={'library-header'}>
      {renderContentTypes()}
      {renderPublish()}
    </div>
  );
};


const mapStateToProps = (state) => ({
  contentTypes: state.appState.contentTypes,
  selectedContentTypeId: state.appState.selectedContentTypeId,
  version: state.appState.version,
  isPublishing: state.appState.isPublishing,
  isBlockPublish: state.appState.isBlockPublish,
  isUpdatingContent: state.appState.isUpdatingContent,
  isConnectedWSS: state.appState.isConnectedWSS[WebsocketServiceType.FLTR],
});

const mapDispatchToProps = (dispatch) => ({
  selectContentType: bindActionCreators(selectContentType, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  getStatusPublishFeed: bindActionCreators(getStatusPublishFeed, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LibraryHeader);
