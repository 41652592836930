import React, {useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import {getLocalizedValue} from '../../utils/LocalizationUtils';
import {ElementsListType} from './ElementsList';
import DropdownWrapper from '../general/DropdownWrapper';
import ComponentSettings from '../general/ComponentSettings';
import {ComponentType} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Draggable} from 'react-beautiful-dnd';
import {setEditElement} from '../../actions/elements';

const Element = ({id, element, isSelected, isBlockDnD, packId, editElement,
  draggableId, elementListType, arrayIndex,
}) => {
  const [isMouseOver, setMouseOver] = useState(false);

  const renderButtons = () => {
    return <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
      <DropdownWrapper style={{right: '-100%'}}>
        <DotsHorizontal
          style={{opacity: (isMouseOver ? 1 : 0)}}
        />
        <ComponentSettings componentType={ComponentType.ELEMENT}
          sourceItemId={packId}
          componentTypeIn={elementListType}
          item={element}
        />
      </DropdownWrapper>
    </div>;
  };
  const isDragging = false;

  const style = {
    cursor: (isBlockDnD ? 'pointer' : 'move'),
    marginBottom: (isDragging ? '20px' : '0px'),
  };

  return (
    <Draggable
      key={element.id}
      draggableId={draggableId}
      index={arrayIndex}
    >
      {(provided, snapshot) => (
        <div className={'pack-list-content' + (isSelected ? '-active' : '')}
          onMouseLeave={() => setMouseOver(false)}
          onMouseEnter={() => setMouseOver(true)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{...provided.draggableProps.style}}
          {...provided.dragHandleProps}
          onClick={() => editElement(element)}
        >
          <div className={'element-list-item' + (elementListType === ElementsListType.ELEMENTS_IN_PACK ? '-in-pack' : '')}
            style={style}
          >
            { isBlockDnD ? null :
                    <div className={'drag-dots'}
                      style={{
                        opacity: (isMouseOver ? 1 : 0),
                      }}
                    >
                    </div>
            }
            {
                    element.videoUrl ?
                        <video className={'element-list-img' + (elementListType === ElementsListType.ELEMENTS_IN_PACK ? '-in-pack' : '')}
                          style={{opacity: (isDragging ? 0 : (element.isActive ? '1' : '0.4'))}}>
                          <source src={element.videoUrl} type="video/mp4"/>
                        </video> :
                        <img className={'element-list-img' + (elementListType === ElementsListType.ELEMENTS_IN_PACK ? '-in-pack' : '')}
                          style={{opacity: (isDragging ? 0 : (element.isActive ? '1' : '0.4'))}}
                          src={element.previewUrl ? element.previewUrl : element.processedImageUrl ? element.processedImageUrl : element.imageUrl}
                        />
            }

            <div className={'pack-list-value-name'}>
            </div>

            <div style={{display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '10px',
              opacity: (isDragging ? 0 : (element.isActive ? '1' : '0.4')),
            }}>
              <div className={'pack-list-value-name'}>
                {getLocalizedValue(element.localization, 'name')}
              </div>

              {elementListType === ElementsListType.ELEMENTS_IN_TODAY || elementListType === ElementsListType.ELEMENTS_IN_PREMIUM ?
                        <div className="fltr-second-text">
                          {element.contentTypeId} Element
                        </div> : null}
            </div>

            {renderButtons()}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editElement: bindActionCreators(setEditElement, dispatch),
});

export default connect(null, mapDispatchToProps)(Element);
