import {FileInput} from './FileInput';
import React, {useEffect} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {dngToJpgUrl, saveFile} from '../../utils/FilesUtils';
import MDSpinner from 'react-md-spinner';
import {getStyle} from '../../utils/StyleUtils';

export const DngInput = ({style, handleFileDrop, setElementUrl, setElementFileUrl, setIsLoadingDNG,
  setElementPreviewUrl, elementUrl, elementPreviewUrl, tempNameDNG, elementFileTempUrl, isLoadingDNG,
  isLoadPreviewDNG = true, maxSizeDngBytes, dngLabel = 'DNG'
}) => {
  useEffect(() => {
    if ((elementUrl || elementFileTempUrl) && (!elementPreviewUrl || elementPreviewUrl === '') && isLoadPreviewDNG) {
      dngToJpgUrl(elementUrl, elementFileTempUrl, setElementPreviewUrl);
    }
  }, [elementUrl]);

  useEffect(() => {
  }, [elementFileTempUrl]);

  const renderImgDNG = () => {
    if (elementPreviewUrl && elementPreviewUrl !== '') {
      return <img className={getStyle('dng-img')} style={{marginRight: '7px'}}
        onClick={() => {
          saveFile(elementUrl);
        } }
        src={elementPreviewUrl}
      />;
    } else if (elementUrl && elementUrl !== '') {
      return <div className='spinner-container' style={{height: '30px', marginRight: '7px'}}>
        <MDSpinner size={20} singleColor={'#00dada'}/>
      </div>;
    }
  };

  if (isLoadingDNG) {
    return <div className={getStyle('dng-input-container')} style={style}>
      <div className={'fltr-second-text'} style={{fontSize: isMobileOnly ? '9px' : undefined}}>
        {dngLabel}
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: isMobileOnly ? '2px' : '7px', justifyContent: 'center'}}>
        <MDSpinner size={20} singleColor={'#00dada'}/>
      </div>
    </div>;
  }

  return <div className={getStyle('dng-input-container')} style={style}>
    <div className={'fltr-second-text'} style={{fontSize: isMobileOnly ? '9px' : undefined}}>
      {dngLabel}
    </div>
    <div style={{display: 'flex', alignItems: 'center', marginTop: isMobileOnly ? '2px' : '7px'}}>
      {renderImgDNG()}
      <FileInput style={{width: '100%'}}
        handleChange={(file) => {
          const check = (file.type === 'image/x-adobe-dng' || file.type === 'image/tiff') && ((maxSizeDngBytes && maxSizeDngBytes > file.size) || !maxSizeDngBytes);
          alert(`Type: ${file.type}; Size: ${file.size}; IsTrue: ${check}`);
          if (check) {
            handleFileDrop(
                file,
                setElementFileUrl,
                setElementUrl,
                setIsLoadingDNG,
                tempNameDNG,
                true,
            );
          }
        }}
        placeholder={'Upload file'}
        value={elementUrl}
      />
    </div>

  </div>;
};
