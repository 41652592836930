import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_CONNECTING,
  WebsocketServiceType,
} from '../middlewares/websockets/types';
import {getAccessToken} from '../utils/AuthUtill';
import {configs} from '../resources/configs';

export const getWebsocketUrl = (serviceTypes) => {
  return [
    {
      url: configs.fltrRequestsWebsocketUrl + '?token=' + getAccessToken(),
      serviceType: WebsocketServiceType.FLTR_REQUESTS,
    },
    {
      url: configs.fltrWebsocketUrl + '?token=' + getAccessToken(),
      serviceType: WebsocketServiceType.FLTR,
    },
  ].filter((websocketUrl) => serviceTypes.indexOf(websocketUrl.serviceType) !== -1);
};

export const connectWSS = (serviceTypes) => (dispatch) => {
  dispatch({
    type: WEBSOCKET_CONNECT,
    payload: {
      urls: getWebsocketUrl(serviceTypes),
    },
  });
  dispatch({type: WEBSOCKET_CONNECTING, serviceTypes});
};

export const disconnectWSS = () => (dispatch) => {
  dispatch({type: WEBSOCKET_DISCONNECT});
};
