import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';


const AddHeader = ({title, modalType, showModal, meta}) => {
  return (
    <div className={'fltr-add-header'}>
      <div className={'fltr-add-header-title'}>
        {title}
      </div>
      <div className={'fltr-add-header-add-container'} onClick={() => {
        showModal(modalType, true, meta);
      }}>
        <PlusIcon/>
        <div className={'fltr-add-header-text-add'}>
                    Add
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(AddHeader);
