import momentZ from 'moment-timezone';
import {IS_LOADING_PACKS, SAVE_PACKS} from './packs';
import {IS_LOADING_GROUPS, SAVE_GROUPS} from './groups';
import {IS_LOADING_ELEMENTS, SAVE_ELEMENTS} from './elements';
import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {IS_LOADING_TAGS, SAVE_TAGS} from './tags';
import {configs} from '../resources/configs';
import {WEBSOCKET_SEND, WebsocketAction} from '../middlewares/websockets/types';
import {StatusPublishFeed} from '../models/Feed';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_IS_RELOAD_FEED = 'SET_IS_RELOAD_FEED';
export const SELECT_LANG = 'SELECT_LANG';
export const SELECT_CONTENT_TYPE = 'SELECT_CONTENT_TYPE';
export const SAVE_CONTENT_TYPES = 'SAVE_CONTENT_TYPES';
export const SAVE_FEED = 'SAVE_FEED';
export const IS_PUBLISHED = 'IS_PUBLISHED';
export const IS_UPDATING_CONTENT = 'IS_UPDATING_CONTENT';
export const SET_IS_BLOCK_PUBLISH= 'SET_IS_BLOCK_PUBLISH';
export const SAVE_VERSION = 'SAVE_VERSION';
export const SAVE_CLOUD_FEED = 'SAVE_CLOUD_FEED';
export const SAVE_ADMIN = 'SAVE_ADMIN';
export const SAVE_ADMINS = 'SAVE_ADMINS';
export const SET_IS_LOADING_ADMINS = 'SET_IS_LOADING_ADMINS';
export const SET_IS_LOADING_REMOTE_CONFIG = 'SET_IS_LOADING_REMOTE_CONFIG';
export const SAVE_REMOTE_CONFIG = 'SAVE_REMOTE_CONFIG';
export const typeModal = {
  PACK_ADD: 'PACK_ADD',
  PACK_EDIT: 'PACK_EDIT',
  PACK_DELETE: 'PACK_DELETE',
  ELEMENT_ADD: 'ELEMENT_ADD',
  ELEMENT_EDIT: 'ELEMENT_EDIT',
  ELEMENT_DELETE: 'ELEMENT_DELETE',
  GROUP_ADD: 'GROUP_ADD',
  GROUP_EDIT: 'GROUP_EDIT',
  GROUP_DELETE: 'GROUP_DELETE',
  TAG_DELETE: 'TAG_DELETE',
  TAG_ADD: 'TAG_ADD',
  TAG_EDIT: 'TAG_EDIT',
  PUBLISH: 'PUBLISH',
  REQUEST_EDIT: 'REQUEST_EDIT',
  REQUEST_DELETE: 'REQUEST_DELETE',
  REQUEST_IS_PROCESSED: 'REQUEST_IS_PROCESSED',
  DONE_REQUEST: 'DONE_REQUEST',
  CREATE_PERSONAL_NOTIFICATION: 'CREATE_PERSONAL_NOTIFICATION',
  INTERNET_DISCONNECTED: 'INTERNET_DISCONNECTED'
};

export const ComponentType = {
  ELEMENT: 'ELEMENT',
  PACK: 'PACK',
  GROUP: 'GROUP',
  TAG: 'TAG',
};

export const MetaType = {
  WithPack: 'WithPack',
};

export const showModal = (modalType, isShow, meta) => (dispatch) => {
  dispatch({
    type: SHOW_MODAL,
    modalType,
    isShow,
    meta,
  });
};

export const setIsReloadFeed = (isReload) => (dispatch) => {
  dispatch({
    type: SET_IS_RELOAD_FEED,
    isReload,
  });
};

export const saveAdmin = (admin) => (dispatch) => {
  dispatch({
    type: SAVE_ADMIN,
    admin,
  });
};

export const selectLang = (lang) => (dispatch) => {
  dispatch({
    type: SELECT_LANG,
    lang,
  });
};

export const getFeed = () => (dispatch, getState) => {
  dispatch({type: IS_LOADING_PACKS, isLoading: true});
  dispatch({type: IS_LOADING_GROUPS, isLoading: true});
  dispatch({type: IS_LOADING_ELEMENTS, isLoading: true});
  dispatch({type: IS_LOADING_TAGS, isLoading: true});

  dispatch({type: SET_IS_RELOAD_FEED, isReload: false});

  const stateIdPacks = getState().packs.stateId;
  const stateIdPresets = getState().elements.stateId;
  const stateIdGroups = getState().groups.stateId;
  const stateIdTags = getState().tags.stateId;

  const payload = {
    action: WebsocketAction.GET_FLTR_FEED,
    stateId: `${stateIdPacks}_${stateIdPresets}_${stateIdGroups}_${stateIdTags}`,
  };

  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });

/*  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');
    const groups = response.groups ? response.groups : [];
    const groupsFiltered = groups.filter((group) => {
      return [1, 2, 3].indexOf(group.id) === -1;
    });

    dispatch({
      type: SAVE_FEED,
      feed: response,
    });

    dispatch({
      type: SAVE_CONTENT_TYPES,
      contentTypes: response.contentTypes ? response.contentTypes : [],
    });

    dispatch({
      type: SAVE_VERSION,
      version: response.version,
    });

    dispatch({
      type: SAVE_PACKS,
      stateId: stateIdPacks,
      requestTimeEpoch: timeStampRequest,
      groups,
      packs: response.packs ? response.packs : [],
    });

    dispatch({
      type: SAVE_ELEMENTS,
      stateId: stateIdPresets,
      requestTimeEpoch: timeStampRequest,
      packs: response.packs ? response.packs : [],
      elements: response.elements ? response.elements : [],
    });

    dispatch({
      type: SAVE_GROUPS,
      stateId: stateIdGroups,
      requestTimeEpoch: timeStampRequest,
      groups: groupsFiltered,
    });

    dispatch({
      type: SAVE_TAGS,
      stateId: stateIdTags,
      requestTimeEpoch: timeStampRequest,
      elements: response.elements ? response.elements : [],
      tags: response.tags ? response.tags : [],
    });
  };
  const xhr = createRequest(dispatch, 'GET', '/feed', onload);

  xhr.send();*/
};

export const publish = () => (dispatch) => {
  dispatch({
    type: WebsocketAction.STATUS_FLTR_FEED_PUBLISH.name,
    data: {message: {status: StatusPublishFeed.PUBLISHING}},
  });

  const payload = {
    action: WebsocketAction.FLTR_FEED_PUBLISH,
  };
  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const getCloudFeed = () => (dispatch) => {
  const onload = (responseText) => {
    let cloudFeed;
    try {
      cloudFeed = JSON.parse(new TextDecoder().decode(responseText));
    } catch (e) {
      console.log(e);
      cloudFeed = null;
    }
    dispatch({type: SAVE_CLOUD_FEED, cloudFeed});
  };

  getFileArrayBuffer(configs.cloudFeedUrl, onload);
};

export const getStatusPublishFeed = () => (dispatch) => {
  const payload = {
    action: WebsocketAction.STATUS_FLTR_FEED_PUBLISH,
  };
  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const selectContentType = (contentTypeId) => (dispatch) => {
  dispatch({
    type: SELECT_CONTENT_TYPE,
    contentTypeId,
  });
};

export const getAdmins = () => (dispatch) => {
  dispatch({type: SET_IS_LOADING_ADMINS, isLoading: true});

  const onload = (responseText) => {
    const admins = JSON.parse(responseText);
    dispatch({type: SAVE_ADMINS, admins});
  };
  const xhr = createRequest(dispatch, 'GET', '/admins', onload, true, '');

  xhr.send();
};

export const getRemoteConfig = () => (dispatch) => {
  dispatch({type: SET_IS_LOADING_REMOTE_CONFIG, isLoading: true});

  const onload = (responseText) => {
    let remoteConfig;
    try {
      remoteConfig = JSON.parse(new TextDecoder().decode(responseText));
    } catch (e) {
      console.log(e);
      remoteConfig = null;
    }
    dispatch({type: SAVE_REMOTE_CONFIG, remoteConfig});
  };

  getFileArrayBuffer(configs.remoteConfigUrl, onload);
};

export const updateRemoteConfig = (remoteConfig) => (dispatch) => {
  dispatch({type: SAVE_REMOTE_CONFIG, remoteConfig});
  const xhr = createRequest(dispatch, 'PUT', '/config', () => {}, true);

  xhr.send(JSON.stringify(remoteConfig));
};

export const createPersonalNotification = (deviceId, entityId) => (dispatch) => {
  const xhr = createRequest(dispatch, 'POST', '/personal-notification', () => {}, true);

  xhr.send(JSON.stringify({deviceId, entityId}));
};
