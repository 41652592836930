import React from 'react';
import TodayList from './TodayList';

const Today = ({}) => {
  return (
    <div style={{height: '100%', overflow: 'overlay', marginBottom: '100px'}}>
      <div className={'today-container'}>
        <TodayList/>
      </div>
    </div>
  );
};

export default Today;
