import {IS_UPDATING_CONTENT, SET_IS_BLOCK_PUBLISH, SET_IS_RELOAD_FEED} from './general';
import {batch} from 'react-redux';
import uuid from 'uuid/v4';
import {createRequest} from '../utils/HttpRequestUtills';
import {compareString} from '../utils/ArrayUtils';
import {capitalizeAllWords} from '../utils/StringUtils';

export const SAVE_PACKS = 'SAVE_PACKS';
export const IS_LOADING_PACKS = 'IS_LOADING_PACKS';
export const DELETE_PACK = 'DELETE_PACK';
export const UPDATE_PACK = 'UPDATE_PACK';
export const SELECT_PACK = 'SELECT_PACK';

export const REMOVE_PACK_FROM_GROUP = 'REMOVE_PACK_FROM_GROUP';
export const MOVE_PACKS_IN_GROUP = 'MOVE_PACKS_IN_GROUP';
export const REPLACE_PACKS_IN_GROUPS = 'REPLACE_PACKS_IN_GROUPS';
export const ADD_PACK_TO_GROUP = 'ADD_PACK_TO_GROUP';
export const DELETE_PACK_FROM_ELEMENTS= 'DELETE_PACK_FROM_ELEMENTS';

export const ADD_ELEMENT_TO_PACKS = 'ADD_ELEMENT_TO_PACKS';
export const REMOVE_ELEMENT_FROM_PACKS = 'REMOVE_ELEMENT_FROM_PACKS';
export const REMOVE_GROUP_FROM_PACKS = 'REMOVE_GROUP_FROM_PACKS';
export const UPDATE_PACK_IN_GROUP = 'UPDATE_PACK_IN_GROUP';
export const UPDATE_RELATION_PACK_TO_GROUP = 'UPDATE_RELATION_PACK_TO_GROUP';

export const REMOVE_PACK_FROM_GROUPS = 'REMOVE_PACK_FROM_GROUPS';
export const SET_EDIT_PACK = 'SET_EDIT_PACK';
export const SET_DELETE_PACK = 'SET_DELETE_PACK';


export const addPack = (pack) => (dispatch) => {
  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });
  dispatch({type: IS_LOADING_PACKS, isLoading: true});

  const onload = () => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
    dispatch({type: SET_IS_RELOAD_FEED, isReload: true});
  };
  const xhr = createRequest(dispatch, 'POST', '/pack', onload);

  xhr.send(JSON.stringify(pack));
};

export const addHighlightPack = (pack, elements) => (dispatch) => {
  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });
  dispatch({type: IS_LOADING_PACKS, isLoading: true});

  const onload = (responseText) => {
    const pack = JSON.parse(responseText);

    elements
        .sort(compareString('name'))
        .forEach((element, index) => {
          const onload = () => {};
          const xhr = createRequest(dispatch, 'POST', '/element', onload, false);

          const elementForSave = {};
          let name = element.name;
          name = name ? name : (pack.name ? pack.name + ' ' + (index + 1) : index + 1);
          name = capitalizeAllWords(name);
          elementForSave.isActive = true;
          elementForSave.isFree = pack.isFree;
          elementForSave.isPremium = false;
          elementForSave.isNew = pack.isNew;
          elementForSave.name = name;
          elementForSave.description = '';
          elementForSave.contentTypeId = pack.contentTypeId;
          elementForSave.isForceUpdate = true;
          elementForSave.imageTempPath = element.path;
          elementForSave.todayDate = null;
          elementForSave.localization = {en: {name: name}};
          elementForSave.isUpdateRelations = true;
          elementForSave.order = index + 1;
          elementForSave.sku = uuid();
          elementForSave.fltrTagIds = [];
          elementForSave.elementToPacks = [{
            packId: pack.id,
            uuid: uuid(),
            order: index + 1,
          }];

          xhr.send(JSON.stringify(elementForSave));
        });
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
    dispatch({type: SET_IS_RELOAD_FEED, isReload: true});
  };

  const xhr = createRequest(dispatch, 'POST', '/pack', onload);

  xhr.send(JSON.stringify(pack));
};

export const movePackInGroup = (
    orderIndexes,
    pack,
    fromIndex,
    toIndex,
    toGroupId,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: MOVE_PACKS_IN_GROUP,
    groupId: toGroupId,
    fromIndex,
    toIndex,
    pack,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/pack-to-groups/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes}));
};

export const replacePackFromGroupToGroup = (
    orderIndexes,
    pack,
    fromIndex,
    toIndex,
    fromGroupId,
    toGroupId,
    newRelation,
    oldUUID,
    replaceUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: REPLACE_PACKS_IN_GROUPS,
    toGroupId,
    fromGroupId,
    toIndex,
    pack,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/pack-to-groups/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes, newRelation, replaceUUID, oldUUID}));
};

export const removePackFromGroup = (
    pack,
    fromGroupId,
    oldUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: REMOVE_PACK_FROM_GROUP,
    fromGroupId,
    pack,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/pack-to-groups/order-indexes', onload);

  xhr.send(JSON.stringify({oldUUID}));
};

export const addPackToGroup = (
    orderIndexes,
    pack,
    toIndex,
    toGroupId,
    newRelation,
    replaceUUID,
) => (dispatch) => {
  dispatch({
    type: SET_IS_BLOCK_PUBLISH,
    isBlockPublish: false,
  });
  dispatch({
    type: ADD_PACK_TO_GROUP,
    toGroupId,
    toIndex,
    pack,
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'PATCH', '/pack-to-groups/order-indexes', onload);

  xhr.send(JSON.stringify({orderIndexes, newRelation, replaceUUID}));
};

export const deletePack = (packId, isDeleteElements) => (dispatch) => {
  batch(() => {
    dispatch({
      type: SET_IS_BLOCK_PUBLISH,
      isBlockPublish: false,
    });
    dispatch({type: DELETE_PACK, packId});
    dispatch({type: DELETE_PACK_FROM_ELEMENTS, packId, isDeleteWithPack: isDeleteElements});
  });

  const onload = () => {};
  const xhr = createRequest(dispatch, 'DELETE', '/pack/' + packId + '?deleteElements=' + isDeleteElements, onload);

  xhr.send();
};

export const updatePack = (pack) => (dispatch) => {
  dispatch({type: UPDATE_PACK, pack: {...pack}});

  dispatch({
    type: IS_UPDATING_CONTENT,
    isUpdating: true,
  });

  const onload = (response) => {
    dispatch({
      type: IS_UPDATING_CONTENT,
      isUpdating: false,
    });
  };
  const xhr = createRequest(dispatch, 'PATCH', '/pack', onload);

  xhr.send(JSON.stringify(pack));
};


export const selectPack = (packId, packsListType) => (dispatch) => {
  dispatch({
    type: SELECT_PACK,
    packId,
    packsListType,
  });
};

export const setEditPack = (pack) => (dispatch, getState) => {
  if (!pack) {
    dispatch({
      type: SET_EDIT_PACK,
      pack: pack,
    });
  } else {
    const packsInGroups = getState().packs.packsInGroups;
    const packToGroups = [];

    Object.keys(packsInGroups).forEach((keyGroupId) => {
      packsInGroups[keyGroupId].forEach((packInGroup, index) => {
        if (packInGroup.id === pack.id) {
          packToGroups.push({
            groupId: Number(keyGroupId),
            packId: pack.id,
            order: index,
            uuid: packInGroup.uuidRelation,
          });
        }
      });
    });
    dispatch({
      type: SET_EDIT_PACK,
      pack: {...pack, ...{packToGroups}},
    });
  }
};

export const setDeletePack = (pack) => (dispatch) => {
  dispatch({
    type: SET_DELETE_PACK,
    pack,
  });
};
