import React from 'react';
import {connect} from 'react-redux';
import {showModal, typeModal} from '../../actions/general';
import AddHeader from '../general/AddHeader';
import {bindActionCreators} from 'redux';
import LibraryTagsList from './LibraryTagsList';

const LibraryTagsSection = ({tags, selectedContentTypeId, elements}) => {
  const filteredTags = tags.filter((tag) => {
    const elementsIds = tag.elementsIds.filter((elementId) => {
      const element = elements.find((element) => {
        return element.id === elementId && element.contentTypeId === selectedContentTypeId;
      });
      return !!element;
    });
    return elementsIds.length !== 0;
  });
  return (
    <div className={'section-container'}>
      <AddHeader
        title={`Tags (${filteredTags.length})`}
        modalType={typeModal.TAG_ADD}
      />
      <div className={'fltr-items-container'}>
        <LibraryTagsList
          tags={filteredTags}
          selectedContentTypeId={selectedContentTypeId}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tags.tags.filter((tag) => tag.imageUrl),
  selectedContentTypeId: state.appState.selectedContentTypeId,
  elements: state.elements.elements,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LibraryTagsSection);
