import React from 'react';
import {connect} from 'react-redux';
import {showModal, typeModal} from '../../actions/general';
import AddHeader from '../general/AddHeader';
import {bindActionCreators} from 'redux';
import PacksList, {PacksListType} from './PacksList';

const PacksSection = ({packs, selectedContentTypeId}) => {
  const filteredPacks = packs
      .filter((pack) => (pack.contentTypeId === selectedContentTypeId))
      .sort((a, b) => (b.id - a.id));

  return (
    <div className={'section-container'}>
      <AddHeader
        title={`Packs (${filteredPacks.length})`}
        modalType={typeModal.PACK_ADD}
      />
      <div className={'fltr-items-container'}>
        <PacksList
          packs={filteredPacks}
          packsListType={PacksListType.ONLY_PACKS}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  packs: state.packs.packs,
  selectedLang: state.appState.selectedLang,
  selectedContentTypeId: state.appState.selectedContentTypeId,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PacksSection);
