import React from 'react';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {TagsList} from './TagsList';
import {AiOutlinePlus} from 'react-icons/ai';
import {addTag, orderTags, setDeleteTag, setEditTag, TAGS_CONTENT_TYPES, updateTag} from '../../actions/tags';
import uuid from 'uuid/v4';
import MDSpinner from 'react-md-spinner';

const TagsSection = ({allTags, tags, addTag, updateTag, setDeleteTag,
  isLoadingTags, setEditTag, orderParams, orderTags, selectedTagsContentType}) => {
  if (isLoadingTags) {
    return (
      <div className={'tags-container'}>
        <div className='spinner-container' style={{height: '424px'}}>
          <MDSpinner size={100} singleColor={'#00DADA'}/>
        </div>
      </div>
    );
  }
  return (
    <div className={'tags-container'}>
      <TagsList
        tags={tags}
        updateTag={updateTag}
        setDeleteTag={setDeleteTag}
        setEditTag={setEditTag}
        orderParams={orderParams}
        orderTags={orderTags}
        selectedTagsContentType={selectedTagsContentType}
      />
      {
            selectedTagsContentType === TAGS_CONTENT_TYPES.ALL ? <div className={'tags-add-container tag-text'}
              onClick={() => {
                addTag({
                  id: uuid(),
                  name: '1 new tag',
                  localization: {},
                  isActive: false,
                  order: (allTags[allTags.length - 1].order + 1),
                  elementsIds: [],
                });
              }}
            >
              <AiOutlinePlus style={{fontSize: '19px'}}/>
              <div style={{paddingLeft: '8px'}}>Add Word</div>
            </div> : null
      }

    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tags.sortedTags,
  allTags: state.tags.tags,
  orderParams: state.tags.orderTagsParams,
  isLoadingTags: state.tags.isLoadingTags,
  selectedTagsContentType: state.tags.selectedTagsContentType,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  addTag: bindActionCreators(addTag, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
  setDeleteTag: bindActionCreators(setDeleteTag, dispatch),
  setEditTag: bindActionCreators(setEditTag, dispatch),
  orderTags: bindActionCreators(orderTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsSection);
