import React from 'react';
import {getStyle} from '../../../utils/StyleUtils';

export const RequestActionsBody = ({deleteRequest, onClose}) => {
  return <div className={getStyle('component-settings-container')}>
    <div className={'component-setting'} onClick={() => {
      deleteRequest();
    }}>
      <div className={'component-setting-text'}>Delete</div>
    </div>
  </div>;
};

