import {WEBSOCKET_CONNECTING, WEBSOCKET_OPEN, WEBSOCKET_CLOSED, WEBSOCKET_ERROR, WebsocketMessageType} from './types';
import {groupArray, groupFrames} from '../../utils/WebsocketUtils';

export const connecting = (event, websocket, serviceType) => ({
  type: WEBSOCKET_CONNECTING,
  serviceType,
  payload: {
    timestamp: new Date(),
    event,
    websocket,
  },
});

export const open = (event, serviceType) => ({
  type: WEBSOCKET_OPEN,
  serviceType,
  payload: {
    timestamp: new Date(),
    event,
  },
});

export const closed = (event, serviceType) => ({
  type: WEBSOCKET_CLOSED,
  serviceType,
  payload: {
    timestamp: new Date(),
    event,
  },
});

export const error = (event) => ({
  type: WEBSOCKET_ERROR,
  payload: {
    timestamp: new Date(),
    event,
  },
});

export const message = (event) => {
  const message = JSON.parse(event.data);
  if (message.message !== undefined || message.action !== undefined) {
    if (message.messageType === WebsocketMessageType.FRAMES) {
      return groupFrames(message);
    }
    if (message.messageType === WebsocketMessageType.ARRAY) {
      return groupArray(message);
    }

    return {
      type: message.action,
      data: message,
    };
  }
};

export default {};
