import React from 'react';
import {Input} from '../general/Input';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete_tag-icon.svg';
import {ReactComponent as EditIcon} from '../../resources/imgs/edit_tag-icon.svg';
import {ReactComponent as HideIcon} from '../../resources/imgs/hide_tag-icon.svg';
import {ReactComponent as SortIcon} from '../../resources/imgs/sort-icon.svg';
import {TAGS_CONTENT_TYPES, TAGS_ORDER_TYPES} from '../../actions/tags';

export const LocaleTagColumn = ({
  locale, tags, style, selectTagId, setEditTag, selectedTagsContentType,
  selectedTagId, updateTag, setDeleteTag, orderParams, orderTags,
}) => {
  const isEnglish = locale === 'en';
  const iconStyle = {paddingRight: '5px', paddingLeft: '5px', display: 'flex'};

  const getImageSrc = (tag) => {
    switch (selectedTagsContentType) {
      case TAGS_CONTENT_TYPES.PRESET: return tag.imageUrl;
      case TAGS_CONTENT_TYPES.HIGHLIGHT: return tag.highlightImageUrl;
      case TAGS_CONTENT_TYPES.IG_ICON: return tag.iconImageUrl;
      case TAGS_CONTENT_TYPES.ALL: return tag.imageUrl ? tag.imageUrl : tag.highlightImageUrl ? tag.highlightImageUrl : tag.iconImageUrl;
    }
  };

  const renderInput = (tag) => {
    const value = isEnglish ? tag.name :
      (tag.localization[locale] && tag.localization[locale]['name'] ? tag.localization[locale]['name'] : '');

    return <Input debounce={800}
      className={'tag-input' + (selectedTagId === tag.id ? '-active' : '')}
      handleChange={(value) => {
        const newTag = {...tag};
        if (isEnglish) {
          newTag.name = value;
          if (newTag.name === '') {
            newTag.isActive = false;
          }
        }
        if (newTag.localization[locale] && newTag.localization[locale].name) {
          newTag.localization[locale].name = value;
        } else {
          newTag.localization[locale] = {name: value};
        }

        updateTag(newTag);
      }}
      value={value}
      placeholder={'-'}
    />;
  };

  const renderHeaderGeneralColumn = () => {
    return <div className={'tag-text'}
      style={{paddingTop: '14px', display: 'flex'}}
    >
      <div style={iconStyle}>
        <SortIcon/>
      </div>
      <div style={iconStyle}>
        <SortIcon className={'hover-icon'} onClick={() => {
          orderTags({
            orderBy: TAGS_ORDER_TYPES.IS_VISIBLE,
            orderIndex: orderParams.orderIndex === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        style={{fill: (orderParams.orderBy === TAGS_ORDER_TYPES.IS_VISIBLE ? '#232326' : '#B5B5B5')}}
        />
      </div>
      <div style={iconStyle}>
        <SortIcon className={'hover-icon'} onClick={() => {
          orderTags({
            orderBy: TAGS_ORDER_TYPES.IS_IMAGE,
            orderIndex: orderParams.orderIndex === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        style={{fill: (orderParams.orderBy === TAGS_ORDER_TYPES.IS_IMAGE ? '#232326' : '#B5B5B5')}}
        />
      </div>
      <div style={{...iconStyle, ...{width: '70px', paddingLeft: '20px'}}}>
        <SortIcon className={'hover-icon'} onClick={() => {
          orderTags({
            orderBy: TAGS_ORDER_TYPES.NAME,
            orderIndex: orderParams.orderIndex === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        style={{fill: (orderParams.orderBy === TAGS_ORDER_TYPES.NAME ? '#232326' : '#B5B5B5')}}
        />
      </div>
      <div style={iconStyle}>
        <SortIcon className={'hover-icon'} onClick={() => {
          orderTags({
            orderBy: TAGS_ORDER_TYPES.COUNT_ELEMENTS,
            orderIndex: orderParams.orderIndex === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        style={{fill: (orderParams.orderBy === TAGS_ORDER_TYPES.COUNT_ELEMENTS ? '#232326' : '#B5B5B5')}}
        />
      </div>
    </div>;
  };

  const renderGeneralColumn = () => {
    return <div className={'tag-locale-column'} style={{...style, ...{width: '220px'}}}>
      {renderHeaderGeneralColumn()}
      <div style={{display: 'flex', flexDirection: 'column', marginTop: '8px'}}>
        {tags.map((tag) => {
          return <div
            key={locale + tag.id}
            onClick={() => {
              selectTagId(tag.id);
            }}
            style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}
          >
            <div style={iconStyle}>
              <DeleteIcon className={'hover-icon'} onClick={() => {
                setDeleteTag(tag);
              }}
              />
            </div>
            <div style={iconStyle}>
              <HideIcon className={'hover-icon'} onClick={() => {
                updateTag(
                    Object.assign({}, tag, {
                      isActive: !tag.isActive,
                    }),
                );
              }}
              style={{fill: (tag.isActive ? '#00DADA' : '#B5B5B5')}}
              />
            </div>
            <div style={iconStyle}>
              <EditIcon className={'hover-icon'} onClick={() => {
                setEditTag(tag);
              }}
              style={{fill: (getImageSrc(tag) ? '#282828' : '#B5B5B5')}}
              />
            </div>
            {renderInput(tag)}
            <div style={{...iconStyle, ...{width: '30px'}}} className={'tag-text'}>
              {tag.elementsIds.length}
            </div>
          </div>;
        })}
      </div>
    </div>;
  };

  const renderLocaleColumn = () => {
    return <div className={'tag-locale-column'} style={style}>
      <div className={'tag-text'}
        style={{paddingTop: '14px', paddingLeft: '15px', paddingRight: '15px'}}
      >
        {locale.toUpperCase()}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', marginTop: '8px'}}>
        {tags.map((tag) => {
          return <div
            key={locale + tag.id}
            onClick={() => {
              selectTagId(tag.id);
            }}
            style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}
          >
            {renderInput(tag)}
          </div>;
        })}
      </div>
    </div>;
  };

  return isEnglish ? renderGeneralColumn() : renderLocaleColumn();
};
