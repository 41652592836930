import {saveItem, getItem} from './LocalStoargeUtill';

export const saveAuth = (auth, saveAdmin) => {
  if (auth.access_token_ol) {
    saveItem(auth.access_token_ol, 'access_token_ol');
  }

  if (auth.admin) {
    saveItem(auth.admin, 'admin');
    if (auth.admin.iamRoles) {
      const iamActionsSet = {};
      auth.admin.iamRoles.forEach((role) => {
        role.actions.forEach((action) => {
          iamActionsSet[action] = true;
        });
      });
      saveItem(Object.keys(iamActionsSet), 'iamActions');
    }
  }

  if (auth.cookies) {
    saveItem(auth.cookies, 'cookies');
    auth.cookies.forEach((cookie) => {
      document.cookie = cookie;
    });
  }
};

export const isAuth = () => {
  const accessToken = getItem('access_token_ol', null);

  return accessToken !== null;
};

export const getAccessToken = () => {
  return getItem('access_token_ol', null);
};

export const getAdmin = () => {
  return getItem('admin', null);
};

export const getAdminIamActions = () => {
  return getItem('iamActions', []);
};

let handlerAuthState = null;

export const registerLogout = (logoutFunc) => {
  handlerAuthState = logoutFunc;
};

export const logoutApp = () => {
  setTimeout(() => {
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
    handlerAuthState(false);
  }, 1000);
};

export const registerCookiesFromLocalStorage = () => {
  const cookies = getItem('cookies', []);

  cookies.forEach((cookie) => {
    document.cookie = cookie;
  });
};

export const getCookies = () => {
  return getItem('cookies', []);
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};
