import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createPersonalNotification, showModal, typeModal} from '../../actions/general';
import PackDetails from '../packs/PackDetails';
import Modal from './Modal';
import {deletePack, setDeletePack, setEditPack} from '../../actions/packs';
import {DeleteAttention} from './DeleteAttention';
import {ElementDetails} from '../elements/details';
import {deleteGroup, setDeleteGroup, setEditGroup} from '../../actions/groups';
import {deleteElement, setDeleteElement, setEditElement} from '../../actions/elements';
import GroupDetails from '../groups/GroupDetails';
import PublishFeedDialog from './PublishFeedDialog';
import {deleteTag, setDeleteTag, setEditTag} from '../../actions/tags';
import {deleteRequest, setDeleteRequest, setEditRequest, setProcessedRequest} from '../../actions/requests';
import RequestDetails from '../requests/details/RequestDetails';
import {WarningAttention} from './WarningAttention';
import {AcceptDialog} from './AcceptDialog';
import {NotificationStatus} from '../../models/notifiactions';
import TagDetails from '../tags/TagDetails';
import {CreateNotificationWindow} from './CreateNotificationWindow';
import {InternetDisconnected} from './InternetDisconnected';

const Modals = ({packForEdit, packForDelete, elementForEdit, deleteGroup,
  elementForDelete, setEditGroup, setDeleteGroup, selectedContentTypeId,
  setEditPack, deletePack, setDeletePack, deleteElement, showModal,
  groupForDelete, groupForEdit, setEditElement, setDeleteElement,
  tagForDelete, setDeleteTag, deleteTag, setEditTag, tagForEdit, requestForEdit, setEditRequest,
  setDeleteRequest, deleteRequest, requestForDelete, setProcessedRequest, requestForProcessed,
  createPersonalNotification
}) => {
  return <div style={{position: 'relative'}}>
    <Modal modalType={typeModal.PACK_EDIT} onClose={() => setEditPack(null)}>
      <PackDetails
        mode={'EDIT'}
        pack={packForEdit}
        cancel={() => setEditPack(null)}
        deletePack={(pack) => {
          setDeletePack(pack);
          setEditPack(null);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.PACK_DELETE} onClose={() => setDeletePack(null)}>
      <DeleteAttention
        description={'Pack ' + (packForDelete ? packForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeletePack(null);
        }}
        isSwitcher={true}
        switchText={'Keep elements'}
        onDelete={(isKeepElements) => {
          setDeletePack(null);
          deletePack(packForDelete.id, !isKeepElements);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.PACK_ADD}>
      <PackDetails
        mode={'ADD'}
        pack={{
          isVisible: false,
          isPremium: false,
          isNew: true,
          isFree: false,
          name: '',
          description: '',
          packToGroups: [],
          fltrTagIds: [],
          elementToPacks: [],
          localization: {},
          todayDate: null,
          notificationStatus: NotificationStatus.READY_FOR_SENDING,
          contentTypeId: selectedContentTypeId,
        }}
        cancel={() => {
          showModal(typeModal.PACK_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ELEMENT_EDIT} onClose={() => setEditElement(null)}>
      <ElementDetails
        mode={'EDIT'}
        element={elementForEdit}
        cancel={() => {
          setEditElement(null);
        }}
        deleteElement={(element) => {
          setDeleteElement(element);
          setEditElement(null);
        }}
        elementsCount={elementForEdit ? elementForEdit.elementToPacks.length : 0}
      />
    </Modal>
    <Modal
      modalType={typeModal.ELEMENT_DELETE}
      onClose={() => setDeleteElement(null)}
      modalContentClassName={'attention-modal-content'}
    >
      <DeleteAttention
        description={'Element ' + (elementForDelete ? elementForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteElement(null);
        }}
        onDelete={() => {
          setDeleteElement(null);
          deleteElement(elementForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.ELEMENT_ADD}>
      <ElementDetails
        mode={'ADD'}
        element={{
          isActive: true,
          isFree: false,
          isPremium: false,
          isNew: false,
          name: '',
          description: '',
          presetUrl: '',
          filterUrl: '',
          todayDate: null,
          localization: {},
          fltrTagIds: [],
          contentTypeId: selectedContentTypeId,
          elementToPacks: [],
        }}
        cancel={() => {
          showModal(typeModal.ELEMENT_ADD, false);
        }}
      />
    </Modal>

    <Modal
      modalType={typeModal.REQUEST_DELETE}
      style={{zIndex: '1005'}}
      onClose={() => setDeleteRequest(null)}
      modalContentClassName={'attention-modal-content'}
    >
      <DeleteAttention
        description={'Request ' + (requestForDelete ? requestForDelete.id : '') + ' ?'}
        cancel={() => {
          setDeleteRequest(null);
        }}
        onDelete={() => {
          deleteRequest(requestForDelete.id);
          setDeleteRequest(null);
        }}
      />
    </Modal>

    <Modal
      modalType={typeModal.REQUEST_IS_PROCESSED}
      onClose={() => setProcessedRequest(null)}
      modalContentClassName={'attention-modal-content'}
    >
      <WarningAttention
        description={requestForProcessed ? requestForProcessed.adminName : ''}
        title={'The request is already being processed'}
        subtitle={' does this request, try another'}
        footerButtonText={'Got it'}
        cancel={() => {
          setProcessedRequest(null);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.REQUEST_EDIT} onClose={() => setEditRequest(null)}>
      <RequestDetails
        request={requestForEdit}
        cancel={() => {
          setEditRequest(null);
        }}
        deleteRequest={(request) => {
          setDeleteRequest(request);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.GROUP_EDIT} onClose={() => setEditGroup(null)}>
      <GroupDetails
        mode={'EDIT'}
        group={groupForEdit}
        cancel={() => {
          setEditGroup(null);
        }}
        deleteGroup={(group) => {
          setDeleteGroup(group);
          setEditGroup(null);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.GROUP_DELETE} onClose={() => setDeleteGroup(null)}>
      <DeleteAttention
        description={'Group ' + (groupForDelete ? groupForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteGroup(null);
        }}
        onDelete={() => {
          setDeleteGroup(null);
          deleteGroup(groupForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.GROUP_ADD}>
      <GroupDetails
        mode={'ADD'}
        group={{
          name: '',
          localization: {},
          contentTypeId: selectedContentTypeId,
        }}
        cancel={() => {
          showModal(typeModal.GROUP_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.TAG_EDIT} onClose={() => setEditTag(null)}>
      <TagDetails
        mode={'EDIT'}
        tag={tagForEdit}
        cancel={() => setEditTag(null)}
        deleteTag={(tag) => {
          setDeleteTag(tag);
          setEditTag(null);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.TAG_ADD}>
      <TagDetails
        mode={'ADD'}
        tag={{
          isActive: false,
          id: null,
          elementsIds: [],
          name: '',
          localization: {},
        }}
        cancel={() => {
          showModal(typeModal.TAG_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.TAG_DELETE} onClose={() => setDeleteTag(null)}>
      <DeleteAttention
        description={'Tag ' + (tagForDelete ? tagForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteTag(null);
        }}
        isSwitcher={false}
        onDelete={() => {
          setDeleteTag(null);
          deleteTag(tagForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.PUBLISH}>
      <PublishFeedDialog
        cancel={() => {
          showModal(typeModal.PUBLISH, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.DONE_REQUEST}
      modalContentClassName={'attention-modal-content'}
    >
      <AcceptDialog
        cancel={() => {
          showModal(typeModal.DONE_REQUEST, false);
        }}
        title={'Mark as Done?'}
        subtitle={''}
        textAccept={'Mark as Done'}
      />
    </Modal>
    <Modal modalType={typeModal.CREATE_PERSONAL_NOTIFICATION}
    >
      <CreateNotificationWindow
        cancel={() => {
          showModal(typeModal.CREATE_PERSONAL_NOTIFICATION, false);
        }}
        onSend={createPersonalNotification}
      />
    </Modal>
    <Modal modalType={typeModal.INTERNET_DISCONNECTED}
    >
      <InternetDisconnected/>
    </Modal>

  </div>;
};

const mapStateToProps = (state) => ({
  packForEdit: state.appState.packForEdit,
  packForDelete: state.appState.packForDelete,
  elementForEdit: state.appState.elementForEdit,
  elementForDelete: state.appState.elementForDelete,
  groupForDelete: state.appState.groupForDelete,
  groupForEdit: state.appState.groupForEdit,
  tagForDelete: state.appState.tagForDelete,
  tagForEdit: state.appState.tagForEdit,
  selectedContentTypeId: state.appState.selectedContentTypeId,
  requestForEdit: state.requests.selectedRequest,
  requestForDelete: state.appState.requestForDelete,
  requestForProcessed: state.appState.requestForProcessed,
});

const mapDispatchToProps = (dispatch) => ({
  setEditPack: bindActionCreators(setEditPack, dispatch),
  setDeletePack: bindActionCreators(setDeletePack, dispatch),
  deletePack: bindActionCreators(deletePack, dispatch),
  setDeleteGroup: bindActionCreators(setDeleteGroup, dispatch),
  setEditGroup: bindActionCreators(setEditGroup, dispatch),
  deleteGroup: bindActionCreators(deleteGroup, dispatch),
  setEditElement: bindActionCreators(setEditElement, dispatch),
  setDeleteElement: bindActionCreators(setDeleteElement, dispatch),
  deleteElement: bindActionCreators(deleteElement, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setDeleteTag: bindActionCreators(setDeleteTag, dispatch),
  setEditTag: bindActionCreators(setEditTag, dispatch),
  deleteTag: bindActionCreators(deleteTag, dispatch),
  setEditRequest: bindActionCreators(setEditRequest, dispatch),
  setDeleteRequest: bindActionCreators(setDeleteRequest, dispatch),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
  setProcessedRequest: bindActionCreators(setProcessedRequest, dispatch),
  createPersonalNotification: bindActionCreators(createPersonalNotification, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
