import {motion} from 'framer-motion';
import styled from 'styled-components';

export const Nav = styled(motion.nav)`
  background-color: black;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  z-index: 2000;
  flex-direction: column;
  align-items: flex-start;
`;
